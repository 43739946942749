<template>
  <div id="map-wrapper">
    <GmapMap
        ref="map"
        :options="map.options"
        :center="map.center"
        :zoom="map.zoom"
        :mapTypeId="map.mapTypeId"
        @click="clickMap"
        @dragend="setCenterZoom({mapPromise: $refs.map.$mapPromise})"
        v-if="loaded"
        class="map">
      <GmapMarker
          :key="establishment.attributes.slug"
          v-for="establishment in mappableEstablishments"
          :position="{lat: $options.filters.stringToFloat(establishment.attributes.lat), lng: $options.filters.stringToFloat(establishment.attributes.lng)}"
          :z-index="1"
          :options="{icon: {scaledSize: {height: 26, width: 26}, url: establishmentPin(establishment)}}"
          @click="focusEstablishment(establishment)">
      </GmapMarker>
      <GmapMarker
          key="you-are-here"
          v-if="map.youAreHere"
          :position="map.youAreHere"
          :z-index="2"
          :options="{icon: {scaledSize: {height: 26, width: 26}, url: require('../../assets/icons/icn-here-blu.svg')}}">
      </GmapMarker>
    </GmapMap>
    <v-btn
        v-if="!focusedEstablishment"
        fab
        :to="{name: 'menu'}"
        color="white"
        class="menu-button grey--text elevation-3 ml-3 mt-3">
      <v-icon class="mr-1 ml-2">icn icn-menu-gry</v-icon>
    </v-btn>
    <map-filters
        v-if="!focusedEstablishment">
    </map-filters>
    <v-layout
        column
        align-center
        class="focused-establishment pb-2 px-2"
        v-if="mapOpen && focusedEstablishment"
        @click="$router.push({name: 'winery', params: {slug: focusedEstablishment.attributes.slug}, query: {tab: 'Winery'}})">
      <v-layout column align-center>
        <v-avatar size="5rem" style="margin-top: -2.5rem" color="white">
          <v-avatar size="4rem">
            <v-img v-if="focusedEstablishment.attributes.logoImageFileNameUrl" :src="focusedEstablishment.attributes.logoImageFileNameUrl"></v-img>
          </v-avatar>
        </v-avatar>
      </v-layout>
      <div class="title pt-1 text-truncate" style="max-width: 100%">{{focusedEstablishment.attributes.name}}</div>
      <div class="caption pt-1 grey--text" v-if="focusedEstablishmentTodayHours">Open {{focusedEstablishmentTodayHours}} today</div>
      <v-chip
          v-if="$options.filters.establishmentOpen(focusedEstablishment)"
          color="deep-red"
          text-color="white"
          small>
        Open
      </v-chip>
      <v-chip
          v-else-if="$options.filters.establishmentOpenSoon(focusedEstablishment)"
          color="deep-red-light"
          text-color="white"
          small>
        Open Soon
      </v-chip>
    </v-layout>
  </div>
</template>
<script>
import Vuex       from 'vuex'
import MapFilters from './Map/Filters.vue'
export default {
  name: 'MapScrollerMap',
  components: {
    MapFilters
  },
  data() {
    return {
      loaded: false
    }
  },
  created() {
    this.loadEstablishments()
    setTimeout(() => this.loaded = true, 800)
  },
  computed: {
    ...Vuex.mapGetters('Map', ['map', 'establishments', 'allEstablishments', 'mapOpen', 'focusedEstablishment']),
    mappableEstablishments() {
      return this.establishments.filter(est => est.attributes.lat && est.attributes.lng)
    },
    focusedEstablishmentTodayHours() {
      return this.$options.filters.hoursInfo(this.focusedEstablishment).find(day => day.today).hours
    }
  },
  methods: {
    ...Vuex.mapActions('Map', ['toggleMapOpen', 'setCenterZoom', 'loadEstablishments', 'attemptUpdateYouAreHere', 'setFocusedEstablishment']),
    clickMap() {
      this.toggleMapOpen(true)
      this.setFocusedEstablishment({establishment: null})
    },
    focusEstablishment(establishment) {
      if (this.mapOpen) {
        this.setFocusedEstablishment({establishment})
      } else {
        this.toggleMapOpen(true)
      }
    },
    establishmentPin(establishment) {
      let pin = null
      if (this.$options.filters.establishmentOpen(establishment)) {
        pin = require('../../assets/icons/icn-pin-red.svg')
      } else {
        pin = require('../../assets/icons/icn-pin-dark-gray.svg')
      }
      return pin
    }
  }
}
</script>
<style scoped lang="sass">
#map-wrapper
  position: fixed
  top: 0
  left: 0
  height: var(--viewport-height)
  width: var(--viewport-width)
  background-color: #EFEFEF
  .map
    height: var(--viewport-height)
    width: var(--viewport-width)
  .menu-button
    position: absolute
    left: 0rem
    top: env(safe-area-inset-top)
    height: 3.2rem
    width: 3.2rem
  .focused-establishment
    position: absolute
    left: 50%
    margin-left: -10rem
    width: 20rem
    top: calc(env(safe-area-inset-top) + 1rem + 2.5rem)
    background-color: white
    border-radius: 1rem
</style>
