'use strict'

export default {
  namespaced: true,
  state: {
    config: {},
  },
  mutations: {
    updateConfig(state, config) {
      state.config = {...config}
    },
  },
  getters: {
    config(state) { return state.config },
  },
  actions: {
    updateConfig({commit}, config) {
      commit('updateConfig', {
        enabled:  true,
        floating: true,
        ...config
      })
    }
  }
}
