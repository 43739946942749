<template>
  <lean :left-to="{name: 'guide'}">
    <page :header="{enabled: true, back: {name: 'guide'}, center: {enabled: true, text: 'Menu'}}">
      <v-layout row shrink wrap class="px-3">
        <v-btn
            round
            v-for="tab in tabs"
            :key="tab.route"
            :color="$route.name === tab.route ? 'deep-red' : 'grey'"
            :outline="$route.name !== tab.route"
            :class="{'white--text': $route.name === tab.route}"
            class="mb-2 mx-1 px-3 py-1 caption"
            style="min-width: auto; height: auto"
            :to="{name: tab.route}">
          {{tab.text}}
        </v-btn>
      </v-layout>
      <router-view></router-view>
    </page>
  </lean>
</template>
<script>
export default {
  name: 'Menu',
  computed: {
    tabs() {
      return [
        {route: 'about',   text: 'ABOUT'},
        {route: 'privacy', text: 'TERMS'},
        {route: 'login',   text: 'LOGIN'},
      ]
    }
  }
}
</script>
