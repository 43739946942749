'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    establishment: null,
  },
  getters: {
    establishment(state) { return state.establishment }
  },
  mutations: {
    initializeEstablishment(state) {
      state.establishment = {
        active:    true,
        partnered: false,
        hours:     {},
        amenities: []
      }
    },
    setEstablishment(state, {establishment}) {
      state.establishment = {...state.establishment, ...establishment}
    }
  },
  actions: {
    initializeEstablishment({commit}) {
      commit('initializeEstablishment')
    },
    setEstablishment({commit}, {establishment}) {
      commit('setEstablishment', {establishment})
    },
    async attemptSaveEstablishment({getters}) {
      try {
        await api.Admins.Establishments.create({params: {establishment: getters.establishment}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
