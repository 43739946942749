<template>
  <v-layout column class="content py-3">
    <div v-if="!filters.search">
      <list-of-establishments
          :establishments="featuredWineries"
          :show-placeholders="false"
          class="px-2">
      </list-of-establishments>
    </div>
    <list-of-establishments
        v-else
        :establishments="wineries"
        :show-placeholders="false"
        class="px-2">
    </list-of-establishments>
  </v-layout>
</template>
<script>
import Vuex                 from 'vuex'
import ListOfEstablishments from '../ListOfEstablishments.vue'
export default {
  name: 'MapScrollerWineries',
  components: {
    ListOfEstablishments
  },
  created() {
    this.loadFeaturedWineries()
  },
  computed: {
    ...Vuex.mapGetters('Wineries', ['wineries', 'featuredWineries', 'filters'])
  },
  methods: {
    ...Vuex.mapActions('Wineries', ['loadFeaturedWineries'])
  }
}
</script>
<style scoped lang="sass">
.content
  overflow: auto
  background-color: white
</style>
