<template>
  <page :header="{enabled: true, back: {name: 'admins-establishments'}, center: {enabled: true, text: 'New business'}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-text-field v-model="computedName" :rules="NameRules" label="Name"></v-text-field>
      <v-text-field v-model="computedSlug" :rules="SlugRules" label="Slug"></v-text-field>
      <h3 class="mt-2">Logo Image</h3>
      <v-layout row align-center>
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray">
          <v-img
              v-if="establishment.logoImageFileNameData || establishment.logoImageFileNameUrl"
              :src="establishment.logoImageFileNameData || establishment.logoImageFileNameUrl">
          </v-img>
        </v-list-tile-avatar>
        <image-upload v-model="computedLogoImageFileNameData"></image-upload>
      </v-layout>
      <h3 class="mt-2">Cover Image</h3>
      <v-layout row align-center>
        <v-sheet
            color="light-gray"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3 handle">
          <v-img
              v-if="establishment.headerImageFileNameData || establishment.headerImageFileNameUrl"
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="establishment.headerImageFileNameData || establishment.headerImageFileNameUrl">
          </v-img>
        </v-sheet>
        <image-upload v-model="computedHeaderImageFileNameData"></image-upload>
      </v-layout>
      <v-textarea v-model="computedDescription" :rules="DescriptionRules" label="Description"></v-textarea>
      <v-text-field v-model="computedPhone" :rules="PhoneRules" label="Phone"></v-text-field>
      <v-text-field v-model="computedWebsite" :rules="WebsiteRules" label="Website URL"></v-text-field>
      <v-text-field v-model="computedAddress" :rules="AddressRules" label="Address"></v-text-field>
      <v-layout>
        <v-text-field v-model="computedLat" :rules="LatRules" label="Latitude" class="mr-2"></v-text-field>
        <v-text-field v-model="computedLng" :rules="LngRules" label="Longitude"></v-text-field>
      </v-layout>
      <v-radio-group v-model="computedLocale" :rules="LocaleRules" label="Locale" required>
        <v-radio
            v-for="locale in ['Wine Country', 'Old Town']"
            :key="locale"
            :label="locale"
            :value="locale">
        </v-radio>
      </v-radio-group>
      <v-radio-group v-model="computedType" :rules="TypeRules" label="Type" required>
        <v-radio
            v-for="type in ['Winery', 'Partner']"
            :key="type"
            :label="type"
            :value="type">
        </v-radio>
      </v-radio-group>
      <v-radio-group v-if="computedType === 'Partner'" v-model="computedPartnerType" :rules="PartnerTypeRules" label="Partner Type" required>
        <v-radio
            v-for="partnerType in [null, 'Dine', 'Stay', 'Play', 'Ride']"
            :key="partnerType"
            :label="partnerType || 'None'"
            :value="partnerType">
        </v-radio>
      </v-radio-group>
      <v-expansion-panel class="elevation-0 mb-2">
        <v-expansion-panel-content>
          <h3 slot="header">Hours</h3>
          <hours store-module="Admins/EstablishmentNew"></hours>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <h3 slot="header">Amenities</h3>
          <amenities store-module="Admins/EstablishmentNew"></amenities>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-layout>
        <v-checkbox v-model="computedActive" label="Active"></v-checkbox>
        <v-checkbox v-model="computedPartnered" label="Partnered"></v-checkbox>
      </v-layout>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveEstablishment"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import rules      from '../../lib/rules'
import Hours      from '../Operators/EstablishmentHours.vue'
import Amenities  from '../Operators/EstablishmentAmenities.vue'
import ImageUpload from '../Operators/ImageUpload.vue'
export default {
  name: 'OperatorsEstablishmentNew',
  components: {
    ImageUpload,
    Hours,
    Amenities
  },
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.initializeEstablishment()
  },
  computed: {
    ...Vuex.mapGetters('Admins/EstablishmentNew', ['establishment']),
    ...rules.Establishment,
    computedName: {
      get() { return this.establishment.name },
      set(v) { this.setEstablishment({establishment: {name: v}}) }
    },
    computedSlug: {
      get() { return this.establishment.slug },
      set(v) { this.setEstablishment({establishment: {slug: v}}) }
    },
    computedLogoImageFileNameData: {
      get() { return this.establishment.logoImageFileNameData },
      set(v) { this.setEstablishment({establishment: {logoImageFileNameData: v}}) }
    },
    computedHeaderImageFileNameData: {
      get() { return this.establishment.headerImageFileNameData },
      set(v) { this.setEstablishment({establishment: {headerImageFileNameData: v}}) }
    },
    computedDescription: {
      get() { return this.establishment.description },
      set(v) { this.setEstablishment({establishment: {description: v}}) }
    },
    computedPhone: {
      get() { return this.establishment.phone },
      set(v) { this.setEstablishment({establishment: {phone: v}}) }
    },
    computedWebsite: {
      get() { return this.establishment.website },
      set(v) { this.setEstablishment({establishment: {website: v}}) }
    },
    computedLocale: {
      get() { return this.establishment.locale },
      set(v) { this.setEstablishment({establishment: {locale: v}}) }
    },
    computedType: {
      get() { return this.establishment.type },
      set(v) { this.setEstablishment({establishment: {type: v}}) }
    },
    computedPartnerType: {
      get() { return this.establishment.partnerType },
      set(v) { this.setEstablishment({establishment: {partnerType: v}}) }
    },
    computedAddress: {
      get() { return this.establishment.address },
      set(v) { this.setEstablishment({establishment: {address: v}}) }
    },
    computedLat: {
      get() { return this.establishment.lat },
      set(v) { this.setEstablishment({establishment: {lat: v}}) }
    },
    computedLng: {
      get() { return this.establishment.lng },
      set(v) { this.setEstablishment({establishment: {lng: v}}) }
    },
    computedActive: {
      get() { return this.establishment.active },
      set(v) { this.setEstablishment({establishment: {active: v}}) }
    },
    computedPartnered: {
      get() { return this.establishment.partnered },
      set(v) { this.setEstablishment({establishment: {partnered: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/EstablishmentNew', ['initializeEstablishment', 'setEstablishment', 'attemptSaveEstablishment']),
    async saveEstablishment() {
      this.processing = true
      if (await this.attemptSaveEstablishment()) {
        this.$router.push({name: 'admins-establishment', params: {slug: this.establishment.slug}})
      }
      this.processing = false
    }
  }
}
</script>
