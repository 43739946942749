<template>
  <page :header="{enabled: true, back: {name: 'operators-establishment', params: {slug: $route.params.slug}}, center: {enabled: true, text: establishment ? `Edit ${establishment.name}` : ''}}" :footer="{enabled: false}">
    <v-form v-model="valid" v-if="establishment" class="pa-3">
      <v-text-field :value="establishment.name" readonly disabled></v-text-field>
      <v-text-field v-model="computedAddress" :rules="AddressRules" label="Address"></v-text-field>
      <v-text-field v-model="computedPhone" :rules="PhoneRules" label="Phone"></v-text-field>
      <v-text-field v-model="computedWebsite" :rules="WebsiteRules" label="Website URL"></v-text-field>
      <v-expansion-panel class="elevation-0 mb-2">
        <v-expansion-panel-content>
          <h3 slot="header">Hours</h3>
          <hours store-module="Operators/EstablishmentEdit"></hours>
        </v-expansion-panel-content>
        <v-expansion-panel-content>
          <h3 slot="header">Amenities</h3>
          <amenities store-module="Operators/EstablishmentEdit"></amenities>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveEstablishment"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import rules      from '../../lib/rules'
import Hours      from './EstablishmentHours.vue'
import Amenities  from './EstablishmentAmenities.vue'
export default {
  name: 'OperatorsEstablishmentEdit',
  components: {
    Hours,
    Amenities
  },
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.loadEstablishment({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Operators/EstablishmentEdit', ['establishment']),
    ...rules.Establishment,
    computedAddress: {
      get() { return this.establishment.address },
      set(v) { this.setEstablishment({establishment: {address: v}}) }
    },
    computedPhone: {
      get() { return this.establishment.phone },
      set(v) { this.setEstablishment({establishment: {phone: v}}) }
    },
    computedWebsite: {
      get() { return this.establishment.website },
      set(v) { this.setEstablishment({establishment: {website: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Operators/EstablishmentEdit', ['loadEstablishment', 'setEstablishment', 'attemptSaveEstablishment']),
    async saveEstablishment() {
      this.processing = true
      if (await this.attemptSaveEstablishment()) {
        this.$router.push({name: 'operators-establishment', params: {slug: this.$route.params.slug}})
      }
      this.processing = false
    }
  }
}
</script>
