<template>
  <v-layout column class="content pt-1 px-3">
    <v-layout
        column
        shrink
        v-for="({dayString, events}, i) in eventDayGroupings"
        :key="dayString"
        class="day-grouping">
      <div class="title font-weight-regular pt-3 pb-2 mb-2">{{dayString}}{{i === 0 ? "'s Events" : ''}}</div>
      <v-layout
          row
          v-for="event in events"
          class="my-2"
          :class="`event-${event.attributes.id}`"
          style="position: relative"
          :key="event.attributes.id"
          >
        <v-list-tile-avatar
            size="4rem"
            class="mr-3"
            color="light-gray"
            @click.prevent="$router.push({name: 'winery-events', params: {slug: event.relationships.establishment.attributes.slug}, query: {tab: 'Events', back: {name: 'events'}}})"
            >
          <v-img v-if="event.relationships.establishment.attributes.logoImageFileNameUrl" :src="event.relationships.establishment.attributes.logoImageFileNameUrl"></v-img>
        </v-list-tile-avatar>
        <v-list-tile-content
            @click.prevent="$router.push({name: 'winery-events', params: {slug: event.relationships.establishment.attributes.slug}, query: {tab: 'Events', back: {name: 'events'}}})"
            >
          <v-layout column>
            <div class="title font-weight-regular">{{event.attributes.name}}</div>
            <div class="subheading">{{event.relationships.establishment.attributes.name}}</div>
            <div class="subheading">
              {{event | eventTimeFormatted({onlyTime: true})}}
              <v-chip dark color="deep-red" class="v-chip-small" v-if="event.attributes.liveMusic">Live Music</v-chip>
              <v-chip dark color="deep-red" class="v-chip-small" v-if="event.attributes.twentyOnePlus">21+</v-chip>
            </div>
          </v-layout>
        </v-list-tile-content>
        <v-list-tile-action
            v-if="mobileApp && dayString !== 'Today'"
            class="tl-event-tile-action"
            >
          <v-btn
              icon
              @click="showMenu({event, show: !menusOpen[event.attributes.id]})"
              >
            <v-icon v-if="notificationsDenied">icn icn-bell-no</v-icon>
            <v-icon v-else-if="eventRemindersByEventId[event.attributes.id]">icn icn-bell-red</v-icon>
            <v-icon v-else>icn icn-bell-gry</v-icon>
          </v-btn>
          <transition appear name="fade-fly-from-right">
            <v-layout
                v-if="menusOpen[event.attributes.id]"
                row
                align-center
                class="deep-red pl-2 tl-menu"
                :class="{'tl-menu-hide': !menusOpen[event.attributes.id]}"
                @click.prevent="showMenu({event, show: !menusOpen[event.attributes.id]})"
                >
              <div
                  v-if="notificationsDenied"
                  class="font-weight-bold white--text ma-2"
                  >
                Notifications off
              </div>
              <div
                  v-else-if="!eventRemindersByEventId[event.attributes.id]"
                  class="font-weight-bold white--text ma-2"
                  >
                Remind me:
              </div>
              <v-btn
                  v-if="eventRemindersByEventId[event.attributes.id]"
                  flat
                  @click="destroyEventReminder({event, daysBefore: 1})"
                  class="font-weight-bold ml-1 mr-1 my-2 px-0 white--text"
                  >
                Cancel reminder
              </v-btn>
              <v-btn
                  v-if="!notificationsDenied && !eventRemindersByEventId[event.attributes.id] && dayString !== 'Tomorrow'"
                  flat
                  @click="createEventReminder({event, daysBefore: 1})"
                  class="font-weight-bold ml-1 mr-1 my-2 px-0 white--text"
                  >
                1 day before
              </v-btn>
              <v-btn
                  v-if="!notificationsDenied && !eventRemindersByEventId[event.attributes.id]"
                  flat
                  @click="createEventReminder({event, daysBefore: 0})"
                  class="font-weight-bold ml-1 mr-1 my-2 px-0 white--text"
                  >
                {{dayString === 'Tomorrow' ? 'Tomorrow morning' : 'Morning of'}}
              </v-btn>
              <div class="ml-2 mr-3">
                <v-icon>icn icn-bell-wht</v-icon>
              </div>
            </v-layout>
          </transition>
        </v-list-tile-action>
      </v-layout>
    </v-layout>
  </v-layout>
</template>
<script>
import Vuex   from 'vuex'
import moment from 'moment'
export default {
  name: 'MapScrollerEvents',
  data() {
    return {
      menusOpen: {}
    }
  },
  created() {
    this.loadEvents()
    // Acknowledge footer navigation badge
    this.addAcknowledgement({acknowledgement: {name: 'new-events', expires: moment().endOf('day').format()}})
    setTimeout(() => {
      this.temporarilyShowFirstReminder()
    }, 500)
  },
  computed: {
    ...Vuex.mapGetters('AppInfo', ['mobileApp', 'notificationsDenied']),
    ...Vuex.mapGetters('Events', ['events', 'featuredEvent', 'eventDayGroupingsFunc']),
    ...Vuex.mapGetters('EventReminders', ['eventRemindersByEventId']),
    eventDayGroupings() {
      return this.eventDayGroupingsFunc()
    }
  },
  methods: {
    ...Vuex.mapActions('Events', ['loadEvents']),
    ...Vuex.mapActions('EventReminders', ['createEventReminder', 'destroyEventReminder']),
    ...Vuex.mapActions('Acknowledgements', ['addAcknowledgement']),
    showMenu({event, show}) {
      this.menusOpen = {
        ...this.menusOpen,
        [event.attributes.id]: show
      }
    },
    temporarilyShowFirstReminder() {
      const eventDayGrouping = this.eventDayGroupings.find(({dayString, events}) => {
        return dayString !== 'Today' && events.length
      })
      const reminderEvent = eventDayGrouping ? eventDayGrouping['events'][0] : null
      if (reminderEvent) {
        this.showMenu({event: reminderEvent, show: true})
        setTimeout(() => this.showMenu({event: reminderEvent, show: false}), 3000)
      }
    }
  }
}
</script>
<style scoped lang="sass">
.content
  overflow: auto
  background-color: white
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--footer-height) + 2rem)
.day-grouping
  >.title
    border-bottom: 1px solid var(--light-gray)
.tl-event-tile-action
  position: relative
  .tl-menu
    position: absolute
    top: 0rem
    right: -1.1rem
    border-top-left-radius: 0.4rem
    border-bottom-left-radius: 0.4rem
</style>
