import { render, staticRenderFns } from "./RegisterPayWelcome.vue?vue&type=template&id=3d631d7f&scoped=true&"
import script from "./RegisterPayWelcome.vue?vue&type=script&lang=js&"
export * from "./RegisterPayWelcome.vue?vue&type=script&lang=js&"
import style0 from "./RegisterPayWelcome.vue?vue&type=style&index=0&id=3d631d7f&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d631d7f",
  null
  
)

export default component.exports