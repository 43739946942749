<template>
  <page :header="{enabled: true, back: {name: 'operators-dashboard'}, center: {enabled: true, text: establishment ? establishment.attributes.name : ''}, rightHelpLinkEnabled: true}" :footer="{enabled: false}">
    <v-list v-if="establishment">
      <v-list-tile :to="{name: 'operators-establishment-edit', params: {slug: establishment.attributes.slug}}">
        <v-list-tile-content>
          <v-list-tile-title>General</v-list-tile-title>
          <v-list-tile-sub-title>Change address, hours, and amenities</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'operators-wines', params: {slug: establishment.attributes.slug}}">
        <v-list-tile-content>
          <v-list-tile-title>Wine List</v-list-tile-title>
          <v-list-tile-sub-title>Manage your wine list</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'operators-events', params: {slug: establishment.attributes.slug}}">
        <v-list-tile-content>
          <v-list-tile-title>Events</v-list-tile-title>
          <v-list-tile-sub-title>Manage your events</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'OperatorsEstablishment',
  created() {
    this.loadEstablishment({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishment', ['establishment'])
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishment', ['loadEstablishment'])
  }
}
</script>
<style scoped lang="sass">
</style>
