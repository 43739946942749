<template>
  <div>
    <v-btn
        round
        color="white"
        style="position: absolute; right: 0; top: env(safe-area-inset-top); height: 3.2rem"
        @click="toggleShowFilters({open: !computedShowFilters})"
        class="grey--text mt-3 mr-3">
      <v-icon class="mr-1 pt-1">icn icn-filter</v-icon>
      Filters
    </v-btn>
    <v-navigation-drawer
        absolute
        right
        v-if="computedShowFilters"
        v-model="computedShowFilters"
        class="drawer pa-3"
        temporary>
      <v-layout align-center class="my-1 mt-2">
        <v-icon class="mr-2 pt-1">icn icn-filter</v-icon>
        <h4 class="font-weight-regular grey--text">
          Filters
        </h4>
      </v-layout>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-tile
            v-for="filterAmenity in filterAmenities"
            :key="filterAmenity.amenity"
            @click="toggleFilter({filter: filterAmenity.amenity})">
          <v-list-tile-avatar class="mr-2">
            <v-icon
                size="2.5rem"
                :color="filters.includes(filterAmenity.amenity) ? null : 'gray'">
              {{filterAmenity.icon}}{{filters.includes(filterAmenity.amenity) ? '' : '-off'}}
            </v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title :class="`${filters.includes(filterAmenity.amenity) ? '' : 'gray'}--text`">
              {{filterAmenity.amenity}}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-btn
          round
          color="deep-red"
          class="white--text"
          @click="toggleShowFilters({open: false})">
        Apply
      </v-btn>
    </v-navigation-drawer>
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'MapScrollerMapFilters',
  computed: {
    ...Vuex.mapGetters('Map/Filters', ['filters', 'showFilters']),
    computedShowFilters: {
      get() { return this.showFilters },
      set(v) { this.toggleShowFilters({open: v}) }
    },
    filterAmenities() {
      return [
        {icon: 'icn icn-late',        amenity: 'Open Late'},
        {icon: 'icn icn-music',       amenity: 'Live Music'},
        {icon: 'icn icn-picnic',      amenity: 'Picnic Area'},
        {icon: 'icn icn-dog',         amenity: 'Dog Friendly'},
        {icon: 'icn icn-beer',        amenity: 'Beer on Tap'},
        {icon: 'icn icn-lodging',     amenity: 'Lodging'},
        {icon: 'icn icn-tour',        amenity: 'Wine Tours'},
        {icon: 'icn icn-dining',      amenity: 'Restaurant'},
        {icon: 'icn icn-sustainable', amenity: 'Sustainable'},
        {icon: 'icn icn-wedding',     amenity: 'Weddings'}
      ]
    }
  },
  methods: {
    ...Vuex.mapActions('Map/Filters', ['toggleFilter', 'toggleShowFilters'])
  }
}
</script>
<style scoped lang="sass">
.drawer
  height: auto !important
  width: auto !important
  min-width: 9rem !important
  padding-top: env(safe-area-inset-top) !important
::v-deep .v-list__tile
  padding: 0 !important
::v-deep .v-list__tile__avatar
  min-width: auto !important
</style>
