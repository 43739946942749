<template>
  <v-layout grow column class="tabs mt-3">
    <v-layout column class="tabs-picker">
      <v-layout space-between class="px-2 pt-1 pb-2">
        <v-layout
            column
            align-center
            v-for="tabDatum in tabData"
            :key="tabDatum.tab"
            @click.prevent="$router.push(tabDatum.route)"
            >
          <v-btn icon>
            <v-icon size="3rem">icn {{tabDatum.icon}}{{$route.name === tabDatum.route.name ? '-red' : '-gry'}}</v-icon>
          </v-btn>
          <span :class="{'light-gray--text': $route.name !== tabDatum.route.name}">{{tabDatum.tab}}</span>
        </v-layout>
      </v-layout>
      <v-img
          class="tab-pointer"
          :class="`position-${tabIndex}-${tabData.length}`"
          width="40px"
          height="12px"
          v-if="tabIndex >= 0"
          :src="require('../../assets/icon-pointer-down-gray.svg')">
      </v-img>
    </v-layout>
    <div class="tab-scroller" :class="{moving: $root.appInfo.pageTransitioning}">
      <router-view></router-view>
    </div>
  </v-layout>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'WineryTabs',
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    tabIndex() {
      return this.tabData.findIndex(tab => tab.route.name === this.$route.name)
    },
    tabData() {
      let tabs = [
        {route: {name: 'winery-winery'},   tab: 'Winery',    icon: 'icn-winery'},
        {route: {name: 'winery-events'},   tab: 'Events',    icon: 'icn-events'},
        {route: {name: 'winery-wines'},    tab: 'Wines',     icon: 'icn-wines'},
        {route: {name: 'winery-hours'},    tab: 'Hours',     icon: 'icn-hours'},
        {route: {name: 'winery-location'}, tab: 'Location',  icon: 'icn-location'}
      ]
      return this.winery.attributes.partnered ? tabs : tabs.filter(tab => ['Winery', 'Hours', 'Location'].includes(tab.tab))
    }
  }
}
</script>
<style scoped lang="sass">
.tabs
  width: 100%
  .tabs-picker
    height: 6rem
    border-top: 1px solid var(--light-gray)
    border-bottom: 1px solid var(--light-gray)
    position: relative
    .tab-pointer
      z-index: 10
      position: absolute
      top: calc(100% - 2px)
      transition: 0.3s left
      &.position-0-5
        left: calc(calc(100% - 1rem) * 0.1 - 20px + 0.5rem)
      &.position-1-5
        left: calc(calc(100% - 1rem) * 0.3 - 20px + 0.5rem)
      &.position-2-5
        left: calc(calc(100% - 1rem) * 0.5 - 20px + 0.5rem)
      &.position-3-5
        left: calc(calc(100% - 1rem) * 0.7 - 20px + 0.5rem)
      &.position-4-5
        left: calc(calc(100% - 1rem) * 0.9 - 20px + 0.5rem)
      &.position-0-3
        left: calc(calc(100% - 1rem) * 0.166 - 20px + 0.5rem)
      &.position-1-3
        left: calc(calc(100% - 1rem) * 0.5 - 20px + 0.5rem)
      &.position-2-3
        left: calc(calc(100% - 1rem) * 0.833 - 20px + 0.5rem)
  .tab-scroller
    overflow: auto
    &:not(.moving)
      height: calc(var(--viewport-height) - var(--reasonable-header-height) - 5rem - 2rem - 6rem)
</style>
