<template>
  <page :header="{enabled: true, back: {name: 'admins-spotlights', params: {id: $route.params.id}}, center: {enabled: true, text: spotlight ? `New spotlight` : ''}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-text-field v-model="computedName" :rules="NameRules" label="Name"></v-text-field>
      <v-text-field v-model="computedUrl" :rules="UrlRules" label="URL"></v-text-field>
      <h3 class="mt-2">Cover Image</h3>
      <v-layout row align-center>
        <v-sheet
            color="light-gray"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3 handle">
          <v-img
              v-if="spotlight.headerImageFileNameData || spotlight.headerImageFileNameUrl"
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="spotlight.headerImageFileNameData || spotlight.headerImageFileNameUrl">
          </v-img>
        </v-sheet>
        <image-upload v-model="computedHeaderImageFileNameData"></image-upload>
      </v-layout>
      <v-textarea v-model="computedDescription" :rules="DescriptionRules" label="Description"></v-textarea>
      <v-radio-group v-model="computedPlacement" :rules="PlacementRules" label="Type" required>
        <v-radio
            v-for="type in ['Guide Top', 'Guide Bottom']"
            :key="type"
            :label="type"
            :value="type">
        </v-radio>
      </v-radio-group>
      <div class="elevation-1 pa-2 mb-2">
        <h3>Date/time of event</h3>
        <v-layout align-center>
          <v-flex shrink>
            <v-checkbox v-model="computedDates"></v-checkbox>
          </v-flex>
          <v-text-field v-model="computedDatesCustom" :disabled="!computedDates" label="Freehand"></v-text-field>
        </v-layout>
        <v-checkbox v-model="computedAt" label="Start?"></v-checkbox>
        <div v-if="computedAt">
          <date-picker
              :value="computedAtDate"
              @change="computedAtDate = $event">
          </date-picker>
          <time-picker
              :value="computedAtTime"
              @change="computedAtTime = $event">
          </time-picker>
        </div>
        <v-checkbox
            v-model="computedAtEnd"
            label="End?"
            :disabled="!computedAt"
            >
        </v-checkbox>
        <div v-if="computedAtEnd">
          <date-picker
              :value="computedAtEndDate"
              @change="computedAtEndDate = $event">
          </date-picker>
          <time-picker
              :value="computedAtEndTime"
              @change="computedAtEndTime = $event">
          </time-picker>
        </div>
      </div>
      <div class="elevation-1 pa-2">
        <h3>Show during dates</h3>
        <v-checkbox v-model="computedShowAt" label="Start showing at?"></v-checkbox>
        <div v-if="computedShowAt">
          <date-picker
              :value="computedShowAtDate"
              @change="computedShowAtDate = $event">
          </date-picker>
        </div>
        <v-checkbox
            v-model="computedShowAtEnd"
            label="Stop showing at?"
            :disabled="!computedShowAt"
            >
        </v-checkbox>
        <div v-if="computedShowAtEnd">
          <date-picker
              :value="computedShowAtEndDate"
              @change="computedShowAtEndDate = $event">
          </date-picker>
        </div>
      </div>
      <v-layout>
        <v-checkbox v-model="computedActive" label="Active"></v-checkbox>
      </v-layout>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveSpotlight"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import moment      from 'moment'
import rules       from '../../lib/rules'
import DatePicker  from '../DatePicker.vue'
import TimePicker  from '../TimePicker.vue'
import ImageUpload from '../Operators/ImageUpload.vue'
export default {
  name: 'AdminsSpotlightNew',
  components: {
    DatePicker,
    TimePicker,
    ImageUpload
  },
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.initializeSpotlight()
  },
  computed: {
    ...Vuex.mapGetters('Admins/SpotlightNew', ['spotlight']),
    ...rules.Spotlight,
    computedName: {
      get() { return this.spotlight.name },
      set(v) { this.setSpotlight({spotlight: {name: v}}) }
    },
    computedUrl: {
      get() { return this.spotlight.url },
      set(v) { this.setSpotlight({spotlight: {url: v}}) }
    },
    computedHeaderImageFileNameData: {
      get() { return this.spotlight.headerImageFileNameData },
      set(v) { this.setSpotlight({spotlight: {headerImageFileNameData: v}}) }
    },
    computedDescription: {
      get() { return this.spotlight.description },
      set(v) { this.setSpotlight({spotlight: {description: v}}) }
    },
    computedPlacement: {
      get() { return this.spotlight.placement },
      set(v) { this.setSpotlight({spotlight: {placement: v}}) }
    },
    computedDates: {
      get() { return !this.spotlight.at },
      set() {
        this.computedAt = !this.computedAt
      }
    },
    computedDatesCustom: {
      get() { return this.spotlight.datesCustom },
      set(v) { this.setSpotlight({spotlight: {datesCustom: v}}) }
    },
    computedAt: {
      get() { return !!this.spotlight.at },
      set(v) {
        if (v) {
          this.setSpotlight({spotlight: {datesCustom: ''}})
        }
        this.setSpotlight({spotlight: {at: v ? moment().add(10, 'days').toDate() : null}})
        this.setSpotlight({spotlight: {atEnd: null}})
      }
    },
    computedAtDate: {
      get() { return this.spotlight.at },
      set(v) { this.setSpotlight({spotlight: {at: v}}) }
    },
    computedAtTime: {
      get() { return this.spotlight.at },
      set(v) { this.setSpotlight({spotlight: {at: v}}) }
    },
    computedAtEnd: {
      get() { return !!this.spotlight.atEnd },
      set(v) { this.setSpotlight({spotlight: {atEnd: v ? moment(this.spotlight.at).add(3, 'hours').toDate() : null}}) }
    },
    computedAtEndDate: {
      get() { return this.spotlight.atEnd },
      set(v) { this.setSpotlight({spotlight: {atEnd: v}}) }
    },
    computedAtEndTime: {
      get() { return this.spotlight.atEnd },
      set(v) { this.setSpotlight({spotlight: {atEnd: v}}) }
    },
    computedShowAt: {
      get() { return !!this.spotlight.showAt },
      set(v) {
        this.setSpotlight({spotlight: {showAt: v ? moment().add(10, 'days').toDate() : null}})
        this.setSpotlight({spotlight: {showAtEnd: null}})
      }
    },
    computedShowAtDate: {
      get() { return this.spotlight.showAt },
      set(v) { this.setSpotlight({spotlight: {showAt: v}}) }
    },
    computedShowAtEnd: {
      get() { return !!this.spotlight.showAtEnd },
      set(v) { this.setSpotlight({spotlight: {showAtEnd: v ? moment(this.spotlight.showAt).add(30, 'days').toDate() : null}}) }
    },
    computedShowAtEndDate: {
      get() { return this.spotlight.showAtEnd },
      set(v) { this.setSpotlight({spotlight: {showAtEnd: v}}) }
    },
    computedActive: {
      get() { return this.spotlight.active },
      set(v) { this.setSpotlight({spotlight: {active: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/SpotlightNew', ['initializeSpotlight', 'setSpotlight', 'attemptSaveSpotlight']),
    async saveSpotlight() {
      this.processing = true
      if (await this.attemptSaveSpotlight()) {
        this.$router.push({name: 'admins-spotlights'})
      }
      this.processing = false
    }
  }
}
</script>
