'use strict'

export default {
  namespaced: true,
  state: {
    show: false
  },
  getters: {
    show(state) { return state.show },
  },
  mutations: {
    setShow(state, {show}) {
      state.show = show
    },
  },
  actions: {
    initialize({dispatch, rootGetters}) {
      if (!rootGetters['Acknowledgements/acknowledgements']['capture-user']) {
        setTimeout(() => {
          dispatch('setShow', {show: true})
        }, 20000)
      }
    },
    setShow({commit}, {show}) {
      commit('setShow', {show})
    },
  },
}
