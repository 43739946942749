<template>
  <lean :left-to="back || {name: 'wineries'}">
    <page
        :header="{back: back || {name: 'wineries'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}"
        :footer="{enabled: false}">
      <v-layout
          column
          align-center
          v-if="winery"
          class="winery-layout"
          >
        <v-img
            :src="winery.attributes.headerImageFileNameUrl || require('../assets/drinking.jpg')"
            :height="$root.reasonableHeaderHeight" width="100%"
            @click="showPhotos = true"
            >
          <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top"></v-img>
        </v-img>
        <v-btn
            class="winery-pics"
            icon
            @click="showPhotos = true"
            color="transparent-gray"
            v-if="winery.attributes.partnered"
            :style="{top: `calc(${$root.reasonableHeaderHeight}px - 4rem)`}"
            >
          <v-icon>icn icn-pics icn-size icn-size-1-5</v-icon>
        </v-btn>
        <v-layout column align-center>
          <v-avatar size="8rem" style="margin-top: -4rem" color="white">
            <v-avatar size="7rem">
              <v-img v-if="winery.attributes.logoImageFileNameUrl" :src="winery.attributes.logoImageFileNameUrl"></v-img>
            </v-avatar>
          </v-avatar>
        </v-layout>
        <v-sheet height="2rem">
          <v-layout align-center class="title pt-1">
            {{winery ? winery.attributes.name : null}}
            <v-icon v-if="winery.attributes.partnered" class="ml-2">icn icn-verified</v-icon>
          </v-layout>
          <div class="caption pt-1 grey--text">{{winery ? winery.attributes.streetAddress : null}}</div>
        </v-sheet>
        <winery-tabs></winery-tabs>
      </v-layout>
      <winery-photos
          v-if="showPhotos"
          v-model="showPhotos"
          >
      </winery-photos>
    </page>
  </lean>
</template>
<style scoped lang="sass">
.winery-layout
  position: relative
  .winery-pics
    position: absolute
    right: 0.3rem
</style>
<script>
import Vuex from 'vuex'
import WineryPhotos from './Winery/Photos.vue'
import WineryTabs   from './Winery/Tabs.vue'
export default {
  name: 'Winery',
  components: {
    WineryPhotos,
    WineryTabs
  },
  data() {
    return {
      showPhotos: false
    }
  },
  created() {
    this.loadWinery({slug: this.$route.params.slug})
    this.setBack({back: this.$route.query.back})
    if (this.$route.query.back) {
      this.$router.replace({name: this.$route.name, params: {slug: this.$route.params.slug}})
    }
  },
  computed: {
    ...Vuex.mapGetters('Winery', ['winery', 'back'])
  },
  methods: {
    ...Vuex.mapActions('Winery', ['loadWinery', 'setTab', 'setBack'])
  }
}
</script>
