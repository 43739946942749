<template>
  <transition name="page-fade-in" appear>
    <div
        class="header-nav"
        :class="{scrolled: $root.scrolled, 'header-nav-no-padding': config.noPadding, 'header-nav-fixed': config.enabled && config.fixed}"
        v-if="config.enabled">
      <v-layout align-center justify-center class="header-nav-bar" :class="{'transparent-background': config.transparentBackground}">
        <v-flex xs3>
          <slot name="left">
            <transition name="fade-fly-from-left">
              <router-link v-if="config.back" :to="config.back" class="d-inline-block">
                <v-btn v-if="config.backWhite" fab icon dark small><v-icon>icn icn-back-{{$root.scrolled ? 'gry' : 'wht'}}</v-icon></v-btn>
                <v-btn v-else flat icon><v-icon>icn icn-back-gry</v-icon></v-btn>
              </router-link>
            </transition>
          </slot>
        </v-flex>
        <v-flex xs6>
          <v-layout align-center justify-center>
            <slot name="center">
              <template v-if="config.center.enabled">
                <router-link v-if="config.center.to" :to="config.center.to" class="text-truncate">{{config.center.text}}</router-link>
                <span v-else-if="config.center.text" class="text-truncate">{{config.center.text}}</span>
              </template>
            </slot>
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout align-center justify-end>
            <slot name="right">
              <transition name="fade-fly-from-right">
                <template v-if="config.rightHelpLinkEnabled">
                  <a href="https://www.temeculalife.tv/help" target="_blank" class="text-truncate pa-3">Help?</a>
                </template>
              </transition>
            </slot>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </transition>
</template>

<script>
import Vuex from 'vuex'
export default {
  name: 'HeaderNavigation',
  computed: {
    ...Vuex.mapGetters('HeaderNavigation', ['config'])
  }
}
</script>

<style scoped lang="sass">
.header-nav
  padding-top: env(safe-area-inset-top)
  z-index: 20
  &.header-nav-no-padding
    padding-top: 0
    .header-nav-bar
      height: var(--header-height)
  &.header-nav-fixed
    .header-nav-bar
      position: fixed
      top: env(safe-area-inset-top)
      left: 0
      width: var(--viewport-width)
      z-index: 10
  &.scrolled
    .header-nav-bar
      background-color: white !important
      border-bottom: 1px solid var(--light-gray) !important
      top: 0 !important
      height: calc(env(safe-area-inset-top) + var(--header-height)) !important
      padding-top: env(safe-area-inset-top) !important
  .header-nav-bar
    height: calc(var(--header-height))
    background-color: white
    &.transparent-background
      background-color: transparent
</style>
