<template>
  <page :header="{enabled: false}">
    <map-scroller-map></map-scroller-map>
    <v-layout
        column
        class="shelf"
        :class="{hidden: mapOpen, transition: shelfTransition}"
        :style="{top: shelfTop, '--shelf-top': shelfTop}">
      <div
          class="search-input-container px-3 pb-3"
          v-touch:start="startedMovingHandler"
          v-touch:end="endedMovingHandler"
          v-touch:moving="movingHandler">
        <v-text-field
            solo
            single-line
            clearable
            clear-icon="icn icn-close-gry"
            hide-details
            background-color="white"
            prepend-inner-icon="icn icn-search-gry"
            placeholder="Search for Wineries"
            class="search-input"
            v-model="search"
            @click="openWineries">
          <v-icon slot="append" @click.prevent="$router.push({name: 'winery-list'})">icn icn-list-gry</v-icon>
        </v-text-field>
      </div>
      <div class="scroller-container">
        <div class="scroller pb-6">
          <router-view></router-view>
        </div>
      </div>
    </v-layout>
  </page>
</template>
<script>
import Vuex from 'vuex'
import _ from 'lodash'
import MapScrollerMap from './MapScroller/Map.vue'
export default {
  name: 'MapScroller',
  components: {
    MapScrollerMap
  },
  data() {
    return {
      originOffset: null,
      movingOffset: 0
    }
  },
  created() {
    this.setFocusedEstablishment({establishment: null})
  },
  computed: {
    ...Vuex.mapGetters('Map', ['mapOpen']),
    ...Vuex.mapGetters('Wineries', ['filters']),
    search: {
      get() { return this.filters.search },
      set(v) { this.setFilters({search: v}) }
    },
    shelfTop() {
      const baseOffsets = this.mapOpen ? 'calc(var(--viewport-height) - var(--footer-height) - 4.5rem)' : '13rem'
      return this.movingOffset ? `calc(${baseOffsets} + ${this.movingOffset}px)` : baseOffsets
    },
    shelfTransition() {
      return !this.movingOffset
    }
  },
  methods: {
    ...Vuex.mapActions('Map', ['toggleMapOpen', 'setFocusedEstablishment']),
    ...Vuex.mapActions('Wineries', ['setFilters']),
    openWineries() {
      this.$router.push({name: 'wineries'})
      this.toggleMapOpen(false)
    },
    startedMovingHandler(e) {
      this.originOffset = _.get(e, ['touches', '0', 'screenY'], 0)
    },
    endedMovingHandler() {
      if (this.mapOpen && this.movingOffset < -20) {
        this.toggleMapOpen(false)
      } else if (!this.mapOpen && this.movingOffset > 20) {
        this.toggleMapOpen(true)
      }
      this.movingOffset = 0
    },
    movingHandler(e) {
      const position = _.get(e, ['touches', '0', 'screenY'], undefined)
      if (position !== undefined) {
        const offset = position - this.originOffset
        if (this.mapOpen && offset < 0) {
          this.movingOffset = offset
        } else if (!this.mapOpen && offset > 0) {
          this.movingOffset = offset
        }
      }
    }
  },
  watch: {
    '$route.name'() { this.toggleMapOpen(false) }
  }
}
</script>
<style scoped lang="sass">
.shelf
  position: fixed
  height: calc(var(--viewport-height) - 13rem)
  width: var(--viewport-width)
  &.transition
    transition: top 0.2s
  .search-input-container
    position: absolute
    top: 0
    left: 0
    right: 0
    .search-input ::v-deep .v-input__control > .v-input__slot
      border-radius: 3rem
  .scroller-container
    overflow: scroll
    margin-top: 4.6rem
    background-color: white
    .scroller
      min-height: calc(var(--viewport-height) - var(--shelf-top) - var(--footer-height))
</style>
