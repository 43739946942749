'use strict'

import _      from 'lodash'

import api    from '../../api'

export default {
  namespaced: true,
  state: {
    eventReminders: [],
  },
  getters: {
    eventReminders(state) { return state.eventReminders },
    eventRemindersByEventId(state) { return _.keyBy(state.eventReminders, 'attributes.eventId') }
  },
  mutations: {
    setEventReminders(state, {eventReminders}) {
      state.eventReminders = [...eventReminders]
    }
  },
  actions: {
    async loadEventReminders({commit, rootGetters}) {
      if (rootGetters['AppInfo/notificationsEnabled'] && rootGetters['AppInfo/deviceToken']) {
        let params = {}
        if (rootGetters['AppInfo/platform'] === 'iOS') {
          params['iosPushId'] = rootGetters['AppInfo/deviceToken']
        } else if (rootGetters['AppInfo/platform'] === 'Android') {
          params['androidPushId'] = rootGetters['AppInfo/deviceToken']
        }
        const eventReminders = (await api.EventReminders.index({params})).data.data
        commit('setEventReminders', {eventReminders})
      }
    },
    async createEventReminder({dispatch, rootGetters}, {event, daysBefore}) {
      await dispatch('AppInfo/setDeviceToken', null, {root: true})
      if (rootGetters['AppInfo/notificationsEnabled'] && rootGetters['AppInfo/deviceToken']) {
        let params = {eventId: event.attributes.id, daysBefore}
        if (rootGetters['AppInfo/platform'] === 'iOS') {
          params['iosPushId'] = rootGetters['AppInfo/deviceToken']
        } else if (rootGetters['AppInfo/platform'] === 'Android') {
          params['androidPushId'] = rootGetters['AppInfo/deviceToken']
        }
        await api.EventReminders.create({params: {eventReminder: params}})
        dispatch('loadEventReminders')
      }
    },
    async destroyEventReminder({dispatch, rootGetters}, {event}) {
      await dispatch('AppInfo/setDeviceToken', null, {root: true})
      if (rootGetters['AppInfo/notificationsEnabled'] && rootGetters['AppInfo/deviceToken']) {
        let params = {}
        if (rootGetters['AppInfo/platform'] === 'iOS') {
          params['iosPushId'] = rootGetters['AppInfo/deviceToken']
        } else if (rootGetters['AppInfo/platform'] === 'Android') {
          params['androidPushId'] = rootGetters['AppInfo/deviceToken']
        }
        await api.EventReminders.destroy(event.attributes.id, {params})
        dispatch('loadEventReminders')
      }
    },
  }
}
