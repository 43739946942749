<template>
  <lean :left-to="{name: 'partners'}">
    <page
        :header="{back: {name: 'partners'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}"
        :footer="{enabled: false}">
      <v-layout column align-center v-if="partner">
        <v-img :src="partner.attributes.headerImageFileNameUrl || require('../assets/drinking.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
          <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top"></v-img>
        </v-img>
        <v-layout column align-center>
          <v-avatar size="8rem" style="margin-top: -4rem" color="white">
            <v-avatar size="7rem">
              <v-img v-if="partner.attributes.logoImageFileNameUrl" :src="partner.attributes.logoImageFileNameUrl"></v-img>
            </v-avatar>
          </v-avatar>
        </v-layout>
        <v-layout column align-center class="px-3 mb-2">
          <div class="title pt-1">{{partner.attributes.name}}</div>
          <div class="caption pt-1 grey--text">{{partner.attributes.address}}</div>
        </v-layout>
        <div
            v-if="partner.attributes.description"
            style="white-space: pre-line"
            class="my-2 px-3">
          {{partner.attributes.description}}
        </div>
        <v-layout
            v-if="partner.attributes.website"
            row
            align-center
            justify-space-between
            class="px-3"
            style="width: 100%"
            >
          <span class="font-weight-bold">Learn more</span>
          <v-btn
              round
              color="deep-red"
              class="white--text px-4 mr-0"
              @click="moreInformation">
            Visit Website
          </v-btn>
        </v-layout>
        <v-layout
            column
            justify-end
            id="map-wrapper"
            class="mt-3"
            :class="{'no-map': !partner.attributes.lat || !partner.attributes.lng}"
            >
          <GmapMap
              :options="{styles: require('../lib/map-style').default, draggable: false, panControl: false, mapTypeControl: false, streetViewControl: false, zoomControl: false, fullscreenControl: false}"
              :center="{lat: $options.filters.stringToFloat(partner.attributes.lat), lng: $options.filters.stringToFloat(partner.attributes.lng)}"
              :zoom="16"
              mapTypeId="roadmap"
              v-if="partner.attributes.lat && partner.attributes.lng"
              class="map">
            <GmapMarker
                key="you-are-here"
                :position="{lat: $options.filters.stringToFloat(partner.attributes.lat), lng: $options.filters.stringToFloat(partner.attributes.lng)}"
                :z-index="2"
                :options="{icon: {scaledSize: {height: 26, width: 26}, url: require('../assets/user-location.svg')}}">
            </GmapMarker>
          </GmapMap>
          <v-layout shrink justify-space-around class="mb-3 mx-3">
            <v-btn
                round
                @click.prevent="openMap"
                color="deep-red"
                class="white--text mx-2"
                v-if="partner.attributes.address"
                large
                block>
              Directions
            </v-btn>
            <v-btn
                round
                @click.prevent="callBusiness"
                color="deep-red"
                class="white--text mx-2"
                v-if="simplePhone"
                large
                block>
              Call
            </v-btn>
          </v-layout>
        </v-layout>
      </v-layout>
    </page>
  </lean>
</template>
<script>
import api  from '../lib/api'
import Vuex from 'vuex'
import _    from 'lodash'
export default {
  name: 'Partner',
  created() {
    this.loadPartner({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Partner', ['partner']),
    simplePhone() {
      return (_.get(this.partner, 'attributes.phone', '') || '').replace(/[^\d]/g, '')
    }
  },
  methods: {
    ...Vuex.mapActions('Partner', ['loadPartner']),
    moreInformation() {
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/partner/click_external/${this.partner.attributes.slug}`}}})
      window.FirebasePlugin?.logEvent("click_external", {
        content_type: 'partner',
        event_label: this.partner.attributes.name,
        event_id: this.partner.attributes.slug
      });
      this.$gtag.event(
        `Partner - click_external - ${this.partner.attributes.id} - ${this.partner.attributes.name}`,
        {
          eventCategory: 'Partner',
          eventAction: 'click_external',
          eventLabel: this.partner.attributes.name,
          eventValue: this.partner.attributes.slug
        }
      );
      window.open(this.partner.attributes.website, '_system')
    },
    openMap() {
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/directions`}}})
      if (this.$root.platform.os.family.match(/^iOS/)) {
        window.open(`maps:maps.apple.com/?q=${encodeURIComponent(this.partner.attributes.address)}`, '_system')
      } else {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(this.partner.attributes.address)}`, '_system')
      }
    },
    callBusiness() {
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/call`}}})
      window.open(`tel:${this.simplePhone}`, '_system')
    }
  }
}
</script>
<style scoped lang="sass">
#map-wrapper
  position: relative
  width: var(--viewport-width)
  min-height: 20rem
  height: 20rem
  &.no-map
    min-height: auto
    height: auto
  .map
    position: absolute
    top: 0
    left: 0
    width: var(--viewport-width)
    min-height: 20rem
    height: 20rem
</style>
