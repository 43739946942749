import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=8d30b432&scoped=true&"
import script from "./Events.vue?vue&type=script&lang=js&"
export * from "./Events.vue?vue&type=script&lang=js&"
import style0 from "./Events.vue?vue&type=style&index=0&id=8d30b432&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d30b432",
  null
  
)

export default component.exports