<template>
  <v-layout column class="pa-4">
    <v-layout
        row
        v-for="day in computedHours"
        :key="day.name"
        class="py-2 px-2"
        :class="day.status">
      <v-flex xs4 :class="day.status === 'hours-open-soon' ? 'deep-red-light--text' : 'deep-red--text'">
        <v-layout align-center fill-height>
          {{day.statusName}}
        </v-layout>
      </v-flex>
      <v-flex xs2>
        <v-layout align-center fill-height>
          {{day.name}}
        </v-layout>
      </v-flex>
      <v-flex xs6>
        {{day.hours}}
      </v-flex>
    </v-layout>
    <div class="mt-3 light-gray--text text-xs-center">Hours subject to change for Holidays and Private Events. Always confirm ahead of time.</div>
  </v-layout>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'HoursTab',
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    computedHours() {
      return this.$options.filters.hoursInfo(this.winery)
    }
  }
}
</script>
<style scoped lang="sass">
.hours-today
  border: 1px solid var(--light-gray)
  border-radius: 3rem
.hours-open
  border: 1px solid var(--deep-red)
  border-radius: 3rem
.hours-open-soon
  border: 1px solid var(--deep-red-light)
  border-radius: 3rem
</style>
