<template>
  <v-layout column class="pa-4">
    <template v-if="winery && winery.relationships.wines && winery.relationships.wines.length">
      <div class="side-scroller-wrapper">
        <v-layout id="side-scroller">
          <v-layout row v-scroll:#side-scroller="scroll">
            <v-btn
                round
                :color="tag === computedActiveTag ? 'deep-red' : 'gray'"
                :outline="tag !== computedActiveTag"
                :class="{'white--text': tag === computedActiveTag}"
                class="px-3 ml-0 mr-2"
                style="height: 2.5rem; min-width: auto"
                v-for="tag in tags"
                :key="tag"
                @click.prevent="activeTag = tag">
              {{tag}}
            </v-btn>
          </v-layout>
        </v-layout>
        <div
            class="more"
            v-if="showScroll"
            >
          <v-icon size="1rem">fas fa-arrow-right</v-icon>
        </div>
      </div>
      <div class="my-3" v-for="wine in shownWines" :key="wine.id">
        <h3>{{wine.attributes.name}}</h3>
        <div style="white-space: pre-line">{{wine.attributes.description}}</div>
        <v-menu bottom v-if="wine.attributes.criticScore">
          <v-layout slot="activator" align-center class="mt-1 font-weight-bold">
            <v-icon class="mr-2">icn icn-award</v-icon> {{wine.attributes.criticScore}} points
          </v-layout>
          <div class="white pa-2">
            Score out of 100 from one of the<br />top 10 wine review competitions
          </div>
        </v-menu>
      </div>
    </template>
    <transition name="fade" appear v-else>
      <v-icon class="mt-5">icn icn-no-wine</v-icon>
    </transition>
  </v-layout>
</template>
<script>
import Vuex from 'vuex'
import _ from 'lodash'
export default {
  name: 'WinesTab',
  data() {
    return {
      activeTag: null,
      showScroll: true
    }
  },
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    computedActiveTag() {
      return this.activeTag || this.tags[0]
    },
    shownWines() {
      return this.winery.relationships.wines.filter(wine => _.get(wine, 'attributes.tags', [null]).includes(this.computedActiveTag))
    },
    tags() {
      const tagPreferredOrder = ['Sparkling', 'White', 'Rosé', 'Red', 'Sweet']
      const tagsPresent = _.uniq(
        _.compact(
          _.flatten(
            _.map(this.winery.relationships.wines, 'attributes.tags')
          )
        )
      )
      return [..._.intersection(tagPreferredOrder, tagsPresent), ..._.without(tagsPresent, ...tagPreferredOrder)]
    }
  },
  methods: {
    scroll(e) {
      this.showScroll = e.target.scrollLeft === 0
    }
  }
}
</script>
<style scoped lang="sass">
.side-scroller-wrapper
  width: 100%
  overflow: hidden
  position: relative
  #side-scroller
    width: 100%
    overflow-x: auto
  .more
    position: absolute
    right: 0
    top: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
</style>
