'use strict'

import store from './store'

const authRoute = (to, from, next) => {
  store.getters['Login/loggedIn'] ? next() : next({name: 'login'})
}

const authAdminRoute = (to, from, next) => {
  store.getters['Login/loggedIn'] && store.getters['Login/currentUser'].attributes.admin ? next() : next({name: 'operators-login'})
}

export {
  authRoute,
  authAdminRoute
}
