'use strict'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    wineries: []
  },
  getters: {
    wineries(state) { return state.wineries }
  },
  mutations: {
    setWineries(state, {wineries}) {
      state.wineries = [...wineries]
    }
  },
  actions: {
    async loadWineries({commit, getters}) {
      if (!getters.wineries.length) {
        const wineries = (await api.Establishments.index({params: {filter: {type: 'Winery'}}})).data.data
        commit('setWineries', {wineries})
      }
    }
  }
}
