<template>
  <v-dialog v-model="modal" :disabled="readonly">
    <v-text-field
        :label="label"
        :disabled="disabled"
        slot="activator"
        :value="formattedDate"
        prepend-icon="event"
        readonly>
    </v-text-field>
    <v-date-picker
        v-if="modal"
        v-model="computedDate"
        full-width
        :readonly="readonly"
        >
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn flat color="primary" @click="ok">OK</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DatePicker',
  props: {
    disabled: Boolean,
    label: String,
    displayFormat: {
      type: String,
      default: 'dddd, MMMM D'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: null
  },
  data() {
    return {
      modal: false,
      date: null
    }
  },
  created() {
    this.date = this.value
  },
  computed: {
    formattedDate() {
      return moment(this.date).format(this.displayFormat)
    },
    computedDate: {
      get() { return moment(this.date).format('YYYY-MM-DD') },
      set(v) { this.date = moment(v + moment(this.date).format(' HH:mm'), 'YYYY-MM-DD HH:mm') }
    }
  },
  methods: {
    ok() {
      this.$emit('change', this.date)
      this.modal = false
    }
  },
  watch: {
    modal() {
      this.date = this.value
    },
    value(v) {
      this.date = v
    }
  }
}
</script>
