<template>
  <page :header="{enabled: true, back: {name: 'login'}, center: {enabled: true, text: 'Businesses'}, rightHelpLinkEnabled: true}" :footer="{enabled: false}">
    <list-of-establishments :establishments="establishments"></list-of-establishments>
  </page>
</template>
<script>
import Vuex from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'OperatorsDashboard',
  components: {
    ListOfEstablishments
  },
  created() {
    this.loadEstablishments()
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishments', ['establishments'])
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishments', ['loadEstablishments'])
  }
}
</script>
<style scoped lang="sass">
</style>
