'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    photos: []
  },
  getters: {
    photos(state) { return state.photos }
  },
  mutations: {
    setPhotos(state, {photos}) {
      state.photos = [...photos]
    }
  },
  actions: {
    async clearPhotos({commit}) {
      commit('setPhotos', {photos: []})
    },
    async loadPhotos({commit}, {slug}) {
      const photos = (await api.EstablishmentPhotos.index({params: {filter: {establishmentId: slug}}})).data.data
      commit('setPhotos', {photos})
    }
  }
}
