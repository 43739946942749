'use strict'

import Establishments        from './Operators/Establishments'
import Establishment         from './Operators/Establishment'
import EstablishmentEdit     from './Operators/EstablishmentEdit'
import Events                from './Operators/Events'
import EventEdit             from './Operators/EventEdit'
import EventNew              from './Operators/EventNew'
import Wines                 from './Operators/Wines'
import WineEdit              from './Operators/WineEdit'
import WineNew               from './Operators/WineNew'

export default {
  namespaced: true,
  modules: {
    Establishments,
    Establishment,
    EstablishmentEdit,
    Events,
    EventEdit,
    EventNew,
    Wines,
    WineEdit,
    WineNew
  }
}
