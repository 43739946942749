<template>
  <page :header="{enabled: false}" :footer="{enabled: true}">
    <v-layout
        class="pb-0"
        column>
      <v-img :src="require('../assets/vip-deals.jpg')" :height="$root.reasonableHeaderHeight" class="mb-3">
        <v-img :src="require('../assets/shadow-downward.png')" contain :height="$root.reasonableHeaderHeight" class="shading-top">
          <v-layout column fill-height justify-center align-center class="pa-2 white--text">
            <v-flex shrink class="headline mb-3">Experiences</v-flex>
            <v-icon color="white" class="icn-size icn-size-5">icn icn-deals-wht</v-icon>
          </v-layout>
        </v-img>
      </v-img>
      <v-btn
          fab
          :to="{name: 'menu'}"
          color="white"
          class="menu-button grey--text elevation-3 ml-3 mt-3">
        <v-icon class="mr-1 ml-2">icn icn-menu-gry</v-icon>
      </v-btn>
      <v-layout
          column
          align-center
          class="pa-3"
          >
        <v-form v-model="valid">
          <v-text-field
              placeholder="Email"
              :rules="EmailRules"
              v-model="email"
              >
          </v-text-field>
          <v-btn
              @click="createInterestedUser"
              :color="finished ? 'green' : 'deep-red'"
              dark
              round
              :depressed="finished"
              :ripple="!finished"
              >
            Let us know you're interested!
            <v-icon
                color="white"
                class="ml-2"
                v-if="finished"
                >
              fas fa-check
            </v-icon>
          </v-btn>
        </v-form>
      </v-layout>
      <v-layout column align-center class="display-1 pt-3">
        <v-img :src="require('../assets/icons/img-coming-soon.svg')" width="15rem"></v-img>
      </v-layout>
    </v-layout>
  </page>
</template>
<script>
import rules from '../lib/rules'
import api from '../lib/api'
export default {
  name: 'VipDeals',
  data() {
    return {
      valid: true,
      email: '',
      finished: false
    }
  },
  computed: {
    ...rules.InterestedUser
  },
  methods: {
    async createInterestedUser() {
      if (!this.finished && this.valid) {
        await api.InterestedUser.create({params: {interestedUser: {email: this.email}}})
        this.finished = true
      }
    }
  }
}
</script>
<style scoped lang="sass">
.menu-button
  position: absolute !important
  left: 0rem
  top: env(safe-area-inset-top)
  height: 3.2rem
  width: 3.2rem
</style>
