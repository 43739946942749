<template>
  <lean :left-to="{name: 'guide'}">
    <page :header="{back: {name: 'guide'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}">
      <v-img :src="require('../assets/wineries-open-late.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
        <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top">
          <v-layout column fill-height justify-start align-center class="pa-2 white--text">
            <v-flex shrink class="headline mt-5">Open Late</v-flex>
          </v-layout>
        </v-img>
      </v-img>
      <div class="px-4 pt-3">
        <div class="title text-xs-center mb-2">Open today until...</div>
        <v-layout class="open-late-picker" justify-space-between>
          <v-btn
              round
              v-for="hour in openUntils"
              :key="hour"
              :color="computedOpenUntil === hour ? 'deep-red' : 'white'"
              :class="computedOpenUntil === hour ? 'white--text' : 'gray--text'"
              class="ma-0 elevation-0"
              @click.prevent="computedOpenUntil = hour"
              >
            {{hourNice({hour})}}
          </v-btn>
        </v-layout>
      </div>
      <list-of-establishments
          :establishments="sortedTimeRelevantWineries"
          :condensed="false"
          route-name="winery-hours"
          :link-query="{back: {name: 'wineries-open-late'}}"
          class="px-2 pt-3 pb-6"></list-of-establishments>
    </page>
  </lean>
</template>
<script>
import _                    from 'lodash'
import moment               from 'moment'
import Vuex                 from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'WineriesOpenLate',
  components: {
    ListOfEstablishments
  },
  data() {
    return {
      openUntil: null
    }
  },
  created() {
    this.loadWineries()
  },
  computed: {
    ...Vuex.mapGetters('WineryList', ['wineries']),
    today() {
      return moment().format('dddd')
    },
    computedOpenUntil: {
      get() { return this.openUntil || this.openUntils[0] },
      set(v) { this.openUntil = v }
    },
    filteredWineries() {
      return this.wineries.filter(winery => winery.attributes.amenities.includes('Open Late'))
    },
    timeRelevantWineries() {
      return this.filteredWineries.filter(winery => {
        const endTime = _.get(winery, `attributes.hours.${this.today}.endTime`, null)
        return this.computedOpenUntil && endTime && endTime.replace(/:\d\d$/, '') >= this.computedOpenUntil
      })
    },
    sortedTimeRelevantWineries() {
      return _.sortBy(
        this.timeRelevantWineries,
        winery => [!winery.attributes.partnered, winery.attributes.name]
      )
    },
    openUntils() {
      return _.takeRight(
        _.uniq(
          _.compact(
            this.filteredWineries.map(winery => {
              return _.get(winery, `attributes.hours.${this.today}.endTime`, null)
            })
          ).map(time => time.replace(/:\d\d$/, ''))
        ).sort(),
        3
      )
    }
  },
  methods: {
    ...Vuex.mapActions('WineryList', ['loadWineries']),
    hourNice({hour}) {
      return moment().set('hour', hour).format('ha')
    }
  }
}
</script>
<style scoped lang="sass">
.open-late-picker
  border: 1px solid var(--gray)
  border-radius: 2rem
</style>
