<template>
  <lean :left-to="{name: 'guide'}">
    <page :header="{back: {name: 'guide'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}">
      <v-img :src="require('../assets/wineries-picnic-area.jpg')" height="17rem" width="100%">
        <v-img :src="require('../assets/shadow-downward.png')" height="17rem" contain class="shading-top">
          <v-layout column fill-height justify-start align-center class="pa-2 white--text">
            <v-flex shrink class="headline mt-5">Picnic Area</v-flex>
          </v-layout>
        </v-img>
      </v-img>
      <list-of-establishments
          :establishments="sortedFilteredWineries"
          :condensed="false"
          :link-query="{back: {name: 'wineries-picnic-area'}}"
          class="px-2 pt-3 pb-6"></list-of-establishments>
    </page>
  </lean>
</template>
<script>
import _                    from 'lodash'
import Vuex                 from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'WineriesPicnicArea',
  components: {
    ListOfEstablishments
  },
  created() {
    this.loadWineries()
  },
  computed: {
    ...Vuex.mapGetters('WineryList', ['wineries']),
    filteredWineries() {
      return this.wineries.filter(winery => winery.attributes.amenities.includes('Picnic Area'))
    },
    sortedFilteredWineries() {
      return _.sortBy(
        this.filteredWineries,
        winery => [!winery.attributes.partnered, winery.attributes.name]
      )
    }
  },
  methods: {
    ...Vuex.mapActions('WineryList', ['loadWineries'])
  }
}
</script>
<style scoped lang="sass">
</style>
