<template>
  <page :header="{enabled: true, back: {name: 'operators-events', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'New Event'}}" :footer="{enabled: false}">
    <v-form v-model="valid" v-if="establishment" class="pa-3">
      <v-text-field v-model="computedName" :rules="NameRules" label="Name"></v-text-field>
      <v-layout row align-center>
        <v-sheet
            color="grey"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3 handle">
          <v-img
              v-if="event.imageFileNameData || event.imageFileNameUrl"
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="event.imageFileNameData || event.imageFileNameUrl">
          </v-img>
        </v-sheet>
        <image-upload v-model="computedImageFileNameData"></image-upload>
      </v-layout>
      <v-textarea v-model="computedDescription" :rules="DescriptionRules" label="Description" counter="1000"></v-textarea>
      <div>
        <h3>Start</h3>
        <date-picker
            :value="computedAtDate"
            @change="computedAtDate = $event">
        </date-picker>
        <time-picker
            :value="computedAtTime"
            @change="computedAtTime = $event">
        </time-picker>
        <h3>End</h3>
        <date-picker
            :value="computedAtEndDate"
            @change="computedAtEndDate = $event">
        </date-picker>
        <time-picker
            :value="computedAtEndTime"
            @change="computedAtEndTime = $event">
        </time-picker>
      </div>
      <v-checkbox v-model="computedRepeatDaily" label="Repeat Daily?"></v-checkbox>
      <v-checkbox v-model="computedRepeatWeekly" label="Repeat Weekly?"></v-checkbox>
      <div class="elevation-1 pa-2">
        <h3>Show during dates</h3>
        <v-checkbox v-model="computedShowAt" label="Start showing at?"></v-checkbox>
        <div v-if="computedShowAt">
          <date-picker
              :value="computedShowAtDate"
              @change="computedShowAtDate = $event">
          </date-picker>
        </div>
        <v-checkbox
            v-model="computedShowAtEnd"
            label="Stop showing at?"
            :disabled="!computedShowAt"
            >
        </v-checkbox>
        <div v-if="computedShowAtEnd">
          <date-picker
              :value="computedShowAtEndDate"
              @change="computedShowAtEndDate = $event">
          </date-picker>
        </div>
      </div>
      <v-layout
          justify-start
          align-center
          >
        <v-flex shrink class="mr-3">
          <v-checkbox v-model="computedLiveMusic" label="Live Music"></v-checkbox>
        </v-flex>
        <transition name="fade-fly-from-right">
          <span v-if="computedLiveMusic" class="red--text">Please specify artists in the event name ⬆</span>
        </transition>
      </v-layout>
      <v-checkbox v-model="computedTwentyOnePlus" label="21+"></v-checkbox>
      <v-checkbox v-model="computedActive" label="Active"></v-checkbox>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveEvent"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import moment     from 'moment'
import rules      from '../../lib/rules'
import DatePicker from '../DatePicker.vue'
import TimePicker from '../TimePicker.vue'
import ImageUpload from './ImageUpload.vue'
export default {
  name: 'OperatorsEventNew',
  components: {
    DatePicker,
    TimePicker,
    ImageUpload
  },
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.initializeEvent()
    this.setEvent({event: {establishmentId: this.$route.params.slug}})
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishment', ['establishment']),
    ...Vuex.mapGetters('Operators/EventNew', ['event']),
    ...rules.Event,
    computedName: {
      get() { return this.event.name },
      set(v) { this.setEvent({event: {name: v}}) }
    },
    computedImageFileNameData: {
      get() { return this.event.imageFileNameData },
      set(v) { this.setEvent({event: {imageFileNameData: v}}) }
    },
    computedDescription: {
      get() { return this.event.description },
      set(v) { this.setEvent({event: {description: v}}) }
    },
    computedAtDate: {
      get() { return this.event.at },
      set(v) {
        const at = moment(v),
              atEnd = moment(this.event.atEnd)
        this.setEvent({event: {
          at: v,
          atEnd: at.hours(atEnd.hours()).minutes(atEnd.minutes()).toDate()
        }})
      }
    },
    computedAtTime: {
      get() { return this.event.at },
      set(v) {
        const at = moment(v),
              atEnd = moment(this.event.atEnd)
        this.setEvent({event: {
          at: v,
          atEnd: atEnd.hours(at.hours()).minutes(at.minutes()).add(4, 'hours').toDate()
        }})
      }
    },
    computedAtEndDate: {
      get() { return this.event.atEnd },
      set(v) { this.setEvent({event: {atEnd: v}}) }
    },
    computedAtEndTime: {
      get() { return this.event.atEnd },
      set(v) { this.setEvent({event: {atEnd: v}}) }
    },
    computedShowAt: {
      get() { return !!this.event.showAt },
      set(v) {
        this.setEvent({event: {showAt: v ? moment().add(10, 'days').toDate() : null}})
        this.setEvent({event: {showAtEnd: null}})
      }
    },
    computedShowAtDate: {
      get() { return this.event.showAt },
      set(v) { this.setEvent({event: {showAt: v}}) }
    },
    computedShowAtEnd: {
      get() { return !!this.event.showAtEnd },
      set(v) { this.setEvent({event: {showAtEnd: v ? moment(this.event.showAt).add(5, 'days').toDate() : null}}) }
    },
    computedShowAtEndDate: {
      get() { return this.event.showAtEnd },
      set(v) { this.setEvent({event: {showAtEnd: v}}) }
    },
    computedLiveMusic: {
      get() { return this.event.liveMusic },
      set(v) { this.setEvent({event: {liveMusic: v}}) }
    },
    computedRepeatDaily: {
      get() { return this.event.repeatDaily },
      set(v) { this.setEvent({event: {repeatDaily: v, ...(v ? {repeatWeekly: false} : {})}}) }
    },
    computedRepeatWeekly: {
      get() { return this.event.repeatWeekly },
      set(v) { this.setEvent({event: {repeatWeekly: v, ...(v ? {repeatDaily: false} : {})}}) }
    },
    computedTwentyOnePlus: {
      get() { return this.event.twentyOnePlus },
      set(v) { this.setEvent({event: {twentyOnePlus: v}}) }
    },
    computedActive: {
      get() { return this.event.active },
      set(v) { this.setEvent({event: {active: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Operators/EventNew', ['initializeEvent', 'setEvent', 'attemptSaveEvent']),
    async saveEvent() {
      this.processing = true
      if (await this.attemptSaveEvent()) {
        this.$router.push({name: 'operators-events', params: {slug: this.$route.params.slug}})
      }
      this.processing = false
    }
  }
}
</script>
