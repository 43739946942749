<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Users'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-user-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <v-expansion-panel v-model="filtering">
      <v-expansion-panel-content>
        <div slot="header">Filter</div>
        <v-card>
          <v-card-text>
            <v-text-field label="First Name" v-model="computedFilterFirstName" clearable></v-text-field>
            <v-text-field label="Last Name" v-model="computedFilterLastName" clearable></v-text-field>
            <v-text-field label="Email" v-model="computedFilterEmail" clearable></v-text-field>
            <v-checkbox label="Operator" v-model="computedFilterOperator" clearable></v-checkbox>
            <v-btn @click.prevent="loadUsers">Apply</v-btn>
          </v-card-text>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-list two-line>
      <v-list-tile
          v-for="user in users"
          :key="user.attributes.id"
          :to="{name: 'admins-user', params: {id: user.attributes.id}}">
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">{{displayName({user})}}</v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip
                color="deep-red"
                text-color="white"
                small
                v-if="user.attributes.admin"
                >
              Admin
            </v-chip>
            <v-chip
                color="light-gray"
                small
                v-if="user.attributes.operatorCount"
                >
              Operator of: {{user.attributes.operatorCount}}
            </v-chip>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
import _    from 'lodash'
export default {
  name: 'AdminsUsers',
  data() {
    return {
      filtering: [false]
    }
  },
  created() {
    this.loadUsers()
  },
  computed: {
    ...Vuex.mapGetters('Admins/Users', ['users', 'filter']),
    computedFilterFirstName: {
      get() { return this.filter.firstName },
      set(v) { this.setFilter({filter: {firstName: v}}) }
    },
    computedFilterLastName: {
      get() { return this.filter.lastName },
      set(v) { this.setFilter({filter: {lastName: v}}) }
    },
    computedFilterEmail: {
      get() { return this.filter.email },
      set(v) { this.setFilter({filter: {email: v}}) }
    },
    computedFilterOperator: {
      get() { return this.filter.operator },
      set(v) { this.setFilter({filter: {operator: v ? v : undefined}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/Users', ['loadUsers', 'setFilter']),
    displayName({user}) {
      return _.compact([user.attributes.firstName, user.attributes.lastName, `(${user.attributes.email})`]).join(' ')
    }
  },
}
</script>
<style scoped lang="sass">
</style>
