<template>
  <router-link :to="{name: 'register'}">
    <v-layout
        class="px-3 deals"
        column>
      <div
          v-for="deal in shownDeals"
          :key="deal.attributes.id"
          class="mb-2"
          :to="{name: 'deal', params: {id: deal.attributes.id}}">
        <v-card
            :tile="false">
          <v-avatar
              size="5rem"
              style="margin-bottom: -2.5rem; z-index: 10"
              class="ml-2"
              color="white">
            <v-avatar size="4rem">
              <v-img v-if="deal.relationships.establishment.attributes.logoImageFileNameUrl" :src="deal.relationships.establishment.attributes.logoImageFileNameUrl"></v-img>
            </v-avatar>
          </v-avatar>
          <v-img :src="deal.relationships.establishment.attributes.headerImageFileNameUrl || require('../../assets/drinking.jpg')" height="10rem">
            <v-img :src="require('../../assets/shadow-upward.png')" contain height="10rem" class="shading-bottom">
              <v-layout column fill-height justify-end class="pa-2 white--text">
                <v-flex shrink class="headline">
                  {{deal.attributes.name}}
                </v-flex>
                <v-flex shrink class="subheader">
                  {{deal.relationships.establishment.attributes.name}}
                </v-flex>
              </v-layout>
            </v-img>
          </v-img>
        </v-card>
      </div>
    </v-layout>
    <v-layout class="non-member-overlay white--text" column align-center justify-center>
      <div class="headline font-weight-bold pt-6 pb-1">and many more!</div>
      <v-btn :to="{name: 'register'}" dark round color="deep-red px-5">Become a VIP</v-btn>
      <router-link :to="{name: 'login'}" class="white--text mt-2">Already a VIP?</router-link>
    </v-layout>
  </router-link>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'VipDealsNonMember',
  computed: {
    ...Vuex.mapGetters('Deals', ['shownDeals', 'type']),
  }
}
</script>
<style scoped lang="sass">
.deals
  min-height: 45rem
.non-member-overlay
  position: relative
  margin-top: -40rem
  height: 40rem
  background-image: linear-gradient(transparent, black)
  z-index: 11
</style>
