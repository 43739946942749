<template>
  <page :header="{enabled: false}">
    <v-img :src="require('../assets/sip.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
      <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top">
        <v-layout column fill-height justify-center align-center class="pa-2 white--text">
        </v-layout>
      </v-img>
    </v-img>
    <v-btn
        fab
        :to="{name: 'menu'}"
        color="white"
        class="menu-button grey--text elevation-3 ml-3 mt-3">
      <v-icon class="mr-1 ml-2">icn icn-menu-gry</v-icon>
    </v-btn>
    <v-layout column class="mx-3 mt-3">
      <div class="title mb-2">Get your SIP Passport Today!</div>
      <p>
        Join us and sip your way through Temecula Valley Wine Country!
      </p>
      <div class="sub-title mb-2">Card Information</div>
      <div id="card-wrapper" class="py-2 px-2">
        <div ref="card"></div>
      </div>
      <div
          v-if="cardErrorMessage"
          class="red--text"
          >
        {{cardErrorMessage}}
      </div>
      <div class="text-xs-center mt-3">
        <v-btn
            color="deep-red"
            class="px-3"
            :dark="valid && cardComplete && !processing"
            round
            :loading="processing"
            :disabled="!valid || !cardComplete || processing"
            @click="pay"
            >
          Complete Purchase
        </v-btn>
      </div>
    </v-layout>
  </page>
</template>
<script>
import Vuex   from 'vuex'
import stripe from '@/lib/stripe'
export default {
  name: 'Partners',
  components: {
  },
  data() {
    return {
      processing: false,
      card: null,
      cardComplete: false,
      cardErrorMessage: null,
      valid: true,
    }
  },
  async created() {
  },
  mounted() {
    const cardStyle = {
      base: {
        color: '#474747',
        fontFamily: 'Montserrat, sans-serif'
      }
    }
    this.card = stripe().elements().create('card', {style: cardStyle})
    this.card.mount(this.$refs.card)
    this.card.on('ready', () => this.card.focus())
    this.card.on('change', this.paymentDetailsChanged)
  },
  computed: {
  },
  methods: {
    paymentDetailsChanged(e) {
      this.cardComplete = e.complete
      this.cardErrorMessage = e.error ? e.error.message : null
    },
    async pay() {
      this.processing = true
      const result = await stripe().createToken(this.card)
      if (result.error) {
        this.cardErrorMessage = result.error.message
      } else {
        console.log(`PURCHASE SUCCESSFUL ${result.token.id}`)
      }
      this.processing = false
    }
  }
}
</script>
<style scoped lang="sass">
.menu-button
  position: absolute !important
  left: 0rem
  top: env(safe-area-inset-top)
  height: 3.2rem
  width: 3.2rem
  z-index: 10
#card-wrapper
  border: 1px solid black
  border-radius: 2rem
</style>
