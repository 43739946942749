'use strict'

import moment from 'moment'

import api    from '../../../api'

export default {
  namespaced: true,
  state: {
    payment: {},
    errorMessage: null
  },
  getters: {
    payment(state) { return state.payment },
    errorMessage(state) { return state.errorMessage }
  },
  mutations: {
    initializePayment(state) {
      state.payment = {membershipStart: moment()}
      state.errorMessage = null
    },
    setPayment(state, {payment}) {
      state.payment = {...state.payment, ...payment}
    },
    setErrorMessage(state, {errorMessage}) {
      state.errorMessage = errorMessage
    }
  },
  actions: {
    initializePayment({commit}) {
      commit('initializePayment')
    },
    setPayment({commit}, {payment}) {
      commit('setPayment', {payment})
    },
    setErrorMessage({commit}, {errorMessage}) {
      commit('setErrorMessage', {errorMessage})
    },
    async attemptPay({dispatch, getters}) {
      try {
        await api.Users.Payments.create({params: {payment: getters.payment}})
        dispatch('Login/initializeCurrentUser', {}, {root: true})
        dispatch('initializePayment')
        return true
      } catch(e) {
        dispatch('setErrorMessage', {errorMessage: e.response.data.errors[0].title})
        return false
      }
    }
  }
}
