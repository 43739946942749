<template>
  <page :header="{enabled: true, back: {name: 'admins-establishment-operators', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'New Operator'}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-autocomplete
          v-model="computedUserId"
          :items="users"
          :item-text="userDisplayName"
          item-value="attributes.id"
          :search-input.sync="search"
          hide-no-data
          label="Person"
          placeholder="Start typing to search"
          prepend-icon="fas fa-user"
          :rules="UserIdRules">
        <template v-slot:selection="{item}">
          <div class="subheading ellipsis-overflow">{{item.attributes.firstName}} {{item.attributes.lastName}} ({{item.attributes.email}})</div>
        </template>
        <template v-slot:item="{item}">
          <div class="subheading ellipsis-overflow">{{item.attributes.firstName}} {{item.attributes.lastName}} ({{item.attributes.email}})</div>
        </template>
      </v-autocomplete>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveOperator"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import _          from 'lodash'
import rules      from '../../lib/rules'
export default {
  name: 'AdminsEstablishmentOperatorsNew',
  data() {
    return {
      valid: true,
      search: '',
      processing: false
    }
  },
  async created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.initializeOperator()
    this.setOperator({operator: {establishmentId: this.$route.params.slug}})
  },
  computed: {
    ...Vuex.mapGetters('Admins/Establishment', ['establishment']),
    ...Vuex.mapGetters('Admins/EstablishmentOperatorsNew', ['operator', 'users']),
    ...rules.EstablishmentOperator,
    computedUserId: {
      get() { return this.operator.userId },
      set(v) { this.setOperator({operator: {userId: v}}) }
    }
  },
  watch: {
    search(v) {
      this.doSearch({search: v})
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Admins/EstablishmentOperatorsNew', ['initializeOperator', 'searchForUsers', 'setOperator', 'attemptSaveOperator']),
    doSearch: _.debounce(function({search}) {
      this.searchForUsers({search})
    }, 200),
    async saveOperator() {
      this.processing = true
      if (await this.attemptSaveOperator()) {
        this.$router.push({name: 'admins-establishment-operators', params: {slug: this.$route.params.slug}})
      }
      this.processing = false
    },
    userDisplayName(item) {
      return `${item.attributes.firstName} ${item.attributes.lastName} (${item.attributes.email})`
    }
  }
}
</script>
