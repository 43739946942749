<template>
  <page :header="{enabled: true, back: {name: 'admins-users', params: {id: $route.params.id}}, center: {enabled: true, text: user ? `New user` : ''}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-text-field v-model="computedFirstName" :rules="FirstNameRules" label="First Name"></v-text-field>
      <v-text-field v-model="computedLastName" :rules="LastNameRules" label="Last Name"></v-text-field>
      <v-text-field v-model="computedEmail" :rules="EmailRules" label="Email"></v-text-field>
      <v-text-field
          v-model="computedPassword"
          prepend-icon="refresh"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          @click:prepend="randomizePassword"
          @click:append="showPassword = !showPassword"
          label="Password"
          >
      </v-text-field>
      <v-checkbox v-model="computedReceiveEmails" label="Receive Emails"></v-checkbox>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveUser"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import rules       from '../../lib/rules'
export default {
  name: 'AdminsUserNew',
  data() {
    return {
      showPassword: true,
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.initializeUser()
  },
  computed: {
    ...Vuex.mapGetters('Admins/UserNew', ['user']),
    ...rules.User,
    computedFirstName: {
      get() { return this.user.firstName },
      set(v) { this.setUser({user: {firstName: v}}) }
    },
    computedLastName: {
      get() { return this.user.lastName },
      set(v) { this.setUser({user: {lastName: v}}) }
    },
    computedEmail: {
      get() { return this.user.email },
      set(v) { this.setUser({user: {email: v}}) }
    },
    computedPassword: {
      get() { return this.user.password },
      set(v) { this.setUser({user: {password: v}}) }
    },
    computedReceiveEmails: {
      get() { return this.user.receiveEmails },
      set(v) { this.setUser({user: {receiveEmails: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/UserNew', ['initializeUser', 'setUser', 'attemptSaveUser', 'randomizePassword']),
    async saveUser() {
      this.processing = true
      if (await this.attemptSaveUser()) {
        this.$router.push({name: 'admins-users'})
      }
      this.processing = false
    }
  }
}
</script>
