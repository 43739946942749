<template>
  <v-dialog
      v-model="computedShow"
      >
    <v-layout
        class="white px-3 py-2"
        column
        >
      <h2 class="text-xs-center">
        Join the Temecula Life Insider List for
        giveaways, news, events and promos.
      </h2>
      <v-text-field
          v-model="email"
          label="Email"
          >
      </v-text-field>
      <v-layout
          justify-end
          >
        <v-btn
            flat
            @click.prevent="dontAskFor21Days"
            color="grey lighten-1"
            >
          No thanks
        </v-btn>
        <v-btn
            round
            @click.prevent="createInterestedUser"
            color="deep-red"
            class="white--text"
            :loading="loading"
            ref="join"
            >
          Join the List
        </v-btn>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<style scoped lang="sass">
::v-deep .v-dialog
  overflow-y: visible !important
</style>

<script>
import Vuex from 'vuex'
import moment from 'moment'
import api from '../lib/api'
import { confetti } from 'dom-confetti'

export default {
  name: 'CaptureUser',
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  computed: {
    ...Vuex.mapGetters('CaptureUser', ['show']),
    computedShow: {
      get() { return this.show },
      set() {
        this.dontAskFor21Days()
      }
    },
  },
  methods: {
    ...Vuex.mapActions('CaptureUser', ['setShow']),
    ...Vuex.mapActions('Acknowledgements', ['addAcknowledgement']),
    dontAskFor21Days() {
      this.addAcknowledgement({acknowledgement: {name: 'capture-user', expires: moment().add(21, 'days').format()}})
      this.setShow({show: false})
    },
    async createInterestedUser() {
      if (this.email && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email)) {
        this.loading = true
        await Promise.all([
          confetti(
            this.$refs.join.$el,
            {startVelocity: 30, duration: 1500}
          ),
          api.InterestedUser.create({params: {interestedUser: {email: this.email}}})
        ])
        this.addAcknowledgement({acknowledgement: {name: 'capture-user'}})
        this.loading = false
        this.setShow({show: false})
      }
    }
  }
}
</script>
