'use strict'

import _ from 'lodash'

import Photos from './Winery/Photos'

import api from '../../api'

export default {
  namespaced: true,
  modules: {
    Photos
  },
  state: {
    winery: null,
    back: null
  },
  getters: {
    winery(state) { return state.winery },
    back(state) { return state.back }
  },
  mutations: {
    setWinery(state, winery) {
      state.winery = winery
    },
    setBack(state, {back}) {
      state.back = back
    }
  },
  actions: {
    async loadWinery({commit, rootGetters}, {slug}) {
      commit('setWinery', null)
      let winery = null
      // For quick loading get the winery info from other states
      winery = winery || _.find(rootGetters['Wineries/wineries'], {attributes: {slug}}, null)
      winery = winery || _.find(rootGetters['Map/establishments'], {attributes: {slug}}, null)
      if (winery) {
        commit('setWinery', winery)
      }
      // Get winery's specific info
      api.Establishments.show(slug)
          .then(({data: {data}}) => commit('setWinery', data))
    },
    setBack({commit}, {back}) {
      commit('setBack', {back})
    }
  }
}
