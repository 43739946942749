'use strict'

import api      from '../../../api'
import Payments from './Users/Payments'

export default {
  namespaced: true,
  modules: {
    Payments
  },
  state: {
    users: [],
    filter: {}
  },
  getters: {
    users(state) { return state.users },
    filter(state) { return state.filter}
  },
  mutations: {
    setUsers(state, {users}) {
      state.users = [...users]
    },
    setFilter(state, {filter}) {
      state.filter = {...state.filter, ...filter}
    }
  },
  actions: {
    async loadUsers({commit, getters}) {
      const users = (await api.Admins.Users.index({params: {filter: getters.filter}})).data.data
      commit('setUsers', {users})
    },
    setFilter({commit}, {filter}) {
      commit('setFilter', {filter})
    }
  }
}
