<template>
  <lean :left-to="{name: 'guide'}">
    <page
        :header="{back: {name: 'guide'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}"
        :footer="{enabled: false}">
      <v-layout
          column
          align-center
          v-if="spotlight"
          class="pb-4"
          >
        <v-img :src="spotlight.attributes.headerImageFileNameUrl || require('../assets/drinking.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
          <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top"></v-img>
        </v-img>
        <v-layout column align-start class="pa-3 mb-2" style="width: 100%">
          <div class="title">{{spotlight.attributes.name}}</div>
          <div v-if="spotlight.attributes.datesCustom" class="subheading pt-1">{{spotlight.attributes.datesCustom}}</div>
          <div v-else class="subheading pt-1">{{spotlight | spotlightTimeFormatted}}</div>
          <sanitized-html
              :html="spotlight.attributes.description"
              link-event-category="Event"
              link-event-action="click_external"
              :link-event-label="({url}) => `Spotlight - ${spotlight.attributes.name} (${url})`"
              :link-event-value="`${spotlight.attributes.id}`"
              >
          </sanitized-html>
        </v-layout>
        <v-btn
            round
            color="deep-red"
            class="white--text px-4"
            v-if="spotlight.attributes.url"
            @click="moreInformation">
          More Information
        </v-btn>
      </v-layout>
    </page>
  </lean>
</template>
<script>
import api  from '../lib/api'
import Vuex from 'vuex'
export default {
  name: 'Spotlight',
  created() {
    this.loadSpotlight({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Spotlight', ['spotlight'])
  },
  methods: {
    ...Vuex.mapActions('Spotlight', ['loadSpotlight']),
    moreInformation() {
      window.FirebasePlugin?.logEvent("click_external", {
        content_type: 'spotlight',
        event_label: this.spotlight.attributes.name,
        event_id: this.spotlight.attributes.id
      });
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/spotlight/click_external/${this.spotlight.attributes.id}`}}})
      this.$gtag.event(
        `Spotlight - click_external - ${this.spotlight.attributes.id} - ${this.spotlight.attributes.name} `,
        {
          eventCategory: 'Spotlight',
          eventAction: 'click_external',
          eventLabel: this.spotlight.attributes.name,
          eventValue: this.spotlight.attributes.id
        }
      );
      window.open(this.spotlight.attributes.url, '_system')
    }
  }
}
</script>
<style scoped lang="sass">
</style>
