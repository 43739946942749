<template>
  <page :header="{enabled: true, back: {name: 'experiences'}, center: {enabled: true, text: 'Payment Info'}}" :footer="{enabled: false}">
    <v-form class="pa-3" v-model="valid">
      <v-alert
          :value="true"
          v-if="errorMessage"
          type="error"
          class="py-2 mb-3"
          >
        {{errorMessage}}
      </v-alert>
      <div class="title mb-2">Card Information</div>
      <div id="card-wrapper" class="py-2 px-2 mb-3">
        <div ref="card"></div>
      </div>
      <div class="title mb-0">Choose your VIP Lounge package</div>
      <v-radio-group v-model="computedType" :rules="TypeRules" class="mt-0 mb-0">
        <div class="font-weight-bold subheading">Local Residents</div>
        <v-radio label="$5.99 monthly membership" value="Monthly $5.99"></v-radio>
        <v-radio label="$59.90 yearly (2 months free)" value="Yearly $59.90"></v-radio>
        <div class="font-weight-bold subheading">Out-of-towners</div>
        <v-radio label="$24.99 4-day pass" value="Once $24.99"></v-radio>
      </v-radio-group>
      <div v-if="computedType === 'once-2499'">
        <date-picker
            label="Arrival Date"
            display-format="YYYY-MM-DD"
            :value="computedMembershipStart"
            @change="computedMembershipStart = $event"
            >
        </date-picker>
        <date-picker
            label="Departure Date"
            display-format="YYYY-MM-DD"
            :value="computedMembershipEnd"
            :readonly="true"
            >
        </date-picker>
      </div>
      <div class="text-xs-center">
        <v-btn
            color="deep-red"
            class="px-3"
            :dark="valid && !processing"
            round
            :disabled="!valid || processing"
            @click="pay"
            >
          Complete Purchase
        </v-btn>
      </div>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import moment      from 'moment'
import stripe      from '@/lib/stripe'
import rules       from '@/lib/rules'
import DatePicker  from '../DatePicker.vue'
export default {
  name: 'UsersRegisterPay',
  components: {
    DatePicker,
  },
  data() {
    return {
      valid: true,
      processing: false,
      card: null
    }
  },
  created() {
    this.initializePayment()
  },
  mounted() {
    const cardStyle = {
      base: {
        color: '#474747',
        fontFamily: 'Montserrat, sans-serif'
      }
    }
    this.card = stripe().elements().create('card', {style: cardStyle})
    this.card.mount(this.$refs.card)
  },
  computed: {
    ...Vuex.mapGetters('Users/Payment', ['payment', 'errorMessage']),
    ...rules.Payment,
    computedType: {
      get() { return this.payment.type },
      set(v) { this.setPayment({payment: {type: v}}) }
    },
    computedMembershipStart: {
      get() { return this.payment.membershipStart },
      set(v) { this.setPayment({payment: {membershipStart: v}}) }
    },
    computedMembershipEnd() {
      return moment(this.computedMembershipStart).add(4, 'days')
    }
  },
  methods: {
    ...Vuex.mapActions('Users/Payment', ['initializePayment', 'setPayment', 'attemptPay', 'setErrorMessage']),
    async pay() {
      this.processing = true
      const result = await stripe().createToken(this.card)
      if (result.error) {
        this.setErrorMessage({errorMessage: result.error.message})
      } else {
        this.setPayment({payment: {token: result.token.id}})
        if (await this.attemptPay()) {
          this.$router.replace({name: 'register-pay-welcome'})
        }
      }
      this.processing = false
    }
  }
}
</script>
<style scoped lang="sass">
#card-wrapper
  border: 1px solid black
  border-radius: 2rem
</style>
