'use strict'

const requireModule = require.context('.', true, /\.vue$/)

const components = {};
requireModule.keys().forEach(fileName => {
  const name = fileName.replace(/^\.\/(.+).vue$/, '$1')

  components[name] = requireModule(fileName).default;
});
export default components;
