'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    wines: []
  },
  getters: {
    wines(state) { return state.wines }
  },
  mutations: {
    setWines(state, {wines}) {
      state.wines = [...wines]
    }
  },
  actions: {
    async loadWines({commit}, {slug}) {
      const wines = (await api.Operators.Wines.index({params: {filter: {establishmentId: slug}}})).data.data
      commit('setWines', {wines})
    },
    async resortActiveWines({dispatch}, {wines}) {
      if (wines.length) {
        for (let index in wines) {
          await api.Operators.Wines.update(wines[index].attributes.id, {params: {wine: {sortOrder: index}}})
        }
        await dispatch('loadWines', {slug: wines[0].attributes.establishmentId})
      }
    }
  }
}
