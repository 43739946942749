<template>
  <v-dialog v-model="modal">
    <v-text-field :label="label" :disabled="disabled" slot="activator" :value="twelveHourTime" readonly></v-text-field>
    <v-time-picker v-if="modal" v-model="time" :value="value" full-width>
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn flat color="primary" @click="ok">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'AdminsHoursTimePicker',
  props: {
    disabled: Boolean,
    label: String,
    value: String
  },
  data() {
    return {
      modal: false,
      time: null
    }
  },
  created() {
    this.time = this.value
  },
  computed: {
    twelveHourTime() {
      return this.time ? moment(moment().format('YYYY-MM-DD ') + this.time, 'YYYY-MM-DD HH:mm').format('h:mma') : null
    }
  },
  methods: {
    ok() {
      this.$emit('change', this.time)
      this.modal = false
    }
  },
  watch: {
    value(v) {
      this.time = v
    }
  }
}
</script>
