<template>
  <div
      v-touch:start="startedMovingHandler"
      v-touch:end="endedMovingHandler"
      v-touch:moving="movingHandler"
      :style="{'--lean-amount': leanAmount}"
      class="leaner"
      :class="{leaning}"
      >
    <slot></slot>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'Lean',
  props: {
    leftTo: null,
    rightTo: null
  },
  data() {
    return {
      leanThreshold: {x: 20, y: 5},
      originOffset: {x: null, y: null},
      movingOffset: {x: 0, y: 0},
      leanStarted: false,
      leanFailed: false,
      leanSucceeded: false
    }
  },
  computed: {
    leaning() {
      return this.leanStarted && !this.leanFailed && this.leanSucceeded
    },
    leanAmount() {
      let amount = 0

      if (this.leaning) {
        amount = this.movingOffset.x > 0 ? '20px' : '-20px'
      }

      return amount
    }
  },
  methods: {
    startedMovingHandler(e) {
      this.leanStarted = true
      this.leanFailed = false
      this.leanSucceeded = false
      this.originOffset = {
        x: _.get(e, ['touches', '0', 'screenX']) || _.get(e, 'offsetX', 0),
        y: _.get(e, ['touches', '0', 'screenY']) || _.get(e, 'offsetY', 0),
      }
    },
    // This code is executed in this order with caching so that nextTick can handle the event
    endedMovingHandler() {
      const cachedLeaning = this.leaning
      const eventName = this.movingOffset.x > 0 ? 'swipe-left' : 'swipe-right'
      this.leanStarted = false
      if (cachedLeaning) {
        this.$nextTick(() => {
          if (eventName === 'swipe-left' && this.leftTo) {
            this.$router.push(this.leftTo)
          } else if (eventName === 'swipe-right' && this.rightTo) {
            this.$router.push(this.rightTo)
          }
          this.$emit(eventName)
        })
      } else {
        this.movingOffset = {x: 0, y: 0}
      }
    },
    movingHandler(e) {
      // Only handle move if the lean has started and not failed
      if (this.leanStarted && !this.leanFailed) {
        this.movingOffset = {
          x: (_.get(e, ['touches','0', 'screenX'], 0) || _.get(e, 'offsetX', 0)) - this.originOffset.x,
          y: (_.get(e, ['touches','0', 'screenY'], 0) || _.get(e, 'offsetY', 0)) - this.originOffset.y,
        }
        // If lean is outside threshold, then mark as succeeded
        if (Math.abs(this.movingOffset.x) > this.leanThreshold.x) {
          this.leanSucceeded = true
        // Otherwise mark as not succeeded
        } else {
          this.leanSucceeded = false
        }
      }
      if (!this.leanSucceeded && (Math.abs(this.movingOffset.y) > this.leanThreshold.y)) {
        this.leanFailed = true
      }
    }
  }
}
</script>

<style scoped lang="sass">
.leaner
  transition: transform 0.3s
  &.leaning
    transform: translateX(var(--lean-amount))
</style>
