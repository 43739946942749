'use strict'

import moment from 'moment'
import _      from 'lodash'

export default {
  dbDateFormatted({date}) {
    return moment(date).format('YYYY-MM-DD')
  },
  dbDateTimeFormatted({date}) {
    return moment(date).format('YYYY-MM-DD h:m a')
  },
  startEndTimeFormatted({start, end, options: {onlyTime} = {onlyTime: false}}) {
    const isStart                    = !!start,
          isEnd                      = !!end,
          startEndEssentiallySameDay = isStart && isEnd && moment(start).endOf('day').add(5, 'hours').isAfter(end),
          startEndSameMeridiem       = isStart && isEnd && moment(start).format('a') === moment(end).format('a')
    let out = ''
    // Just start
    if (isStart && !isEnd) {
      // Only time: 8:00am
      if (onlyTime) {
        out = moment(start).format('h:mma')
      // Not only time: Fri, July 16 8:00am
      } else {
        out = moment(start).format('ddd, MMMM D h:mma')
      }
    // Start and end essentially same day
    } else if (isStart && isEnd && startEndEssentiallySameDay) {
      let time = ''
      // Start and end same meridiem: 8:00-11:00am
      if (startEndSameMeridiem) {
        time = moment(start).format('h:mm-') + moment(end).format('h:mma')
      // Start and end not same meridiem: 8:00am-5:00pm
      } else {
        time = moment(start).format('h:mma-') + moment(end).format('h:mma')
      }
      // Only time: 8:00am-5:00pm
      if (onlyTime) {
        out = time
      // Not only time: Fri, July 16 8:00am-5:00pm
      } else {
        out = `${moment(start).format('ddd, MMMM D')} ${time}`
      }
    // End not essentially same day: Fri, July 16 8:00am - Sunday, July 18 5:00pm
    } else if (isStart && isEnd && !startEndEssentiallySameDay) {
      out = `${moment(start).format('ddd, MMMM D h:mma')} - ${moment(end).format('ddd, MMMM D h:mma')}`
    }
    return out
  },
  spotlightTimeFormatted({spotlight, options: {onlyTime} = {onlyTime: false}}) {
    return this.startEndTimeFormatted({start: spotlight.attributes.at, end: spotlight.attributes.atEnd, options: {onlyTime}})
  },
  eventTimeFormatted({event, options: {onlyTime} = {onlyTime: false}}) {
    return this.startEndTimeFormatted({start: event.attributes.adjustedAt, end: event.attributes.adjustedAtEnd, options: {onlyTime}})
  },
  hoursInfo({establishment}) {
    return Array.from({length: 7}, (v,i) => i).map(offset => {
      const day = moment().startOf('week').add(offset + 1, 'days')
      const now = moment()
      let info = {
        name:   day.format('ddd').toUpperCase(),
        today:  now.format('dddd') === day.format('dddd'),
        status: now.format('dddd') === day.format('dddd') ? 'hours-today' : null
      }
      const startTimeRaw = _.get(establishment, `attributes.hours[${day.format('dddd')}].startTime`, null)
      const endTimeRaw   = _.get(establishment, `attributes.hours[${day.format('dddd')}].endTime`, null)
      if (startTimeRaw && endTimeRaw) {
        const startTime = moment(`${day.format('YYYY-MM-DD')} ${startTimeRaw}:00`)
        let endTime     = moment(`${day.format('YYYY-MM-DD')} ${endTimeRaw}:00`)
        // If the end time is before the start time, the implication is it closes the next day
        if (endTime.isBefore(startTime)) endTime = moment(endTime).add(1, 'day')
        // Set hours string
        if (startTime.format('a') === endTime.format('a')) {
          info.hours = `${startTime.format('h:mm')}-${endTime.format('h:mma')}`
        } else {
          info.hours = `${startTime.format('h:mma')}-${endTime.format('h:mma')}`
        }
        // Set status
        if (startTime.isBefore(now) && endTime.isAfter(now)) {
          info.status     = 'hours-open'
          info.statusName = 'OPEN'
        } else if (startTime.isBefore(moment(now).add(1, 'hour')) && endTime.isAfter(now)) {
          info.status     = 'hours-open-soon'
          info.statusName = 'OPEN SOON'
        }
      }
      return info
    })
  },
  establishmentOpen({establishment}) {
    let open = false
    const now = moment()
    const todayHours = _.get(establishment, `attributes.hours.${now.format('dddd')}`, {})
    if (todayHours.startTime && todayHours.endTime) {
      const startTime = moment(`${now.format('YYYY-MM-DD')} ${todayHours.startTime}:00`)
      let   endTime = moment(`${now.format('YYYY-MM-DD')} ${todayHours.endTime}:00`)
      if (endTime.isBefore(startTime)) endTime = moment(endTime).add(1, 'day')
      open = now.isAfter(startTime) && now.isBefore(endTime)
    }
    return open
  },
  establishmentOpenSoon({establishment}) {
    let open = false
    const now = moment()
    const todayHours = _.get(establishment, `attributes.hours.${now.format('dddd')}`, {})
    if (todayHours.startTime && todayHours.endTime) {
      const startTime = moment(`${now.format('YYYY-MM-DD')} ${todayHours.startTime}:00`)
      let   endTime = moment(`${now.format('YYYY-MM-DD')} ${todayHours.endTime}:00`)
      if (endTime.isBefore(startTime)) endTime = moment(endTime).add(1, 'day')
      open = moment(now).add(1, 'hour').isAfter(startTime) && now.isBefore(endTime)
    }
    return open
  },
  downloadCSV({filename = 'download.csv', rows}) {
    const content = `${rows.map(r => r.map(c => [null, undefined].includes(c) ? `""` : `"${c}"`).join(',')).join("\n")}`
    const link = document.createElement("a")
    link.download = filename
    link.href = encodeURI(`data:text/csv;charset=utf-8,${content}`)
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
