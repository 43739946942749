<template>
  <span>
    <span v-for="(token,i) in tokenized" :key="i">
      <span v-if="token.type === 'text'">{{token.value}}</span>
      <span v-else-if="token.type === 'nl'"><br /></span>
      <span v-else-if="token.type === 'url'">
        <outbound-link
            :url="token.value"
            :event-category="linkEventCategory"
            :event-action="linkEventAction"
            :event-label="linkEventLabel"
            :event-value="linkEventValue"
            ></outbound-link>
      </span>
    </span>
  </span>
</template>
<script>
const linkify = require('linkifyjs')
const sanitizeHtml = require('sanitize-html')
export default {
  name: 'SanitizedHtml',
  props: {
    html: String,
    linkUrls: {
      type: Boolean,
      default: true
    },
    linkEventCategory: [String, Function],
    linkEventAction: [String, Function],
    linkEventLabel: [String, Function],
    linkEventValue: [String, Function]
  },
  computed: {
    tokenized() {
      const tokenizedHtml = linkify.tokenize(this.html)
      return tokenizedHtml.map(tokenBlock => {
        let ret = null
        if (tokenBlock.type === 'nl') {
          ret = {type: 'nl'}
        } else if (tokenBlock.type === 'url') {
          ret = {type: 'url', value: tokenBlock.toHref()}
        } else {
          ret = {type: 'text', value: tokenBlock.v.map(({v}) => v).join('')}
        }
        return ret
      })
    }
  }
}
</script>
