'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    event: {}
  },
  getters: {
    event(state) { return state.event }
  },
  mutations: {
    initializeEvent(state) {
      state.event = {}
    },
    setEvent(state, {event}) {
      state.event = {...state.event, ...event}
    }
  },
  actions: {
    async initializeEvent({commit}, {id}) {
      commit('initializeEvent')
      const event = (await api.Operators.Events.show(id)).data.data
      commit('setEvent', {event: event.attributes})
    },
    setEvent({commit}, {event}) {
      commit('setEvent', {event})
    },
    async attemptSaveEvent({getters}) {
      try {
        await api.Operators.Events.update(getters.event.id, {params: {event: getters.event}})
        return true
      } catch(e) {
        return false
      }
    },
    async attemptDestroyEvent({getters}) {
      try {
        await api.Operators.Events.destroy(getters.event.id)
        return true
      } catch(e) {
        return false
      }
    }
  }
}
