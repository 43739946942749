<template>
  <page :header="{enabled: false}" :footer="{enabled: false}">
    <v-layout
        column
        justify-space-around
        class="full pa-3">
      <v-card>
        <v-img
            :src="require('../../assets/vip-lounge.jpg')"
            aspect-ratio="1.5"
            >
        </v-img>
        <v-card-title>
          <div>
            <div class="headline">Welcome to the VIP Lounge</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
          </div>
        </v-card-title>
      </v-card>
      <v-layout justify-center>
        <v-btn
            :to="{name: 'experiences'}"
            class="px-4 my-4 white font-weight-bold"
            outline
            dark
            round
            >
          Browse VIP Deals
        </v-btn>
      </v-layout>
    </v-layout>
  </page>
</template>
<script>
export default {
  name: 'UsersRegister',
}
</script>
<style scoped lang="sass">
.full
  min-height: var(--viewport-height)
  background-color: var(--deep-red)
  background-image: url('../../assets/map-background.svg')
  background-size: cover
  width: 100%
  padding-top: calc(2rem + env(safe-area-inset-top)) !important
</style>
