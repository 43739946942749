'use strict'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    topSpotlights: [],
    bottomSpotlights: [],
  },
  getters: {
    topSpotlights(state) { return state.topSpotlights },
    bottomSpotlights(state) { return state.bottomSpotlights }
  },
  mutations: {
    setTopSpotlights(state, {topSpotlights}) {
      state.topSpotlights = [...topSpotlights]
    },
    setBottomSpotlights(state, {bottomSpotlights}) {
      state.bottomSpotlights = [...bottomSpotlights]
    }
  },
  actions: {
    async loadTopSpotlights({commit}) {
      const topSpotlights = (await api.Spotlight.index({params: {
        orderRandom: true,
        filter: {
          placement: 'Guide Top'
        },
        page: {number: 0, size: 10}
      }})).data.data
      commit('setTopSpotlights', {topSpotlights})
    },
    async loadBottomSpotlights({commit}) {
      const bottomSpotlights = (await api.Spotlight.index({params: {
        orderRandom: true,
        filter: {
          placement: 'Guide Bottom'
        },
        page: {number: 0, size: 10}
      }})).data.data
      commit('setBottomSpotlights', {bottomSpotlights})
    }
  }
}
