<template>
  <page :header="{enabled: true, back: {name: 'admins-establishment', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'Operators'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-establishment-operators-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <v-list>
      <v-list-tile v-for="(operator,i) in operators" :key="operator.attributes.userId">
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">
            {{operator.relationships.user.attributes.firstName}}
            {{operator.relationships.user.attributes.lastName}}
          </v-list-tile-title>
          <v-list-tile-sub-title>
            Created: {{operator.attributes.createdAt | dbDateFormatted}}
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-dialog v-model="confirmingDestroy[i]">
            <v-icon slot="activator" color="red">delete_forever</v-icon>
            <v-card>
              <v-card-title class="headline grey lighten-2">Delete?</v-card-title>
              <v-card-actions class="justify-center py-3">
                <v-btn
                    color="error"
                    :disabled="processing"
                    :loading="processing"
                    @click="destroyOperator({operator})">
                  Yes
                </v-btn>
                <v-btn
                    :disabled="processing"
                    :loading="processing"
                    @click="confirmingDestroy[i] = false">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'AdminsEstablishmentOperators',
  data() {
    return {
      confirmingDestroy: {},
      processing: false
    }
  },
  created() {
    this.loadOperators({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Admins/EstablishmentOperators', ['operators'])
  },
  methods: {
    ...Vuex.mapActions('Admins/EstablishmentOperators', ['loadOperators', 'attemptDestroyOperator']),
    async destroyOperator({operator}) {
      this.processing = true
      await this.attemptDestroyOperator({operator})
      this.confirmingDestroy = {}
      this.processing = false
    }
  }
}
</script>
<style scoped lang="sass">
</style>
