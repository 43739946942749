<template>
  <v-layout column class="pa-3 pb-6">
    <h2 class="font-weight-regular">Temecula Life Wine Country Guide</h2>
    <br />
    <p>
      With nearly 50 wineries, the Temecula Valley is fast becoming the newest destination to enjoy amazing wines, delicious food and exciting activities.
    </p>
    <p>
      The Temecula Life Wine Country Guide is your one-stop-shop to discover new wineries, find upcoming events and plan your wine country adventure.
    </p>
    <p>
      With detailed winery information and helpful guides — you’ll find wineries to match your specific taste, style and occasion.
    </p>
    <p class="title">
      Looking to promote your brand or business?
    </p>
    <p>
      Temecula Life offers many options to help market your products and services:
    </p>
    <ul class="mb-3">
      <li>Online Marketing</li>
      <li>Sponsored Instagram Posts and Stories</li>
      <li>Product Photography</li>
      <li>Event Promotion</li>
      <li>and more</li>
    </ul>
    <p>
      Email <a href="mailto:temeculalifeapp@gmail.com">temeculalifeapp@gmail.com</a> for more information.
    </p>
    <div class="font-weight-light light-gray--text">Version: {{appInfo.version}}</div>
  </v-layout>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'MenuAbout',
  computed: {
    ...Vuex.mapGetters('AppInfo', ['appInfo'])
  }
}
</script>
