'use strict'

import store              from './store'

export default async () => {
  document.addEventListener("deviceready", () => {
    if (window.cordova && window.cordova.InAppBrowser && store.getters['AppInfo/mobileApp']) {
      window.open = window.cordova.InAppBrowser.open
    }
  }, false)
}
