<template>
  <page :header="{enabled: true, back: {name: 'admins-reports'}, center: {enabled: true, text: 'App Downloads'}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-menu v-model="startModal" :close-on-content-click="false" right>
        <v-text-field slot="activator" v-model="computedStart" label="Start" prepend-icon="event" readonly></v-text-field>
        <v-date-picker v-model="computedStart" type="date" @input="startModal = false"></v-date-picker>
      </v-menu>
      <v-menu v-model="endModal" :close-on-content-click="false" left>
        <v-text-field slot="activator" v-model="computedEnd" label="End" prepend-icon="event" readonly></v-text-field>
        <v-date-picker v-model="computedEnd" type="date" @input="endModal = false"></v-date-picker>
      </v-menu>
      <v-btn
          color="primary"
          :disabled="processing"
          :loading="processing"
          @click="generateReport"
          class="ml-0">
        Generate
      </v-btn>
    </v-form>
    <div v-if="report">
      <v-card>
        <v-card-title class="font-weight-bold">App Downloads</v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-tile>
            <v-list-tile-content>iOS</v-list-tile-content>
            <v-list-tile-content class="align-end">{{Number(report.data.counts.ios).toLocaleString()}}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Android</v-list-tile-content>
            <v-list-tile-content class="align-end">{{Number(report.data.counts.android).toLocaleString()}}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Total</v-list-tile-content>
            <v-list-tile-content class="align-end">{{Number(report.data.counts.total).toLocaleString()}}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </div>
  </page>
</template>
<script>
import Vuex       from 'vuex'
export default {
  name: 'AdminsReportAppDownloads',
  data() {
    return {
      valid: true,
      startModal: false,
      endModal: false,
      processing: false,
    }
  },
  async created() {
    await this.initialize()
  },
  computed: {
    ...Vuex.mapGetters('Admins/ReportAppDownloads', ['report', 'filters']),
    computedStart: {
      get() { return this.filters.start },
      set(v) { this.setFilters({filters: {start: v}}) }
    },
    computedEnd: {
      get() { return this.filters.end },
      set(v) { this.setFilters({filters: {end: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/ReportAppDownloads', ['initialize', 'setFilters', 'attemptGenerateReport']),
    async generateReport() {
      this.processing = true
      await this.attemptGenerateReport()
      this.processing = false
    }
  }
}
</script>
