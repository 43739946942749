<template>
  <page :header="{enabled: true, back: {name: 'admins-establishment-photos', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'New Photo'}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-layout row align-center>
        <v-sheet
            color="light-gray"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3 handle">
          <v-img
              v-if="photo.imageFileNameData || photo.imageFileNameUrl"
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="photo.imageFileNameData || photo.imageFileNameUrl">
          </v-img>
        </v-sheet>
        <image-upload v-model="computedImageFileNameData"></image-upload>
      </v-layout>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="savePhoto"
          class="mt-3 ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import ImageUpload from '../Operators/ImageUpload.vue'
import rules       from '../../lib/rules'
export default {
  name: 'AdminsEstablishmentPhotosNew',
  components: {
    ImageUpload
  },
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.initializePhoto()
    this.setPhoto({photo: {establishmentId: this.$route.params.slug}})
  },
  computed: {
    ...Vuex.mapGetters('Admins/Establishment', ['establishment']),
    ...Vuex.mapGetters('Admins/EstablishmentPhotosNew', ['photo']),
    ...rules.EstablishmentPhoto,
    computedImageFileNameData: {
      get() { return this.photo.imageFileNameData },
      set(v) { this.setPhoto({photo: {imageFileNameData: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Admins/EstablishmentPhotosNew', ['initializePhoto', 'setPhoto', 'attemptSavePhoto']),
    async savePhoto() {
      this.processing = true
      if (await this.attemptSavePhoto()) {
        this.$router.push({name: 'admins-establishment-photos', params: {slug: this.$route.params.slug}})
      }
      this.processing = false
    },
  }
}
</script>
