'use strict'

import _      from 'lodash'
import moment from 'moment'

import api    from '../../api'

export default {
  namespaced: true,
  state: {
    events: [],
    featuredEvent: null
  },
  getters: {
    events(state) { return state.events },
    featuredEvent(state) { return state.featuredEvent },
    // Returns an array of days with events nested like so:
    // [
    //   {dayString: 'Today', events: [...]},
    //   {dayString: 'Monday', events: [...]}
    // ]
    eventDayGroupingsFunc(state) {
      return (filter = {}) => {
        const grouped = _.filter(state.events, filter).reduce((memo,event) => {
          const startDay = moment(event.attributes.adjustedAt),
                endDay   = event.attributes.atEnd ? moment(event.attributes.atEnd) : null
          let   days     = endDay && moment(startDay).isBefore(endDay) ? endDay.diff(moment(startDay).startOf('day'), 'days') : 0

          // Event can't span more than 3 additional days in event list
          days = days > 3 ? 3 : days

          // Add an entry for each day the event is going on
          for (let i = 0; i <= days; i++) {
            let dayString = moment(startDay).add(i, 'days').format('YYYY-MM-DD')
            memo[dayString] = [...(memo[dayString] || []), event]
          }
          return memo
        }, {})
        const sortedGroupings = _.sortBy(_.toPairs(grouped), '[0]')

        return sortedGroupings.map(([date, events]) => {
          const momentDate = moment(date)
          let dayString = ''
          if (momentDate.isSame(moment(), 'day')) {
            dayString = 'Today'
          } else if (momentDate.isSame(moment().add(1, 'day'), 'day')) {
            dayString = 'Tomorrow'
          } else if (momentDate.isBefore(moment().add(5, 'days'))) {
            dayString = momentDate.format('dddd')
          } else {
            dayString = momentDate.format('MMM Do')
          }

          return {
            dayString,
            events: _.sortBy(events, 'attributes.adjustedAt')
          }
        })
      }
    }
  },
  mutations: {
    setEvents(state, {events}) {
      state.events = [...events]
    },
    setFeaturedEvent(state, {featuredEvent}) {
      state.featuredEvent = featuredEvent
    }
  },
  actions: {
    async loadEvents({commit, getters}) {
      if (!getters.events.length) {
        const events = (await api.Events.index({params: {
          day: {number: 0, size: 14}
        }})).data.data
        commit('setEvents', {events})
      }
    }
  }
}
