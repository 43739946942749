<template>
  <div class="px-3">
    <v-layout column v-if="loggedIn">
      <v-layout column justify-center menu class="mt-4 mb-4">
        <v-btn v-if="currentUser.attributes.operatorCount" :to="{name: 'operators-dashboard'}" color="primary">Operator Dashboard</v-btn>
        <v-btn v-if="currentUser.attributes.admin" :to="{name: 'admins-dashboard'}" color="primary">Admin Dashboard</v-btn>
      </v-layout>
      <v-dialog v-model="confirmingLogout">
        <a href="#" slot="activator" class="grey--text">Logout</a>
        <v-card>
          <v-card-title class="headline grey lighten-2">Logout?</v-card-title>
          <v-card-actions class="justify-center py-3">
            <v-btn
                color="error"
                @click="logout">
              Yes
            </v-btn>
            <v-btn
                @click="confirmingLogout = false">
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-form v-else class="pa-3">
      <div>
        <v-alert
            :value="true"
            v-for="loginError in loginErrors"
            :key="loginError.title"
            type="error"
            >
          {{loginError.title}}
        </v-alert>
      </div>
      <v-text-field v-model="computedEmail" type="email" label="Email"></v-text-field>
      <v-text-field v-model="computedPassword" type="password" label="Password" @keypress.enter="login"></v-text-field>
      <v-btn color="primary" class="ml-0" @click="login">Login</v-btn>
    </v-form>
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      confirmingLogout: false
    }
  },
  created() {
    this.initializeCurrentUser()
  },
  computed: {
    ...Vuex.mapGetters('Login', ['currentUser', 'loggedIn', 'loginErrors', 'loginForm']),
    computedEmail: {
      get() { return this.loginForm.email },
      set(v) { this.setLoginForm({loginForm: {email: v}}) }
    },
    computedPassword: {
      get() { return this.loginForm.password },
      set(v) { this.setLoginForm({loginForm: {password: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Login', ['initializeCurrentUser', 'setLoginForm', 'attemptLogin', 'attemptLogout']),
    async logout() {
      if (await this.attemptLogout()) {
        this.confirmingLogout = false
      }
    },
    async login() {
      if (await this.attemptLogin()) {
        if (this.loggedIn && !this.currentUser.attributes.admin && this.currentUser.attributes.operatorCount) {
          this.$router.replace({name: 'operators-dashboard'})
        }
      }
    }
  }
}
</script>
<style scoped lang="sass">
</style>
