<template>
  <v-flex>
    <v-list two-line>
      <v-list-tile
          v-for="deal in deals"
          :key="deal.attributes.id"
          :to="{name: 'admins-deal', params: {id: deal.attributes.id}}">
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray">
          <v-img v-if="deal.relationships.establishment.attributes.headerImageFileNameUrl" :src="deal.relationships.establishment.attributes.headerImageFileNameUrl"></v-img>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">{{deal.attributes.name}}</v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip color="light-gray" text-color="white" small>
              Uses: {{deal.attributes.useCount}}
            </v-chip>
            {{deal.relationships.establishment.attributes.name}}
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </v-flex>
</template>
<script>
export default {
  name: 'AdminsListOfDeals',
  props: {
    deals: Array
  }
}
</script>
