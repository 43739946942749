<template>
  <v-layout justify-center>
    <v-flex shrink class="option-picker">
      <v-btn
          round
          v-for="option in options"
          :key="option"
          :color="option === value ? 'deep-red' : 'white'"
          :class="option === value ? 'white--text' : 'gray--text'"
          class="option-picker-option my-0 mx-2 elevation-0"
          @click.prevent="pick({option})"
          >
        {{option}}
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'Picker',
  props: {
    options: Array,
    value: null
  },
  methods: {
    pick({option}) {
      this.$emit('input', option)
    }
  }
}
</script>
<style scoped lang="sass">
.option-picker
  border: 1px solid var(--gray)
  border-radius: 2rem
  .option-picker-option
    &:first-child
      margin-left: 0 !important
    &:last-child
      margin-right: 0 !important
</style>
