'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    deal: null
  },
  getters: {
    deal(state) { return state.deal }
  },
  mutations: {
    setDeal(state, {deal}) {
      state.deal = deal
    }
  },
  actions: {
    async loadDeal({commit}, {id}) {
      const deal = (await api.Admins.Deals.show(id)).data.data
      commit('setDeal', {deal})
    }
  }
}
