<template>
  <div
      class="page"
      :class="{'pad-safely': padSafely}">
    <div
        class="page-inner"
        :class="{
          'fixed-header': headerConfig.enabled && headerConfig.fixed,
          'overlap-header': headerConfig.enabled && headerConfig.overlap,
          'fixed-footer': footerConfig.enabled && footerConfig.fixed
        }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
export default {
  name: 'Page',
  props: {
    padSafely: {
      type:    Boolean,
      default: false
    },
    header: null,
    footer: null
  },
  created() {
    this.updateHeaderConfig(this.header)
    this.updateFooterConfig(this.footer)
  },
  computed: {
    ...Vuex.mapGetters('HeaderNavigation', {headerConfig: 'config'}),
    ...Vuex.mapGetters('FooterNavigation', {footerConfig: 'config'})
  },
  methods: {
    ...Vuex.mapActions('HeaderNavigation', {updateHeaderConfig: 'updateConfig'}),
    ...Vuex.mapActions('FooterNavigation', {updateFooterConfig: 'updateConfig'})
  },
  watch: {
    header(v) {
      this.updateHeaderConfig(v)
    },
    footer(v) {
      this.updateFooterConfig(v)
    }
  }
}
</script>

<style lang="sass">
.page
  display: inline-block
  height: var(--viewport-height)
  width: var(--viewport-width)
  overflow-y: auto
  -webkit-overflow-scrolling: touch
  &.pad-safely
    padding-top: env(safe-area-inset-top)
    padding-bottom: env(safe-area-inset-bottom)
  .page-inner
    &.fixed-header
      padding-top: var(--header-height)
      &.overlap-header
        padding-top: 0
    &.fixed-footer
      padding-bottom: var(--footer-height)
</style>
