<template>
  <transition name="page-fade-in" appear>
    <div
        class="footer-nav"
        :class="{'footer-nav-fixed': config.enabled && config.floating}"
        v-if="config.enabled"
        >
      <v-layout
          align-start
          justify-space-around
          class="footer-nav-bar px-2"
          >
        <v-flex shrink v-for="button in buttons" :key="button.text" @click="resetMap">
          <router-link :to="button.route">
            <v-btn
                flat
                :round="button.selected"
                class="footer-nav-btn mx-0 px-2"
                :color="button.selected ? 'deep-red' : 'gray'"
                :ripple="false"
                >
              <v-layout column align-center>
                <v-badge right overlap color="red">
                  <template v-slot:badge v-if="button.badge">
                    {{button.badge}}
                  </template>
                  <v-icon class="icn-size-3">
                    icn {{button.icon}}{{button.selected ? '-red' : '-coal'}}
                  </v-icon>
                </v-badge>
                <div
                    :class="button.selected ? 'deep-red--text font-weight-black' : 'coal--text font-weight-bold'"
                    >
                  {{button.text}}
                </div>
              </v-layout>
            </v-btn>
          </router-link>
        </v-flex>
      </v-layout>
    </div>
  </transition>
</template>
<script>
import Vuex from 'vuex'
import _    from 'lodash'
export default {
  name: 'FooterNavigation',
  computed: {
    ...Vuex.mapGetters('FooterNavigation', ['config']),
    ...Vuex.mapGetters('Acknowledgements', ['acknowledgements']),
    ...Vuex.mapGetters('Map', ['mapOpen']),
    todayEventCount() {
      const eventDayGroupings = this.$store.getters['Events/eventDayGroupingsFunc']()
      return _.get(eventDayGroupings, '[0].dayString') === 'Today' ? eventDayGroupings[0].events.length : 0
    },
    buttons() {
      return [
        {
          selected: this.$route.name === 'guide',
          text:     'Guide',
          icon:     'icn-guide',
          route:    {name: 'guide'}
        },
        {
          selected: this.$route.name === 'events',
          text:     'Events',
          icon:     'icn-events',
          route:    {name: 'events'},
          badge:    this.acknowledgements['new-events'] ? null : this.todayEventCount
        },
        {
          selected: ['wineries', 'winery-list'].includes(this.$route.name),
          text:     'Wineries',
          icon:     'icn-wineries',
          route:    {name: 'wineries'}
        },
        {
          selected: this.$route.name === 'partners',
          text:     'Partners',
          icon:     'icn-partners',
          route:    {name: 'partners'}
        },
//        {
//          selected: this.$route.name === 'sip',
//          text:     'SIP',
//          icon:     'icn-partners',
//          route:    {name: 'sip'}
//        },
      ]
    }
  },
  methods: {
    ...Vuex.mapActions('Map', ['toggleMapOpen', 'setFocusedEstablishment']),
    resetMap() {
      this.toggleMapOpen(false)
      this.setFocusedEstablishment({establishment: null})
    }
  }
}
</script>
<style scoped lang="sass">
.footer-nav
  z-index: 20
  .footer-nav-bar
    height: var(--footer-height)
    background-color: white
    border-top: 1px solid var(--light-gray)
  &.footer-nav-fixed
    padding-bottom: var(--footer-height)
    .footer-nav-bar
      position: fixed
      top: calc(var(--viewport-height) - var(--footer-height))
      left: 0
      width: var(--viewport-width)
      z-index: 10
  .footer-nav-btn
    min-width: auto
    height: auto
    ::v-deep .v-badge__badge
      margin-top: 0.6rem
</style>
