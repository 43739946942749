<template>
  <page :pad-safely="true" :header="{enabled: false}">
    <list-of-establishments
        :establishments="wineries"
        :link-query="{back: {name: 'winery-list'}}"
        class="px-2 pt-2 pb-6"></list-of-establishments>
    <v-btn fab class="map-button elevation-3" color="white" :to="{name: 'wineries'}">
      <v-icon color="light-gray">icn icn-wineries-gry</v-icon>
    </v-btn>
  </page>
</template>
<script>
import Vuex                 from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'WineryList',
  components: {
    ListOfEstablishments
  },
  created() {
    this.loadWineries()
  },
  computed: {
    ...Vuex.mapGetters('WineryList', ['wineries'])
  },
  methods: {
    ...Vuex.mapActions('WineryList', ['loadWineries'])
  }
}
</script>
<style scoped lang="sass">
.map-button
  position: fixed !important
  top: calc(var(--viewport-height) - var(--footer-height) - 5.3rem)
  left: calc(var(--viewport-width) - 6.5rem)
</style>
