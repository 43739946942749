<template>
  <v-layout column class="pt-2 pb-5" v-if="!$root.appInfo.pageTransitioning">
    <v-layout shrink row grow class="mx-2">
      <v-flex
          xs12
          class="ma-2"
          v-if="topSpotlights.length"
          >
        <v-carousel
            v-model="carouselItem"
            height="16rem"
            :hide-controls="true"
            :hide-delimiters="topSpotlights.length === 1"
            :cycle="carouselCycle"
            :interval="3500"
            v-touch:swipe.left="carouselNext"
            v-touch:swipe.right="carouselPrev"
            >
          <v-carousel-item
              v-for="spotlight in topSpotlights"
              :key="spotlight.attributes.id"
              >
            <v-card :tile="false" :to="{name: 'spotlight', params: {id: spotlight.attributes.id}}">
              <v-img :src="spotlight.attributes.headerImageFileNameUrl || require('../../assets/drinking.jpg')" height="13rem">
                <v-img :src="require('../../assets/shadow-upward.png')" contain height="13rem" class="shading-bottom">
                  <v-layout column fill-height justify-end class="pa-2 white--text">
                    <v-flex shrink class="headline spotlight-headline">{{spotlight.attributes.name}}</v-flex>
                    <div class="subtitle" v-if="spotlight.attributes.datesCustom || spotlight.attributes.at">
                      <span v-if="spotlight.attributes.datesCustom">{{spotlight.attributes.datesCustom}}</span>
                      <span v-else>{{spotlight | spotlightTimeFormatted}}</span>
                    </div>
                  </v-layout>
                </v-img>
              </v-img>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-flex>
    </v-layout>
    <v-layout column>
      <v-layout v-for="(row, i) in categories" :key="i" row grow align-center class="mx-2">
        <v-flex grow v-for="category in row" :key="category.title">
          <v-card :tile="false" :to="category.route" class="ma-2">
            <v-img :src="category.image" height="10rem">
              <v-img :src="require('../../assets/shadow-upward.png')" contain height="10rem" class="shading-bottom">
                <v-layout column fill-height justify-end class="pa-2 white--text">
                  <v-flex shrink class="headline">{{category.title}}</v-flex>
                </v-layout>
              </v-img>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout shrink row grow class="mx-2" v-for="bottomSpotlight in bottomSpotlights" :key="bottomSpotlight.attributes.id">
      <v-flex
          xs12
          class="ma-2"
          >
        <v-card :tile="false" :to="{name: 'spotlight', params: {id: bottomSpotlight.attributes.id}}">
          <v-img :src="bottomSpotlight.attributes.headerImageFileNameUrl || require('../../assets/drinking.jpg')" height="13rem">
            <v-img :src="require('../../assets/shadow-upward.png')" contain height="13rem" class="shading-bottom">
              <v-layout column fill-height justify-end class="pa-2 white--text">
                <v-flex shrink class="headline">{{bottomSpotlight.attributes.name}}</v-flex>
                <div class="subtitle pt-1" v-if="bottomSpotlight.attributes.at">{{bottomSpotlight | spotlightTimeFormatted}}</div>
              </v-layout>
            </v-img>
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
import Vuex       from 'vuex'
import moment     from 'moment'
export default {
  name: 'MapScrollerGuide',
  data() {
    return {
      carouselItem: 0,
      carouselCycle: true
    }
  },
  created() {
    this.loadTopSpotlights()
    this.loadBottomSpotlights()
  },
  computed: {
    ...Vuex.mapGetters('AppInfo', ['appInfo']),
    ...Vuex.mapGetters('Guide', ['topSpotlights', 'bottomSpotlights']),
    categories() {
      const tiles = {
        'Open Late':    {image: require('../../assets/wineries-open-late.jpg'),       title: 'Open Late',    route: {name: 'wineries-open-late'}},
        'Live Music':   {image: require('../../assets/wineries-live-music.jpg'),      title: 'Live Music',   route: {name: 'wineries-live-music'}},
        'Picnic Area':  {image: require('../../assets/wineries-picnic-area.jpg'),     title: 'Picnic Area',  route: {name: 'wineries-picnic-area'}},
        'Dog Friendly': {image: require('../../assets/wineries-dog-friendly.jpg'),    title: 'Dog Friendly', route: {name: 'wineries-dog-friendly'}},
        'Sustainable':  {image: require('../../assets/wineries-sustainable.jpg'),     title: 'Sustainable',  route: {name: 'wineries-sustainable'}},
        'Beer on Tap':  {image: require('../../assets/wineries-beer-on-tap.jpg'),     title: 'Beer on Tap',  route: {name: 'wineries-beer-on-tap'}},
        'Eats':         {image: require('../../assets/wineries-eat.jpg'),             title: 'Eats',         route: {name: 'wineries-eat'}},
        'Lodging':      {image: require('../../assets/wineries-lodging.jpg'),         title: 'Lodging',      route: {name: 'wineries-lodging'}},
        'Weddings':     {image: require('../../assets/wineries-weddings.jpg'),        title: 'Weddings',     route: {name: 'wineries-weddings'}},
        'Winery Tours': {image: require('../../assets/wineries-winery-tours.jpg'),    title: 'Winery Tours', route: {name: 'wineries-winery-tours'}},
        '21+ Events':   {image: require('../../assets/wineries-twenty-one-plus.jpg'), title: '21+ Events',   route: {name: 'wineries-twenty-one-plus'}}
      }
      let tilesOrdered = []
      // If after 4PM then show tiles in this order
      if (moment().isAfter(moment().startOf('day').add(16, 'hours'))) {
        tilesOrdered = [
          [tiles['Eats'],         tiles['Open Late']],
          [tiles['Dog Friendly'], tiles['Beer on Tap']],
          [tiles['Lodging'],      tiles['Sustainable']],
          [tiles['Weddings'],     tiles['Winery Tours']],
          [tiles['Picnic Area'],  tiles['Live Music']],
          [tiles['21+ Events']],
        ]
      } else {
        tilesOrdered = [
          [tiles['Eats'],         tiles['Open Late']],
          [tiles['Dog Friendly'], tiles['Beer on Tap']],
          [tiles['Lodging'],      tiles['Sustainable']],
          [tiles['Weddings'],     tiles['Winery Tours']],
          [tiles['Picnic Area'],  tiles['Live Music']],
          [tiles['21+ Events']],
        ]
      }
      return tilesOrdered
    }
  },
  methods: {
    ...Vuex.mapActions('Guide', ['loadTopSpotlights', 'loadBottomSpotlights']),
    carouselNext() {
      this.carouselItem = (this.carouselItem + this.topSpotlights.length + 1) % this.topSpotlights.length
      this.carouselCycle = false
    },
    carouselPrev() {
      this.carouselItem = (this.carouselItem + this.topSpotlights.length - 1) % this.topSpotlights.length
      this.carouselCycle = false
    }
  }
}
</script>
<style scoped lang="sass">
.spotlight-headline
  line-height: 1.3rem !important
::v-deep .v-carousel
  border-radius: 0.5rem
  box-shadow: none
  .v-carousel__controls
    background-color: white
    height: 3rem
    border-bottom-left-radius: 0.5rem
    border-bottom-right-radius: 0.5rem
    .v-btn
      color: var(--deep-red)
      &.v-btn--active
        &::before
          background-color: var(--deep-red) !important
      &::before
        background-color: white !important
</style>
