'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    spotlight: null,
  },
  getters: {
    spotlight(state) { return state.spotlight }
  },
  mutations: {
    initializeSpotlight(state) {
      state.spotlight = {
        active:    true,
        placement: 'Guide Top'
      }
    },
    setSpotlight(state, {spotlight}) {
      state.spotlight = {...state.spotlight, ...spotlight}
    }
  },
  actions: {
    initializeSpotlight({commit}) {
      commit('initializeSpotlight')
    },
    setSpotlight({commit}, {spotlight}) {
      commit('setSpotlight', {spotlight})
    },
    async attemptSaveSpotlight({getters}) {
      try {
        await api.Admins.Spotlights.create({params: {spotlight: getters.spotlight}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
