<template>
  <page :header="{enabled: true, back: {name: 'admins-deals'}, center: {enabled: true, text: deal ? deal.attributes.name : ''}}" :footer="{enabled: false}">
    <v-list v-if="deal">
      <v-list-tile :to="{name: 'admins-deal-edit', params: {id: deal.attributes.id}}">
        <v-list-tile-content>
          <v-list-tile-title>Edit</v-list-tile-title>
          <v-list-tile-sub-title>Change deal information</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'AdminsDeal',
  created() {
    this.loadDeal({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Admins/Deal', ['deal'])
  },
  methods: {
    ...Vuex.mapActions('Admins/Deal', ['loadDeal']),
    ...Vuex.mapActions('Login', ['initializeCurrentDeal']),
  }
}
</script>
<style scoped lang="sass">
</style>
