'use strict'

import DealUse      from './Users/DealUse'
import Payment      from './Users/Payment'
import Registration from './Users/Registration'

export default {
  namespaced: true,
  modules: {
    DealUse,
    Payment,
    Registration
  }
}
