<template>
  <v-layout column class="pa-4">
    <template v-if="futureEvents.length">
      <v-card v-for="event in futureEvents" :key="event.attributes.id" class="mb-4">
        <v-img
            v-if="event.attributes.imageFileNameUrl"
            :src="event.attributes.imageFileNameUrl"
            height="15rem">
          <v-img :src="require('../../assets/shadow-upward.png')" contain height="15rem" class="shading-bottom">
            <v-layout column fill-height justify-end class="pa-3 white--text">
              <v-flex shrink class="title mb-1">{{event.attributes.name}}</v-flex>
              <v-flex shrink class="subheading">{{event | eventTimeFormatted}}</v-flex>
            </v-layout>
          </v-img>
        </v-img>
        <div v-else>
          <div class="title mb-1">{{event.attributes.name}}</div>
          <div class="subheading">{{event | eventTimeFormatted}}</div>
        </div>
        <v-card-title primary-title class="pt-3 px-0" style="white-space: pre-line">
          <div>
            <sanitized-html
                :html="event.attributes.description"
                link-event-category="Event"
                link-event-action="click_external"
                :link-event-label="({url}) => `${winery.attributes.name} - ${event.attributes.name} (${url})`"
                :link-event-value="`${event.attributes.id}`"
                >
            </sanitized-html>
          </div>
        </v-card-title>
      </v-card>
    </template>
    <div class="title font-weight-regular pt-3 pb-2 mb-2 gray--text">Past events</div>
    <template v-if="recentPastEvents.length">
      <v-card v-for="event in recentPastEvents" :key="event.attributes.id" class="mb-4">
        <v-img
            v-if="event.attributes.imageFileNameUrl"
            :src="event.attributes.imageFileNameUrl"
            height="15rem">
          <v-img :src="require('../../assets/shadow-upward.png')" contain height="15rem" class="shading-bottom">
            <v-layout column fill-height justify-end class="pa-3 white--text">
              <v-flex shrink class="title mb-1">{{event.attributes.name}}</v-flex>
              <v-flex shrink class="subheading">{{event | eventTimeFormatted}}</v-flex>
            </v-layout>
          </v-img>
        </v-img>
        <div v-else>
          <div class="title mb-1">{{event.attributes.name}}</div>
          <div class="subheading">{{event | eventTimeFormatted}}</div>
        </div>
        <v-card-title primary-title class="pt-3 px-0" style="white-space: pre-line">
          <div>
            <sanitized-html
                :html="event.attributes.description"
                link-event-category="Event"
                link-event-action="click_external"
                :link-event-label="({url}) => `${winery.attributes.name} - ${event.attributes.name} (${url})`"
                :link-event-value="`${event.attributes.id}`"
                >
            </sanitized-html>
          </div>
        </v-card-title>
      </v-card>
    </template>
    <transition name="fade" appear v-if="!futureEvents.length && !recentPastEvents.length">
      <v-icon class="mt-5">icn icn-no-events</v-icon>
    </transition>
  </v-layout>
</template>
<script>
import moment from 'moment'
import _      from 'lodash'
import Vuex from 'vuex'
export default {
  name: 'EventsTab',
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    futureEvents() {
      const beginningOfDay = moment().startOf('day')
      return _.get(this.winery, 'relationships.events', []).filter(event => moment(event.attributes.adjustedAt).isAfter(beginningOfDay))
    },
    // Only get last 3 events
    recentPastEvents() {
      const beginningOfDay = moment().startOf('day')
      return _.takeRight(_.get(this.winery, 'relationships.events', []).filter(event => moment(event.attributes.adjustedAt).isBefore(beginningOfDay)), 3)
    }
  }
}
</script>
<style scoped lang="sass">
</style>
