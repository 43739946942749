'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    photos: []
  },
  getters: {
    photos(state) { return state.photos }
  },
  mutations: {
    setPhotos(state, {photos}) {
      state.photos = [...photos]
    }
  },
  actions: {
    async loadPhotos({commit}, {slug}) {
      const photos = (await api.Admins.EstablishmentPhotos.index({params: {filter: {establishmentId: slug}}})).data.data
      commit('setPhotos', {photos})
    },
    async attemptDestroyPhoto({dispatch}, {photo}) {
      try {
        await api.Admins.EstablishmentPhotos.destroy(photo.attributes.id)
        dispatch('loadPhotos', {slug: photo.attributes.establishmentId})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
