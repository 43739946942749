'use strict'

import moment from 'moment'

import api    from '../../../api'

export default {
  namespaced: true,
  state: {
    event: {}
  },
  getters: {
    event(state) { return state.event }
  },
  mutations: {
    initializeEvent(state) {
      state.event = {
        active: true,
        at: moment().add(1, 'day').set('hour', 14).set('minute', 0).toDate(),
        atEnd: moment().add(1, 'day').set('hour', 18).set('minute', 0).toDate(),
        repeatWeekly: false
      }
    },
    setEvent(state, {event}) {
      state.event = {...state.event, ...event}
    }
  },
  actions: {
    initializeEvent({commit}) {
      commit('initializeEvent')
    },
    setEvent({commit}, {event}) {
      commit('setEvent', {event})
    },
    async attemptSaveEvent({getters}) {
      try {
        await api.Operators.Events.create({params: {event: getters.event}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
