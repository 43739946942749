<template>
  <v-chip dark :color="active ? 'deep-red' : 'gray'" class="v-chip-small">Call</v-chip>
</template>
<script>
export default {
  name: 'WineryCallButton',
  props: {
    active: {
      type: Boolean,
      default: true
    }
  }
}
</script>
