'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    operators: []
  },
  getters: {
    operators(state) { return state.operators }
  },
  mutations: {
    setOperators(state, {operators}) {
      state.operators = [...operators]
    }
  },
  actions: {
    async loadOperators({commit}, {slug}) {
      const operators = (await api.Admins.EstablishmentOperators.index({params: {filter: {establishmentId: slug}}})).data.data
      commit('setOperators', {operators})
    },
    async attemptDestroyOperator({dispatch}, {operator}) {
      try {
        await api.Admins.EstablishmentOperators.destroy(operator.attributes.establishmentId, {params: {filter: {establishmentId: operator.attributes.establishmentId, userId: operator.attributes.userId}}})
        dispatch('loadOperators', {slug: operator.attributes.establishmentId})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
