import { render, staticRenderFns } from "./ValleyGuide.vue?vue&type=template&id=33e28d54&scoped=true&"
import script from "./ValleyGuide.vue?vue&type=script&lang=js&"
export * from "./ValleyGuide.vue?vue&type=script&lang=js&"
import style0 from "./ValleyGuide.vue?vue&type=style&index=0&id=33e28d54&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33e28d54",
  null
  
)

export default component.exports