'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    establishments: []
  },
  getters: {
    establishments(state) { return state.establishments }
  },
  mutations: {
    setEstablishments(state, {establishments}) {
      state.establishments = [...establishments]
    }
  },
  actions: {
    async loadEstablishments({commit}) {
      const establishments = (await api.Admins.Establishments.index()).data.data
      commit('setEstablishments', {establishments})
    }
  }
}
