'use strict'

import { authRoute } from './auth'
import components                    from '../components'

export default [
  {path: '/',                    name: 'welcome',             component: components['Welcome']},
  {path: '/map',                 redirect: {name: 'guide'},   component: components['MapScroller'],
    children: [
      {path: 'guide',               name: 'guide',            component: components['MapScroller/Guide']},
      {path: 'wineries',            name: 'wineries',         component: components['MapScroller/Wineries']},
      {path: 'events',              name: 'events',           component: components['MapScroller/Events']},
    ]
  },
  {path: '/spotlight/:id',            name: 'spotlight',                component: components['Spotlight']},
  {path: '/wineries-sustainable',     name: 'wineries-sustainable',     component: components['WineriesSustainable']},
  {path: '/wineries-beer-on-tap',     name: 'wineries-beer-on-tap',     component: components['WineriesBeerOnTap']},
  {path: '/wineries-open-late',       name: 'wineries-open-late',       component: components['WineriesOpenLate']},
  {path: '/wineries-live-music',      name: 'wineries-live-music',      component: components['WineriesLiveMusic']},
  {path: '/wineries-picnic-area',     name: 'wineries-picnic-area',     component: components['WineriesPicnicArea']},
  {path: '/wineries-dog-friendly',    name: 'wineries-dog-friendly',    component: components['WineriesDogFriendly']},
  {path: '/wineries-eat',             name: 'wineries-eat',             component: components['WineriesEat']},
  {path: '/wineries-lodging',         name: 'wineries-lodging',         component: components['WineriesLodging']},
  {path: '/wineries-weddings',        name: 'wineries-weddings',        component: components['WineriesWeddings']},
  {path: '/wineries-winery-tours',    name: 'wineries-winery-tours',    component: components['WineriesWineryTours']},
  {path: '/wineries-twenty-one-plus', name: 'wineries-twenty-one-plus', component: components['WineriesTwentyOnePlus']},
  {path: '/winery-list',              name: 'winery-list',              component: components['WineryList']},
  {
    path: '/winery/:slug',
    name: 'winery',
    component: components['Winery'],
    redirect: {name: 'winery-winery'},
    children: [
      {path: 'winery',   name: 'winery-winery',   component: components['Winery/WineryTab']},
      {path: 'events',   name: 'winery-events',   component: components['Winery/EventsTab']},
      {path: 'wines',    name: 'winery-wines',    component: components['Winery/WinesTab']},
      {path: 'hours',    name: 'winery-hours',    component: components['Winery/HoursTab']},
      {path: 'location', name: 'winery-location', component: components['Winery/LocationTab']}
    ]
  },
  {path: '/partners',              name: 'partners',              component: components['Partners']},
  {path: '/partner/:slug',         name: 'partner',               component: components['Partner']},

  {path: '/sip',                   name: 'sip',                   component: components['Sip']},
//  {path: '/experiences',           name: 'experiences',           component: components['VipDeals']},
//  {path: '/vip-deals/:id',         name: 'vip-deal',              component: components['VipDeals/VipDeal'],                                    beforeEnter: authRoute},
//  {path: '/vip-deals/:id/use',     name: 'vip-deal-use',          component: components['VipDeals/VipDealUse'],                                 beforeEnter: authRoute},

  {path: '/menu',                  name: 'menu',                  component: components['Menu'],        redirect: {name: 'about'},
    children: [
      {path: 'about',              name: 'about',                 component: components['Menu/About']},
      {path: 'login',              name: 'login',                 component: components['Menu/Login']},
      {path: 'privacy',            name: 'privacy',               component: components['Menu/Privacy']},
    ]
  },
  {path: '/operators',             redirect: {name: 'operators-dashboard'}, component: components['Operators'],
    children: [
      {path: 'dashboard',                           name: 'operators-dashboard',          component: components['Operators/Dashboard'],         beforeEnter: authRoute},
      {path: 'establishment/:slug',                 name: 'operators-establishment',      component: components['Operators/Establishment'],     beforeEnter: authRoute},
      {path: 'establishment/:slug/edit',            name: 'operators-establishment-edit', component: components['Operators/EstablishmentEdit'], beforeEnter: authRoute},
      {path: 'establishment/:slug/wines',           name: 'operators-wines',              component: components['Operators/Wines'],             beforeEnter: authRoute},
      {path: 'establishment/:slug/wines/new',       name: 'operators-wine-new',           component: components['Operators/WineNew'],           beforeEnter: authRoute},
      {path: 'establishment/:slug/wines/:id/edit',  name: 'operators-wine-edit',          component: components['Operators/WineEdit'],          beforeEnter: authRoute},
      {path: 'establishment/:slug/events',          name: 'operators-events',             component: components['Operators/Events'],            beforeEnter: authRoute},
      {path: 'establishment/:slug/events/new',      name: 'operators-event-new',          component: components['Operators/EventNew'],          beforeEnter: authRoute},
      {path: 'establishment/:slug/events/:id/edit', name: 'operators-event-edit',         component: components['Operators/EventEdit'],         beforeEnter: authRoute}
    ]
  },
//  {path: '/users',                 redirect: {name: 'experiences'},                         component: components['Users'],
//    children: [
//      {path: 'register',                            name: 'register',                     component: components['Users/Register']},
//      {path: 'register/information',                name: 'register-information',         component: components['Users/RegisterInformation']},
//      {path: 'register/pay',                        name: 'register-pay',                 component: components['Users/RegisterPay'],           beforeEnter: authRoute},
//      {path: 'register/pay-welcome',                name: 'register-pay-welcome',         component: components['Users/RegisterPayWelcome'],    beforeEnter: authRoute}
//    ]
//  },
  {path: '/admins',                redirect: {name: 'admins-dashboard'}, component: components['Admins'],
    children: [
      {path: 'dashboard',                         name: 'admins-dashboard',                        component: components['Admins/Dashboard'],                      beforeEnter: authRoute},
      {path: 'valley-guide',                      name: 'admins-valley-guide',                     component: components['Admins/ValleyGuide'],                    beforeEnter: authRoute},
      {path: 'reports',                           name: 'admins-reports',                          component: components['Admins/Reports'],                        beforeEnter: authRoute},
      {path: 'report/establishment-month-recap',  name: 'admins-report-establishment-month-recap', component: components['Admins/Report/EstablishmentMonthRecap'], beforeEnter: authRoute},
      {path: 'report/app-downloads',              name: 'admins-report-app-downloads',             component: components['Admins/Report/AppDownloads'],            beforeEnter: authRoute},
      {path: 'deals',                             name: 'admins-deals',                            component: components['Admins/Deals'],                          beforeEnter: authRoute},
      {path: 'deals/new',                         name: 'admins-deal-new',                         component: components['Admins/DealNew'],                        beforeEnter: authRoute},
      {path: 'deals/:id',                         name: 'admins-deal',                             component: components['Admins/Deal'],                           beforeEnter: authRoute},
      {path: 'deals/:id/edit',                    name: 'admins-deal-edit',                        component: components['Admins/DealEdit'],                       beforeEnter: authRoute},
      {path: 'spotlights',                        name: 'admins-spotlights',                       component: components['Admins/Spotlights'],                     beforeEnter: authRoute},
      {path: 'spotlight/:id/edit',                name: 'admins-spotlight-edit',                   component: components['Admins/SpotlightEdit'],                  beforeEnter: authRoute},
      {path: 'spotlight/new',                     name: 'admins-spotlight-new',                    component: components['Admins/SpotlightNew'],                   beforeEnter: authRoute},
      {path: 'users',                             name: 'admins-users',                            component: components['Admins/Users'],                          beforeEnter: authRoute},
      {path: 'user/new',                          name: 'admins-user-new',                         component: components['Admins/UserNew'],                        beforeEnter: authRoute},
      {path: 'user/:id',                          name: 'admins-user',                             component: components['Admins/User'],                           beforeEnter: authRoute},
      {path: 'user/:id/edit',                     name: 'admins-user-edit',                        component: components['Admins/UserEdit'],                       beforeEnter: authRoute},
      {path: 'user/:id/payments',                 name: 'admins-user-payments',                    component: components['Admins/Users/Payments'],                 beforeEnter: authRoute},
      {path: 'user/:id/operators',                name: 'admins-user-operators',                   component: components['Admins/UserOperators'],                  beforeEnter: authRoute},
      {path: 'user/:id/operators/new',            name: 'admins-user-operators-new',               component: components['Admins/UserOperatorsNew'],               beforeEnter: authRoute},
      {path: 'establishments',                    name: 'admins-establishments',                   component: components['Admins/Establishments'],                 beforeEnter: authRoute},
      {path: 'establishment/new',                 name: 'admins-establishment-new',                component: components['Admins/EstablishmentNew'],               beforeEnter: authRoute},
      {path: 'establishment/:slug',               name: 'admins-establishment',                    component: components['Admins/Establishment'],                  beforeEnter: authRoute},
      {path: 'establishment/:slug/edit',          name: 'admins-establishment-edit',               component: components['Admins/EstablishmentEdit'],              beforeEnter: authRoute},
      {path: 'establishment/:slug/operators',     name: 'admins-establishment-operators',          component: components['Admins/EstablishmentOperators'],         beforeEnter: authRoute},
      {path: 'establishment/:slug/operators/new', name: 'admins-establishment-operators-new',      component: components['Admins/EstablishmentOperatorsNew'],      beforeEnter: authRoute},
      {path: 'establishment/:slug/photos',        name: 'admins-establishment-photos',             component: components['Admins/EstablishmentPhotos'],            beforeEnter: authRoute},
      {path: 'establishment/:slug/photos/new',    name: 'admins-establishment-photos-new',         component: components['Admins/EstablishmentPhotosNew'],         beforeEnter: authRoute},
    ]
  },

  {path: '/privacy',  redirect: {name: 'privacy'}},
  {path: '*',         redirect: {name: 'guide'}}
]
