<template>
  <page :header="{enabled: true, back: {name: 'operators-establishment', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'Events'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'operators-event-new', params: {slug: $route.params.slug}})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <div
        v-for="group in [{name: 'Upcoming', key: 'activeFuture'}, {name: 'Past', key: 'activePast'}, {name: 'Inactive', key: 'inactive'}]"
        :key="group.name"
        >
      <v-list v-if="groupedEvents[group.key].length">
        <v-subheader>{{group.name}} ({{groupedEvents[group.key].length}})</v-subheader>
        <v-list-tile
            v-for="event in groupedEvents[group.key]"
            :key="event.attributes.id"
            :to="{name: 'operators-event-edit', params: {slug: $route.params.slug, id: event.attributes.id}}"
            >
          <v-sheet
              color="grey"
              min-width="3rem"
              width="3rem"
              height="2rem"
              class="mr-3 handle">
            <v-img
                v-if="event.attributes.imageFileNameUrl"
                min-width="3rem"
                width="3rem"
                height="2rem"
                :src="event.attributes.imageFileNameUrl">
            </v-img>
          </v-sheet>
          <v-list-tile-content>
            <v-list-tile-title v-text="event.attributes.name"></v-list-tile-title>
            <v-list-tile-sub-title>{{event | eventTimeFormatted}}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-list-tile>
      </v-list>
    </div>
  </page>
</template>
<script>
import Vuex      from 'vuex'
import _         from 'lodash'
import moment    from 'moment'
export default {
  name: 'OperatorsEvents',
  created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.loadEvents({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishment', ['establishment']),
    ...Vuex.mapGetters('Operators/Events', ['events']),
    groupedEvents() {
      const sortedEvents = _.sortBy(this.events, ['attributes.sortOrder', 'attributes.adjustedAt'])
      let groupedEvents = {activeFuture: [], activePast: [], inactive: []}
      for (let e of sortedEvents) {
        const key = !e.attributes.active ? 'inactive' : (e.attributes.repeatDaily || e.attributes.repeatWeekly || moment(e.attributes.adjustedAt).isAfter(moment()) ? 'activeFuture' : 'activePast')
        groupedEvents[key].push(e)
      }
      return groupedEvents
    }
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Operators/Events', ['loadEvents']),
  }
}
</script>
<style scoped lang="sass">
</style>
