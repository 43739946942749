'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    deals: []
  },
  getters: {
    deals(state) { return state.deals }
  },
  mutations: {
    setDeals(state, {deals}) {
      state.deals = [...deals]
    }
  },
  actions: {
    async loadDeals({commit}) {
      const deals = (await api.Admins.Deals.index()).data.data
      commit('setDeals', {deals})
    }
  }
}
