<template>
  <v-dialog
      :value="true"
      v-model="computedDialog"
      v-if="photos.length"
      >
    <v-layout
        column
        align-center
        v-touch:swipe.left="swipeLeft"
        v-touch:swipe.right="swipeRight"
        class="photos-card"
        >
      <v-carousel
          hide-delimiters
          hide-controls
          :cycle="false"
          v-model="slide"
          v-touch="{left: swipeLeft, right: swipeRight}"
          class="photos-carousel"
          >
        <v-carousel-item
            v-for="photo in photos"
            :key="photo.attributes.imageFileNameUrl"
            :src="photo.attributes.imageFileNameUrl"
            >
        </v-carousel-item>
      </v-carousel>
      <v-btn
          class="photos-left"
          icon
          :ripple="false"
          color="transparent-gray"
          @click="swipeRight"
          >
        <v-icon>icn icn-back-wht icn-size icn-size-1</v-icon>
      </v-btn>
      <v-btn
          class="photos-right"
          icon
          :ripple="false"
          color="transparent-gray"
          @click="swipeLeft"
          >
        <v-icon>icn icn-next-wht icn-size icn-size-1</v-icon>
      </v-btn>
      <v-btn
          class="photos-close"
          flat
          icon
          large
          @click="computedDialog = false"
          >
        <v-icon>icn icn-close-wht icn-size icn-size-2</v-icon>
      </v-btn>
    </v-layout>
  </v-dialog>
</template>
<style lang="sass">
.v-overlay--active:before
  opacity: 0.7 !important
</style>
<style scoped lang="sass">
::v-deep .v-dialog
  overflow-y: initial
::v-deep .photos-card
  position: relative
  .photos-carousel
    border-radius: 0.5rem
    overflow: hidden
  .photos-left
    position: absolute
    top: calc(50% - 1rem)
    left: -1rem
  .photos-right
    position: absolute
    top: calc(50% - 1rem)
    right: -1rem
  .photos-close
    position: absolute
    top: calc(100% + 0.5rem)
</style>
<script>
import Vuex from 'vuex'
export default {
  name: 'WineryPhotos',
  props: ['value'],
  data() {
    return {
      slide: 0
    }
  },
  created() {
    this.clearPhotos()
    this.loadPhotos({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Winery/Photos', ['photos']),
    computedDialog: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },
  },
  methods: {
    ...Vuex.mapActions('Winery/Photos', ['loadPhotos', 'clearPhotos']),
    swipeLeft() { this.slide = this.slide + 1 },
    swipeRight() { this.slide = this.slide === 0 ? (this.photos.length - 1) : (this.slide - 1) },
  }
}
</script>
