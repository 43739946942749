'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    spotlight: null,
    errors: [],
  },
  getters: {
    spotlight(state) { return state.spotlight },
    errors(state) { return state.errors }
  },
  mutations: {
    setSpotlight(state, {spotlight}) {
      state.spotlight = spotlight ? {...(state.spotlight || {}), ...spotlight} : spotlight
    },
    setErrors(state, {errors}) {
      state.errors = [...errors]
    }
  },
  actions: {
    async loadSpotlight({commit}, {id}) {
      commit('setSpotlight', {spotlight: null})
      commit('setErrors', {errors: []})
      const spotlight = (await api.Admins.Spotlights.show(id)).data.data
      commit('setSpotlight', {spotlight: spotlight.attributes})
    },
    setSpotlight({commit}, {spotlight}) {
      commit('setSpotlight', {spotlight})
    },
    async attemptSaveSpotlight({commit, getters}) {
      try {
        await api.Admins.Spotlights.update(getters.spotlight.id, {params: {spotlight: getters.spotlight}})
        return true
      } catch(e) {
        commit('setErrors', {errors: e.response.data.errors})
        return false
      }
    }
  }
}
