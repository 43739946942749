'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    establishment: null,
    errors: [],
  },
  getters: {
    establishment(state) { return state.establishment },
    errors(state) { return state.errors }
  },
  mutations: {
    setEstablishment(state, {establishment}) {
      state.establishment = establishment ? {...(state.establishment || {}), ...establishment} : establishment
    },
    setErrors(state, {errors}) {
      state.errors = [...errors]
    }
  },
  actions: {
    async loadEstablishment({commit, dispatch, rootGetters}, {slug}) {
      commit('setEstablishment', {establishment: null})
      commit('setErrors', {errors: []})
      await dispatch('Admins/Establishment/loadEstablishment', {slug}, {root: true})
      commit('setEstablishment', {establishment: rootGetters['Admins/Establishment/establishment'].attributes})
    },
    setEstablishment({commit}, {establishment}) {
      commit('setEstablishment', {establishment})
    },
    async attemptSaveEstablishment({commit, getters}) {
      try {
        await api.Admins.Establishments.update(getters.establishment.slug, {params: {establishment: getters.establishment}})
        return true
      } catch(e) {
        commit('setErrors', {errors: e.response.data.errors})
        return false
      }
    }
  }
}
