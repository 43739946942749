<template>
  <lean :left-to="{name: 'guide'}">
    <page :header="{back: {name: 'guide'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}">
      <v-img :src="require('../assets/wineries-eat.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
        <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top">
          <v-layout column fill-height justify-start align-center class="pa-2 white--text">
            <v-flex shrink class="headline mt-5">Eats</v-flex>
          </v-layout>
        </v-img>
      </v-img>
      <v-layout justify-center class="px-4 pt-3">
        <v-layout shrink class="wineries-eats-picker" justify-space-between d-inline-flex>
          <v-btn
              round
              v-for="(amenity, i) in amenities"
              :key="amenity"
              :color="selectedAmenity === amenity ? 'deep-red' : 'white'"
              :class="{[selectedAmenity === amenity ? 'white--text' : 'gray--text']: true, 'mr-3': i < amenities.length - 1}"
              class="ma-0 elevation-0"
              @click.prevent="selectedAmenity = amenity"
              >
            {{amenity}}
          </v-btn>
        </v-layout>
      </v-layout>
      <list-of-establishments
          :establishments="sortedFilteredWineries"
          :condensed="false"
          :link-query="{back: {name: 'wineries-eat'}}"
          class="px-2 pb-5"></list-of-establishments>
    </page>
  </lean>
</template>
<script>
import Vuex                 from 'vuex'
import _                    from 'lodash'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'WineriesEat',
  components: {
    ListOfEstablishments
  },
  data() {
    return {
      selectedAmenity: 'Restaurant'
    }
  },
  created() {
    this.loadWineries()
  },
  computed: {
    ...Vuex.mapGetters('WineryList', ['wineries']),
    amenities() {
      return ['Restaurant', 'Food Truck', 'Casual Eats']
    },
    allFilteredWineries() {
      return this.wineries.filter(winery => _.intersection(this.amenities, winery.attributes.amenities).length)
    },
    filteredWineries() {
      return this.allFilteredWineries.filter(winery => _.intersection([this.selectedAmenity], winery.attributes.amenities).length)
    },
    sortedFilteredWineries() {
      return _.sortBy(
        this.filteredWineries,
        winery => [!winery.attributes.partnered, winery.attributes.name]
      )
    }
  },
  methods: {
    ...Vuex.mapActions('WineryList', ['loadWineries'])
  }
}
</script>
<style scoped lang="sass">
.wineries-eats-picker
  border: 1px solid var(--gray)
  border-radius: 2rem
</style>
