'use strict'

import temeculalifeAxios from '../temeculalife-axios'

// API calls

export default {
  Admins: {
    Deals: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/deals`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/admins/deals/${id}`, {params}) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/admins/deals/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/deals`, params) },
      destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/admins/deals/${id}`, params) },
    },
    Establishments: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/establishments`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/admins/establishments/${id}`, {params}) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/admins/establishments/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/establishments`, params) }
    },
    EstablishmentOperators: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/establishment-operators`, {params}) },
      destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/admins/establishment-operators/${id}`, {params}) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/establishment-operators`, params) }
    },
    EstablishmentPhotos: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/establishment-photos`, {params}) },
      destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/admins/establishment-photos/${id}`, {params}) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/establishment-photos`, params) }
    },
    Payments: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/payments`, {params}) },
    },
    Reports: {
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/admins/reports/${id}`, {params}) }
    },
    Spotlights: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/spotlights`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/admins/spotlights/${id}`, {params}) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/admins/spotlights/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/spotlights`, params) }
    },
    Users: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/admins/users`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/admins/users/${id}`, {params}) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/admins/users/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/users`, params) },
    },
    ValleyGuideCompletion: {
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/admins/valley-guide-completion`, params) },
    }
  },
  Deals: {
    index({params = {}} = {}) {       return temeculalifeAxios.get(`/deals`, {params}) },
    show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/deals/${id}`, {params}) }
  },
  Establishments: {
    index({params = {}} = {}) { return temeculalifeAxios.get(`/establishments`, {params}) },
    show(id, {params = {}} = {}) { return temeculalifeAxios.get(`/establishments/${id}`, {params}) }
  },
  EstablishmentPhotos: {
    index({params = {}} = {}) {       return temeculalifeAxios.get(`/establishment-photos`, {params}) },
  },
  EventReminders: {
    index({params = {}} = {}) {       return temeculalifeAxios.get(`/event-reminders`, {params}) },
    destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/event-reminders/${id}`, {params}) },
    create({params = {}} = {}) {      return temeculalifeAxios.post(`/event-reminders`, params) }
  },
  Events: {
    index({params = {}} = {}) { return temeculalifeAxios.get(`/events`, {params}) }
  },
  InterestedUser: {
    create({params = {}} = {}) { return temeculalifeAxios.post(`/interested-users`, params) }
  },
  Login: {
    create({params = {}} = {}) { return temeculalifeAxios.post(`/logins`, params) }
  },
  Operators: {
    Establishments: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/operators/establishments`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/operators/establishments/${id}`, {params}) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/operators/establishments/${id}`, params) }
    },
    Events: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/operators/events`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/operators/events/${id}`, {params}) },
      destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/operators/events/${id}`, params) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/operators/events/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/operators/events`, params) }
    },
    Wines: {
      index({params = {}} = {}) {       return temeculalifeAxios.get(`/operators/wines`, {params}) },
      show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/operators/wines/${id}`, {params}) },
      destroy(id, {params = {}} = {}) { return temeculalifeAxios.delete(`/operators/wines/${id}`, params) },
      update(id, {params = {}} = {}) {  return temeculalifeAxios.patch(`/operators/wines/${id}`, params) },
      create({params = {}} = {}) {      return temeculalifeAxios.post(`/operators/wines`, params) }
    }
  },
  Spotlight: {
    index({params = {}} = {}) {       return temeculalifeAxios.get(`/spotlights`, {params}) },
    show(id, {params = {}} = {}) {    return temeculalifeAxios.get(`/spotlights/${id}`, {params}) }
  },
  UserHit: {
    create({params = {}} = {}) { return temeculalifeAxios.post(`/user-hits`, params) }
  },
  Users: {
    DealUses: {
      create({params = {}} = {}) { return temeculalifeAxios.post(`/users/deal-uses`, params) }
    },
    Registrations: {
      create({params = {}} = {}) { return temeculalifeAxios.post(`/users/registrations`, params) }
    },
    Payments: {
      create({params = {}} = {}) { return temeculalifeAxios.post(`/users/payments`, params) }
    }
  }
}
