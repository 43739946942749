'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    operator: null,
    users: []
  },
  getters: {
    operator(state) { return state.operator },
    users(state) { return state.users }
  },
  mutations: {
    initializeOperator(state) {
      state.operator = {}
    },
    setUsers(state, {users}) {
      state.users = [...users]
    },
    setOperator(state, {operator}) {
      state.operator = {...state.operator, ...operator}
    }
  },
  actions: {
    initializeOperator({commit}) {
      commit('initializeOperator')
    },
    async searchForUsers({commit}, {search}) {
      const users = (await api.Admins.Users.index({params: {filter: {search}}})).data.data
      commit('setUsers', {users})
    },
    setOperator({commit}, {operator}) {
      commit('setOperator', {operator})
    },
    async attemptSaveOperator({getters}) {
      try {
        await api.Admins.EstablishmentOperators.create({params: {establishmentOperator: getters.operator}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
