'use strict'

import api from '../../../../api'

export default {
  namespaced: true,
  state: {
    payments: [],
  },
  getters: {
    payments(state) { return state.payments },
  },
  mutations: {
    setPayments(state, {payments}) {
      state.payments = [...payments]
    },
  },
  actions: {
    async loadPayments({commit}, {userId}) {
      const payments = (await api.Admins.Payments.index({params: {filter: {userId}}})).data.data
      commit('setPayments', {payments})
    },
  }
}
