<template>
  <page :header="{enabled: true, back: {name: 'admins-users'}, center: {enabled: true, text: user ? user.attributes.email : ''}}" :footer="{enabled: false}">
    <v-list v-if="user">
      <v-list-tile :to="{name: 'admins-user-edit', params: {id: user.attributes.id}}">
        <v-list-tile-content>
          <v-list-tile-title>Edit</v-list-tile-title>
          <v-list-tile-sub-title>Change user information</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile
          v-if="activeMembership"
          :href="`https://dashboard.stripe.com/customers/${user.attributes.stripeCustomerId}`"
          target="_blank"
          >
        <v-list-tile-content>
          <v-list-tile-title>Stripe</v-list-tile-title>
          <v-list-tile-sub-title>Direct link to Stripe customer page</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-user-payments', params: {id: user.attributes.id}}">
        <v-list-tile-content>
          <v-list-tile-title>Payments</v-list-tile-title>
          <v-list-tile-sub-title>List of all subscriptions and payments</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-dialog v-model="confirmingLoginAs" style="display: block">
        <v-list-tile slot="activator" @click.prevent="">
          <v-list-tile-content>
            <v-list-tile-title>Login as</v-list-tile-title>
            <v-list-tile-sub-title>Login as this user</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-list-tile>
        <v-card>
          <v-card-title class="headline grey lighten-2">Login as this user?</v-card-title>
          <v-card-actions class="justify-center py-3">
            <v-btn
                color="error"
                @click="loginAs">
              Yes
            </v-btn>
            <v-btn
                @click="confirmingLoginAs = false">
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'AdminsUser',
  data() {
    return {
      confirmingLoginAs: false
    }
  },
  created() {
    this.loadUser({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Admins/User', ['user', 'activeMembership'])
  },
  methods: {
    ...Vuex.mapActions('Admins/User', ['loadUser']),
    ...Vuex.mapActions('Login', ['initializeCurrentUser']),
    async loginAs() {
      await this.initializeCurrentUser({givenCredentials: {email: this.user.attributes.email, token: this.user.attributes.token}})
      this.$router.push({name: 'login'})
    },
  }
}
</script>
<style scoped lang="sass">
</style>
