'use strict'

import moment from 'moment'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    filters: {},
    report: null
  },
  getters: {
    filters(state) { return state.filters },
    report(state) { return state.report }
  },
  mutations: {
    initialize(state) {
      state.report = null
      state.filters = {
        start: moment('2019-07-01').format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
      }
    },
    setFilters(state, {filters}) {
      state.filters = {...state.filters, ...filters}
    },
    setReport(state, {report}) {
      state.report = {...report}
    }
  },
  actions: {
    initialize({commit}) {
      commit('initialize')
    },
    setFilters({commit}, {filters}) {
      commit('setFilters', {filters})
    },
    async attemptGenerateReport({commit, getters}) {
      try {
        const report = (await api.Admins.Reports.show('app-downloads', {params: {filters: getters.filters}})).data.data.attributes
        commit('setReport', {report})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
