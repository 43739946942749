<template>
  <div
      id="v-app-wrapper"
      :style="{'--reasonable-header-height': reasonableHeaderHeightWithPx, '--viewport-width': viewportWidthWithPx, '--viewport-height': viewportHeightWithPx}"
      >
    <v-app
        id="v-app"
        :class="{'wide-screen': wideScreen}"
        >
      <header-navigation></header-navigation>
      <transition
          :name="transitionName"
          @before-enter="setAppInfo({appInfo: {pageTransitioning: true}})"
          @after-leave="setAppInfo({appInfo: {pageTransitioning: false}})"
          >
        <router-view :key="$route.matched[0].path"></router-view>
      </transition>
      <capture-user></capture-user>
      <footer-navigation></footer-navigation>
    </v-app>
    <v-app
        id="v-side"
        v-if="wideScreen"
        >
      <v-layout
          column
          align-center
          justify-center
          v-if="showSideContent"
          >
        <v-layout column align-center shrink class="mt-5">
          <v-img contain width="8rem" max-height="10rem" :src="require('../assets/icon.svg')"></v-img>
          <v-img contain min-width="17rem" max-height="14rem" :src="require('../assets/name.svg')" class="mt-2"></v-img>
        </v-layout>
        <v-layout column align-center justify-center shrink class="mt-5 subheading white--text font-weight-regular align-center">
          <div>Thanks for being a part of the</div>
          <div>Temecula Life community!</div>
        </v-layout>
        <v-layout column align-center shrink class="mt-5 white--text">
          <v-btn round outline href="mailto:temeculalifeapp@gmail.com" color="white">Contact Us</v-btn>
        </v-layout>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import Vuex from 'vuex'
import CaptureUser from './CaptureUser'
export default {
  name: 'App',
  components: {
    CaptureUser
  },
  data() {
    return {
      transitionName:  '',
      resize:          null
    }
  },
  created() {
    const resize = () => {
      this.setAppInfo({
        appInfo: {
          fullViewportWidth:      window.innerWidth,
          fullViewportHeight:     window.innerHeight,
          viewportWidth:          (window.innerWidth > window.innerHeight) ? Math.min(450, window.innerWidth) : window.innerWidth,
          viewportHeight:         this.platform === 'iOS' ? window.outerHeight : window.innerHeight,
          reasonableHeaderHeight: Math.min(window.innerHeight * 0.25, 238)
        }
      })
    }
    resize()
    this.resize = window.addEventListener('resize', resize, false)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize, false)
  },
  computed: {
    ...Vuex.mapGetters('AppInfo', ['appInfo', 'platform']),
    wideScreen() {
      return this.appInfo.fullViewportWidth > this.appInfo.fullViewportHeight
    },
    showSideContent() {
      return (this.appInfo.fullViewportWidth - this.appInfo.viewportWidth) > 400
    },
    reasonableHeaderHeightWithPx() {
      return this.appInfo.reasonableHeaderHeight + 'px'
    },
    viewportWidthWithPx() {
      return this.appInfo.viewportWidth + 'px'
    },
    viewportHeightWithPx() {
      return this.appInfo.viewportHeight + 'px'
    }
  },
  watch: {
    '$route'(to, from) {
      const transitions = [
        ['welcome',             'guide',                 'page-slide-left'],
        ['guide',               'welcome',               'page-slide-right'],
        ['guide',               'spotlight',             'page-slide-left'],
        [/.*/,                  'about',                 'page-slide-left'],
        [/.*/,                  'guide',                 'page-slide-right'],
        ['register',            'vip-deals',             'page-slide-right'],
        ['register-information','vip-deals',             'page-slide-right'],
        ['vip-deal',            'vip-deals',             'page-slide-right'],
        ['vip-deals',           'register',              'page-slide-left'],
        [/.*/,                  'vip-deals',             'page-slide-left'],
        ['vip-deals',           'partners',              'page-slide-right'],
        ['vip-deals',           'vip-deal',              'page-slide-left'],
        ['vip-deal-use',        'vip-deals',             'page-slide-right'],
        ['vip-deal-use',        'vip-deal',              'page-slide-right'],
        ['vip-deal',            'vip-deal-use',          'page-slide-left'],
        ['vip-deals',           /.*/,                    'page-slide-right'],
        ['partners',            'partner',               'page-slide-left'],
        ['partner',             'partners',              'page-slide-right'],
        ['partners',            'events',                'page-slide-right'],
        ['partners',            'wineries',              'page-slide-right'],
        ['winery',              'winery-list',           'page-slide-right'],
        [/^winery-/,            'events',                'page-slide-right'],
        [/.*/,                  'partners',              'page-slide-left'],
        [/^winery-/,            /^wineries-/,            'page-slide-right'],
        [/.*/,                  /^wineries-/,            'page-slide-left'],
        ['login',               'admins-dashboard',      'page-slide-left'],
        ['admins-dashboard',    'login',                 'page-slide-right'],
        ['login',               'operators-dashboard',   'page-slide-left'],
        ['operators-dashboard', 'login',                 'page-slide-right'],
        [/.*/,                  'login',                 'page-slide-left'],
        [/.*/,                  'winery-winery',         'page-slide-left'],
        [/.*/,                  'winery-events',         'page-slide-left'],
        [/.*/,                  'winery-wines',          'page-slide-left'],
        [/.*/,                  'winery-hours',          'page-slide-left'],
        [/.*/,                  'winery-location',       'page-slide-left'],
        ['winery',              'guide',                 'page-slide-right'],
        ['winery',              'wineries',              'page-slide-right'],
        ['login',               'guide',                 'page-slide-right'],
        ['contact',             'guide',                 'page-slide-right'],
        ['register',            'register-information',  'page-slide-left'],
        ['register-information','register-pay',          'page-slide-left'],
        ['register-pay',        'register-pay-welcome',  'page-slide-left'],
      ]
      const foundTransition = transitions.find(([f,t]) => {
        return (typeof(f) === 'string' ? f === from.name : from.name.match(f)) &&
          (typeof(t) === 'string' ? t === to.name : to.name.match(t))
      })
      if (foundTransition) {
        this.transitionName = foundTransition[2]
      } else {
        this.transitionName = ''
      }
    }
  },
  methods: {
    ...Vuex.mapActions('AppInfo', ['setAppInfo'])
  }
}
</script>
<style lang="sass">
#v-app-wrapper
  display: flex
  flex-direction: row
  #v-app
    overflow: hidden
    width: var(--viewport-width)
    height: var(--viewport-height)
    position: relative
    &.wide-screen
      z-index: 1
  #v-side
    z-index: 0
    flex: 1 1
    width: calc(100vw - var(--viewport-width))
    height: var(--viewport-height)
    background-color: black !important
</style>
