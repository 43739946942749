<template>
  <lean :right-to="{name: 'guide'}">
    <page :header="{enabled: false}" :footer="{enabled: false}">
      <v-layout
          column
          align-center
          justify-space-around
          @click="$router.push({name: 'guide'})"
          class="full">
      <transition name="fade" appear>
        <v-layout
            column
            align-center
            justify-space-around
            class="full-inner"
            >
          <transition name="fade-slowly" appear>
            <v-layout column align-center shrink class="mt-5">
              <v-img contain width="14rem" max-height="16rem" :lazy-src="require('../assets/blank.svg')" :src="require('../assets/icon.svg')"></v-img>
              <v-img contain min-width="17rem" max-height="14rem" :lazy-src="require('../assets/blank.svg')" :src="require('../assets/name.svg')" class="mt-2"></v-img>
            </v-layout>
          </transition>
          <transition name="fade-slowly" appear>
            <div class="headline white--text font-weight-regular">Wine Country Guide</div>
          </transition>
          <transition name="fade-slowly" appear>
            <v-layout column align-center shrink class="mt-4 mb-2 white--text">
              Take me to wine country &rarr;
            </v-layout>
          </transition>
        </v-layout>
      </transition>
        </v-layout>
    </page>
  </lean>
</template>
<script>
export default {
  name: 'Welcome',
  data() {
    return {
      proceedTimeout: null
    }
  },
  mounted() {
    this.proceedTimeout = setTimeout(
      () => this.$router.replace({name: 'guide'}),
      2000
    )
  },
  beforeDestroy() {
    if (this.proceedTimeout) {
      clearTimeout(this.proceedTimeout)
    }
  }
}
</script>
<style scoped lang="sass">
.full
  height: var(--viewport-height)
  background-color: var(--deep-red)
  .full-inner
    background-image: url('../assets/map-background.svg')
    background-size: cover
    width: 100%
</style>
