'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    photo: null,
  },
  getters: {
    photo(state) { return state.photo },
  },
  mutations: {
    initializePhoto(state) {
      state.photo = {}
    },
    setPhoto(state, {photo}) {
      state.photo = {...state.photo, ...photo}
    }
  },
  actions: {
    initializePhoto({commit}) {
      commit('initializePhoto')
    },
    setPhoto({commit}, {photo}) {
      commit('setPhoto', {photo})
    },
    async attemptSavePhoto({getters}) {
      try {
        await api.Admins.EstablishmentPhotos.create({params: {establishmentPhoto: getters.photo}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
