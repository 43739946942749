'use strict'

import _                    from 'lodash'

import api                  from '../../api'

export default {
  namespaced: true,
  state: {
    partners: [],
    activeTag: null
  },
  getters: {
    partners(state) { return state.partners },
    tags(state) { return _.uniq(_.map(state.partners, 'attributes.partnerType')) },
    activeTag(state) { return state.activeTag },
    shownPartners(state) { return _.filter(state.partners, {attributes: {partnerType: state.activeTag}}) }
  },
  mutations: {
    setPartners(state, {partners}) {
      state.partners = [...partners]
    },
    setActiveTag(state, {activeTag}) {
      state.activeTag = activeTag
    }
  },
  actions: {
    async loadPartners({commit, dispatch, getters}) {
      const partners = (await api.Establishments.index({params: {filter: {type: 'Partner'}}})).data.data
      commit('setPartners', {partners})
      if (!getters.activeTag) {
        dispatch('setActiveTag', {activeTag: _.sample(getters.tags)})
      }
    },
    setActiveTag({commit}, {activeTag}) {
      commit('setActiveTag', {activeTag})
    }
  }
}
