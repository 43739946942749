<template>
  <page :header="{enabled: false}" :footer="{enabled: false}">
    <v-layout
        column
        class="full pa-3">
      <router-link :to="{name: 'vip-deal', params: {id: $route.params.id}}" class="pa-2">
        <v-icon>icn icn-close-wht</v-icon>
      </router-link>
      <div v-if="dealUse">
        <v-card class="px-3 py-4">
          <v-layout column align-center>
            <div class="display-1 my-2">{{dealUse.relationships.establishment.attributes.name}}</div>
            <div class="title my-2">{{dealUse.relationships.deal.attributes.name}}</div>
            <div class="subheading my-2">{{dealUse.relationships.deal.attributes.description}}</div>
            <div class="display-3 my-2 deep-red white--text px-3 border-radius">{{dealUse.attributes.authCode}}</div>
          </v-layout>
        </v-card>
      </div>
    </v-layout>
  </page>
</template>
<script>
import Vuex      from 'vuex'
export default {
  name: 'VipDealsVipDealUse',
  async created() {
    try {
      await this.attemptCreateDealUse({dealId: this.$route.params.id})
    } catch(e) {
      this.$router.replace({name: 'vip-deal', params: {id: this.$route.params.id}})
    }
  },
  computed: {
    ...Vuex.mapGetters('Users/DealUse', ['dealUse'])
  },
  methods: {
    ...Vuex.mapActions('Users/DealUse', ['attemptCreateDealUse'])
  }
}
</script>
<style scoped lang="sass">
.full
  min-height: var(--viewport-height)
  background-color: var(--deep-red)
  background-image: url('../../assets/map-background.svg')
  background-size: cover
  width: 100%
  padding-top: env(safe-area-inset-top) !important
</style>
