'use strict'

import moment from 'moment'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    filters: {},
    establishments: [],
    report: null
  },
  getters: {
    filters(state) { return state.filters },
    establishments(state) { return state.establishments },
    report(state) { return state.report }
  },
  mutations: {
    initialize(state) {
      state.report = null
      state.filters = {
        month: moment().format('YYYY-MM'),
        monthEnd: moment().format('YYYY-MM')
      }
    },
    setEstablishments(state, {establishments}) {
      state.establishments = [...establishments]
    },
    setFilters(state, {filters}) {
      state.filters = {...state.filters, ...filters}
    },
    setReport(state, {report}) {
      state.report = {...report}
    }
  },
  actions: {
    initialize({commit}) {
      commit('initialize')
    },
    async searchForEstablishments({commit}, {search}) {
      const establishments = (await api.Admins.Establishments.index({params: {filter: {search}}})).data.data
      commit('setEstablishments', {establishments})
    },
    setFilters({commit}, {filters}) {
      commit('setFilters', {filters})
    },
    async attemptGenerateReport({commit, getters}) {
      try {
        const report = (await api.Admins.Reports.show('establishment-month-recap', {params: {filters: getters.filters}})).data.data.attributes
        commit('setReport', {report})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
