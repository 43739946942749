'use strict'

import Vue  from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// This fixes devtools not picking up store
Vue.config.devtools = true

import Acknowledgements from './modules/Acknowledgements'
import Admins           from './modules/Admins'
import AppInfo          from './modules/AppInfo'
import CaptureUser      from './modules/CaptureUser'
import Deal             from './modules/Deal'
import Deals            from './modules/Deals'
import Events           from './modules/Events'
import EventReminders   from './modules/EventReminders'
import FooterNavigation from './modules/FooterNavigation'
import Guide            from './modules/Guide'
import HeaderNavigation from './modules/HeaderNavigation'
import Login            from './modules/Login'
import Map              from './modules/Map'
import Operators        from './modules/Operators'
import Partner          from './modules/Partner'
import Partners         from './modules/Partners'
import Spotlight        from './modules/Spotlight'
import Users            from './modules/Users'
import Wineries         from './modules/Wineries'
import Winery           from './modules/Winery'
import WineryList       from './modules/WineryList'

export default new Vuex.Store({
  modules: {
    Acknowledgements,
    Admins,
    AppInfo,
    CaptureUser,
    Deal,
    Deals,
    Events,
    EventReminders,
    FooterNavigation,
    Guide,
    HeaderNavigation,
    Login,
    Map,
    Operators,
    Partner,
    Partners,
    Spotlight,
    Users,
    Wineries,
    Winery,
    WineryList,
  }
})
