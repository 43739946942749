<template>
  <page :header="{enabled: true, back: {name: 'admins-establishment', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'Photos'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-establishment-photos-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <v-layout column>
      <v-layout
          v-for="(photo, i) in photos"
          :key="photo.attributes.imageFileNameUrl"
          row
          justify-space-between
          align-center
          class="px-3 py-2"
          >
        <v-sheet
            color="light-gray"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3">
          <v-img
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="photo.attributes.imageFileNameUrl">
          </v-img>
        </v-sheet>
        <v-dialog v-model="confirmingDestroy[i]">
          <v-icon slot="activator" color="red">delete_forever</v-icon>
          <v-card>
            <v-card-title class="headline grey lighten-2">Delete?</v-card-title>
            <v-card-actions class="justify-center py-3">
              <v-btn
                  color="error"
                  :disabled="processing"
                  :loading="processing"
                  @click="destroyPhoto({photo})">
                Yes
              </v-btn>
              <v-btn
                  :disabled="processing"
                  :loading="processing"
                  @click="confirmingDestroy[i] = false">
                No
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-layout>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'AdminsEstablishmentPhotos',
  data() {
    return {
      confirmingDestroy: {},
      processing: false
    }
  },
  created() {
    this.loadPhotos({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Admins/EstablishmentPhotos', ['photos'])
  },
  methods: {
    ...Vuex.mapActions('Admins/EstablishmentPhotos', ['loadPhotos', 'attemptDestroyPhoto']),
    async destroyPhoto({photo}) {
      this.processing = true
      await this.attemptDestroyPhoto({photo})
      this.confirmingDestroy = {}
      this.processing = false
    }
  }
}
</script>
<style scoped lang="sass">
</style>
