'use strict'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    featuredWineries: [],
    wineries: [],
    filters: {}
  },
  getters: {
    featuredWineries(state) { return state.featuredWineries },
    wineries(state) { return state.wineries },
    filters(state) { return state.filters }
  },
  mutations: {
    setFeaturedWineries(state, {featuredWineries}) {
      state.featuredWineries = [...featuredWineries]
    },
    setFilters(state, filters) {
      state.filters = {...state.filters, ...filters}
    },
    setWineries(state, {wineries}) {
      state.wineries = [...wineries]
    }
  },
  actions: {
    setFilters({commit, dispatch}, filters) {
      commit('setFilters', filters)
      dispatch('loadWineries')
    },
    async loadFeaturedWineries({commit}) {
      const featuredWineries = (await api.Establishments.index({params: {
        filter: {
          type: 'Winery',
          partnered: true
        },
      }})).data.data
      commit('setFeaturedWineries', {featuredWineries})
    },
    async loadWineries({commit, getters}) {
      if (getters.filters.search) {
        const wineries = (await api.Establishments.index({params: {filter: {type: 'Winery', search: getters.filters.search}}})).data.data
        commit('setWineries', {wineries})
      }
    }
  }
}
