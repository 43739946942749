'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    wine: {}
  },
  getters: {
    wine(state) { return state.wine }
  },
  mutations: {
    initializeWine(state) {
      state.wine = {
        active: true
      }
    },
    setWine(state, {wine}) {
      state.wine = {...state.wine, ...wine}
    }
  },
  actions: {
    initializeWine({commit}) {
      commit('initializeWine')
    },
    setWine({commit}, {wine}) {
      commit('setWine', {wine})
    },
    async attemptSaveWine({getters}) {
      try {
        await api.Operators.Wines.create({params: {wine: getters.wine}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
