'use strict'

import Deals                         from './Admins/Deals'
import Deal                          from './Admins/Deal'
import DealEdit                      from './Admins/DealEdit'
import DealNew                       from './Admins/DealNew'
import Users                         from './Admins/Users'
import User                          from './Admins/User'
import UserEdit                      from './Admins/UserEdit'
import UserNew                       from './Admins/UserNew'
import Establishments                from './Admins/Establishments'
import Establishment                 from './Admins/Establishment'
import EstablishmentEdit             from './Admins/EstablishmentEdit'
import EstablishmentNew              from './Admins/EstablishmentNew'
import EstablishmentOperators        from './Admins/EstablishmentOperators'
import EstablishmentOperatorsNew     from './Admins/EstablishmentOperatorsNew'
import EstablishmentPhotos           from './Admins/EstablishmentPhotos'
import EstablishmentPhotosNew        from './Admins/EstablishmentPhotosNew'
import ReportAppDownloads            from './Admins/ReportAppDownloads'
import ReportEstablishmentMonthRecap from './Admins/ReportEstablishmentMonthRecap'
import Spotlights                    from './Admins/Spotlights'
import SpotlightEdit                 from './Admins/SpotlightEdit'
import SpotlightNew                  from './Admins/SpotlightNew'

export default {
  namespaced: true,
  modules: {
    Deals,
    Deal,
    DealEdit,
    DealNew,
    Users,
    User,
    UserEdit,
    UserNew,
    Establishments,
    Establishment,
    EstablishmentEdit,
    EstablishmentNew,
    EstablishmentOperators,
    EstablishmentOperatorsNew,
    EstablishmentPhotos,
    EstablishmentPhotosNew,
    ReportAppDownloads,
    ReportEstablishmentMonthRecap,
    Spotlights,
    SpotlightEdit,
    SpotlightNew
  }
}
