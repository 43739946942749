<template>
  <span>
    <input
        type="file"
        :id="elId"
        accept="image/*"
        style="width: 0; height: 0;"
        @change="imagePicked">
    <label :for="elId" class="v-btn primary ma-0">Image</label>
  </span>
</template>
<script>
export default {
  name: 'OperatorsImageUpload',
  props: {
    value: null
  },
  data() {
    return {
      elId: Math.random().toString(36).substring(7)
    }
  },
  methods: {
    imagePicked(e) {
      const files = e.target.files
      if(files[0] !== undefined) {
        const name = files[0].name
        if(name.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader ()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          let url = fr.result
          // Also emit event
          this.$emit('input', url)
        })
      }
    }
  }
}
</script>
