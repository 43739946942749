'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    spotlights: []
  },
  getters: {
    spotlights(state) { return state.spotlights }
  },
  mutations: {
    setSpotlights(state, {spotlights}) {
      state.spotlights = [...spotlights]
    }
  },
  actions: {
    async loadSpotlights({commit}) {
      const spotlights = (await api.Admins.Spotlights.index()).data.data
      commit('setSpotlights', {spotlights})
    }
  }
}
