'use strict'

import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    filters: [],
    showFilters: false
  },
  mutations: {
    toggleFilter(state, {filter}) {
      state.filters = _.xor(state.filters, [filter])
    },
    toggleShowFilters(state, {open}) {
      state.showFilters = open
    }
  },
  getters: {
    filters(state) { return state.filters },
    showFilters(state) { return state.showFilters }
  },
  actions: {
    toggleFilter({commit}, {filter}) {
      commit('toggleFilter', {filter})
    },
    toggleShowFilters({commit, dispatch}, {open}) {
      commit('toggleShowFilters', {open})
      dispatch('Map/toggleMapOpen', true, {root: true})
    }
  }
}
