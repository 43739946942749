'use strict'

import store              from './store'

export default async () => {
  await store.dispatch('AppInfo/initialize')
  await store.dispatch('Login/initializeCurrentUser')
  // Don't need to wait for this to start the app
  store.dispatch('EventReminders/loadEventReminders')
  store.dispatch('Events/loadEvents')
  store.dispatch('Acknowledgements/initialize')
    .then(() => {
      store.dispatch('CaptureUser/initialize')
    })
}
