<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Valley Guide'}}" :footer="{enabled: false}">
    <div class="px-3" style="height: calc(var(--viewport-height) - var(--header-height)); display: flex; flex-direction: column; justify-content: space-between;">
      <div class="mt-2">
        <v-card class="mb-2">
          <v-card-title class="blue white--text py-2 headline">Hi, I'm the Valley Guide, ready to answer your questions about Temecula Valley Wine Country.</v-card-title>
        </v-card>
        <v-card class="mb-2" v-if="lastInput">
          <v-card-title class="orange white--text py-2 headline">Question: {{ this.lastInput }}</v-card-title>
        </v-card>
        <v-card v-if="loading">
          <v-card-title class="blue white--text py-2 headline">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card-title>
        </v-card>
        <div v-if="response">
          <v-card>
            <v-card-title class="blue white--text py-2 headline">
              <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
              <span v-else>
                {{ response.data.response }}
              </span>
            </v-card-title>
          </v-card>
          <v-switch v-model="debug" label="Info" />
          <v-card v-if="debug" class="v-card-normal mb-2">
            <v-card-title class="black white--text pa-2 headline">
              Info
            </v-card-title>
            <v-card-text class="px-2">
              <div>
                {{ response.data.meta.costString }}
              </div>
              <v-card class="v-card-normal">
                <v-card-title class="black white--text pa-2">
                  Details
                </v-card-title>
                <v-card-text class="px-1">
                  <div v-for="(message, i) in response.data.meta.messages" :key="i" class="mb-1">
                    <v-chip small>{{ message.role }}</v-chip> {{ message.content }}
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div>
        <v-textarea label="Question" outline v-model="input" @keydown.enter="e => {(e.metaKey || e.ctrlKey) ? submit() : null}">
          <template #append>
            <v-btn @click="submit" icon class="mr-0">
              <v-icon>fas fa-arrow-circle-right</v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </div>
    </div>
  </page>
</template>

<style scoped lang="sass">
div ::v-deep .v-input__append-inner
  align-self: center !important
</style>

<script>
import api from '../../lib/api'

export default {
  name: 'AdminsValleyGuide',
  data() {
    return {
      loading: false,
      lastInput: '',
      input: '',
      debug: false,
      pretext: false,
      context: false,
      response: null,
    }
  },
  methods: {
    async submit() {
      this.loading = true
      this.lastInput = this.input
      this.input = ''
      this.response = null
      const response = await api.Admins.ValleyGuideCompletion.create({params: {q: this.lastInput, debug: 1}})
      this.loading = false
      this.response = response.data
    },
  },
}
</script>
