'use strict'

import _              from 'lodash'

import api            from '../../api'

import Filters        from './Map/Filters'

export default {
  namespaced: true,
  modules: {
    Filters
  },
  state: {
    map: {
      options: {
        styles:            require('../../map-style').default,
        gestureHandling:   'none',
        panControl:        false,
        mapTypeControl:    false,
        streetViewControl: false,
        zoomControl:       false,
        scaleControl:      false,
        fullscreenControl: false
      },
      youAreHere: null,
      center: {
        lat: 33.505555,
        lng: -117.060133
      },
      zoom: 13,
      mapTypeId: 'roadmap'
    },
    establishments: [],
    focusedEstablishment: null
  },
  getters: {
    mapOpen(state) { return state.map.options.gestureHandling === 'greedy' },
    map(state) { return state.map },
    establishments(state, getters, rootState, rootGetters) {
      return _.filter(
        state.establishments,
        establishment => {
          return (
            rootGetters['Map/Filters/filters'].length === 0 ||
            rootGetters['Map/Filters/filters'].every(filter => establishment.attributes.amenities.includes(filter))
          )
        }
      )
    },
    allEstablishments(state) {
      return state.establishments
    },
    focusedEstablishment(state) { return state.focusedEstablishment }
  },
  mutations: {
    mergeMapOptions(state, newOptions) {
      state.map = {
        ...state.map,
        options: {
          ...state.map.options,
          ...newOptions
        }
      }
    },
    setYouAreHere(state, {youAreHere}) {
      state.map = {
        ...state.map,
        youAreHere
      }
    },
    setCenterZoom(state, {center, zoom}) {
      state.map = {
        ...state.map,
        center,
        zoom
      }
    },
    setEstablishments(state, {establishments}) {
      state.establishments = [...establishments]
    },
    setFocusedEstablishment(state, {establishment}) {
      state.focusedEstablishment = establishment ? {...establishment} : null
    }
  },
  actions: {
    toggleMapOpen({commit, getters}, open) {
      const newOpen = open === undefined ? !getters.mapOpen : open
      commit('mergeMapOptions', {gestureHandling: newOpen ? 'greedy' : 'none'})
    },
    async setCenterZoom({commit}, {mapPromise}) {
      const map = await mapPromise
      commit('setCenterZoom', {center: {lat: map.center.lat(), lng: map.center.lng()}, zoom: map.zoom})
    },
    async loadEstablishments({commit}) {
      const establishments = (await api.Establishments.index({params: {filter: {type: 'Winery'}}})).data.data
      commit('setEstablishments', {establishments})
    },
    async attemptUpdateYouAreHere({commit}) {
      try {
        const {lat, lng} = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            position => {
              resolve({lat: position.coords.latitude, lng: position.coords.longitude})
            },
            reject,
            {timeout: 20000}
          )
        })
        commit('setYouAreHere', {youAreHere: {lat, lng}})
      // eslint-disable-next-line
      } catch(e) { }
    },
    setFocusedEstablishment({commit}, {establishment}) {
      commit('setFocusedEstablishment', {establishment})
    }
  }
}
