'use strict'

import _      from 'lodash'
import moment from 'moment'

import api    from '../../api'

export default {
  namespaced: true,
  state: {
    deal: null
  },
  getters: {
    deal(state) { return state.deal },
    dealUsed(state) { return _.get(state.deal, 'attributes.consumable') && !!_.get(state.deal, 'relationships.dealUse') },
    dealUsedOn(state) {
      const dealUse = _.get(state.deal, 'relationships.dealUse')
      return dealUse ? moment(dealUse).format('YYYY-MM-DD') : null
    }
  },
  mutations: {
    setDeal(state, {deal}) {
      state.deal = {...deal}
    }
  },
  actions: {
    async loadDeal({commit}, {id}) {
      const deal = (await api.Deals.show(id)).data.data
      commit('setDeal', {deal})
    }
  }
}
