'use strict'

import Vue  from 'vue'
// eslint-disable-next-line
import Vuex from 'vuex'

import './lib/setup'

import router               from './lib/router'
import store                from './lib/store'
import App                  from './components/App.vue'
import storeInitialization  from './lib/store-initialization'
import windowOpenInitialize from './lib/window-open-initialize'

import platform            from 'platform'

window.app = null
const start = async () => {
  // Initialize the store
  await storeInitialization()
  // Initialize the window.open InAppBrowser override
  await windowOpenInitialize()

  window.app = new Vue({
    el:      '#app',
    store,
    router,
    data() {
      return {
        scrolled: false
      }
    },
    created() {
      // Hide splashscreen when app loads
      setTimeout(() => {
        if (navigator.splashscreen) navigator.splashscreen.hide()
      }, 500)
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    computed: {
      ...Vuex.mapGetters('AppInfo', ['appInfo']),
      reasonableHeaderHeight() {
        return store.getters['AppInfo/appInfo'].reasonableHeaderHeight
      },
      platform() {
        return platform
      }
    },
    methods: {
      handleScroll() {
        this.scrolled = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) > 200
      }
    },
    render: h => h(App)
  })
}
window.addEventListener('load', start, false)

// This fires when mobile app is loaded
window.handleOpenURL = url => {
  setTimeout(function(){
    window.app.$router.push(url.split('#')[1])
  }, 100)
}
