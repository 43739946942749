'use strict'

import Vue  from 'vue'
// eslint-disable-next-line
import Vuex from 'vuex'

import util from './util'

// Setup FastClick
document.addEventListener('DOMContentLoaded', function() {
  // eslint-disable-next-line
  FastClick.attach(document.body)
}, false)

// Setup store before routes so the store can be used
// eslint-disable-next-line
import store            from './store'

// Setup axios paramsSerializer
import axios from 'axios'
import qs    from 'qs'
axios.defaults.paramsSerializer = params => qs.stringify(params, {indices: false})

// Setup localforage
import localforage from 'localforage'
localforage.config({
  driver:     localforage.LOCALSTORAGE
})

// Setup vue2-touch-events
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

// Router
import router from './router'

// Setup analytics
import VueGtag from 'vue-gtag'
Vue.use(VueGtag, {
  config: {
    id: store.getters['AppInfo/appInfo'].googleTagID,
  },
}, router)

// Vuetify
import Vuetify from 'vuetify'
Vue.use(Vuetify)
import 'vuetify/dist/vuetify.min.css'
import '../../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'
import '../../node_modules/@fortawesome/fontawesome-free/css/all.css'

// Setup google maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAjNQ92WxP_GMEnlbmGHEhSgAOWonikGeE',
    libraries: 'drawing'
  }
})

// Setup moment
import moment from 'moment-timezone'
moment.tz.setDefault('America/Los_Angeles')
moment.updateLocale('en', {
  relativeTime: {
    s:  'secs',
    ss: '%ds',
    m:  '%dm',
    mm: '%dm',
    h:  '%dh',
    hh: '%dh',
    d:  '%dd',
    dd: '%dd',
    M:  '%dM',
    MM: '%dM',
    y:  '%dy',
    yy: '%dy'
  }
})

// Register global components
import Page from '../components/Page.vue'
Vue.component('page', Page)
import HeaderNavigation from '../components/HeaderNavigation.vue'
Vue.component('header-navigation', HeaderNavigation)
import FooterNavigation from '../components/FooterNavigation.vue'
Vue.component('footer-navigation', FooterNavigation)
import Lean from '../components/Lean.vue'
Vue.component('lean', Lean)
import SanitizedHtml from '../components/SanitizedHtml.vue'
Vue.component('sanitized-html', SanitizedHtml)
import OutboundLink from '../components/OutboundLink.vue'
Vue.component('outbound-link', OutboundLink)

// Register global filters
Vue.filter('dbDateFormatted', date => {
  return util.dbDateFormatted({date})
})
Vue.filter('dbDateTimeFormatted', date => {
  return util.dbDateTimeFormatted({date})
})
Vue.filter('establishmentOpen', establishment => {
  return util.establishmentOpen({establishment})
})
Vue.filter('establishmentOpenSoon', establishment => {
  return util.establishmentOpenSoon({establishment})
})
Vue.filter('spotlightTimeFormatted', (spotlight, options) => {
  return util.spotlightTimeFormatted({spotlight, options})
})
Vue.filter('eventTimeFormatted', (event, options) => {
  return util.eventTimeFormatted({event, options})
})
Vue.filter('hoursInfo', establishment => {
  return util.hoursInfo({establishment})
})
Vue.filter('stringToFloat', str => {
  return Number(str)
})

// Import global styles
import '../stylesheets/main.sass'

Vue.config.productionTip = false
