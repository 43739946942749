<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Businesses'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-establishment-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <list-of-establishments :establishments="establishments"></list-of-establishments>
  </page>
</template>
<script>
import Vuex from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'AdminsEstablishments',
  components: {
    ListOfEstablishments
  },
  created() {
    this.loadEstablishments()
  },
  computed: {
    ...Vuex.mapGetters('Admins/Establishments', ['establishments'])
  },
  methods: {
    ...Vuex.mapActions('Admins/Establishments', ['loadEstablishments'])
  }
}
</script>
<style scoped lang="sass">
</style>
