'use strict'

import api    from '../../../api'

export default {
  namespaced: true,
  state: {
    dealUse: null
  },
  getters: {
    dealUse(state) { return state.dealUse }
  },
  mutations: {
    setDealUse(state, {dealUse}) {
      state.dealUse = dealUse
    }
  },
  actions: {
    async attemptCreateDealUse({commit}, {dealId}) {
      commit('setDealUse', {dealUse: null})
      const dealUse = (await api.Users.DealUses.create({params: {dealUse: {dealId}}})).data.data
      commit('setDealUse', {dealUse})
    }
  }
}
