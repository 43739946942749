'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    deal: null,
    establishments: []
  },
  getters: {
    deal(state) { return state.deal },
    establishments(state) { return state.establishments }
  },
  mutations: {
    initializeDeal(state) {
      state.deal = {
        active:     true,
        consumable: true
      }
    },
    setEstablishments(state, {establishments}) {
      state.establishments = [...establishments]
    },
    setDeal(state, {deal}) {
      state.deal = {...state.deal, ...deal}
    }
  },
  actions: {
    initializeDeal({commit}) {
      commit('initializeDeal')
    },
    async searchForEstablishments({commit}, {search}) {
      const establishments = (await api.Admins.Establishments.index({params: {filter: {search}}})).data.data
      commit('setEstablishments', {establishments})
    },
    setDeal({commit}, {deal}) {
      commit('setDeal', {deal})
    },
    async attemptSaveDeal({getters}) {
      try {
        await api.Admins.Deals.create({params: {deal: getters.deal}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
