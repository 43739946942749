<template>
  <lean :left-to="{name: 'experiences'}">
    <page :header="{enabled: false}" :footer="{enabled: false}">
      <v-layout
          column
          class="full pa-3">
        <router-link :to="{name: 'experiences'}" class="pa-2">
          <v-icon>icn icn-close-wht</v-icon>
        </router-link>
        <div v-if="deal">
          <v-card>
            <v-img :src="deal.relationships.establishment.attributes.headerImageFileNameUrl" aspect-ratio="2"></v-img>
            <v-card-title>
              <div>
                <div class="headline">{{deal.attributes.name}}</div>
                <div>{{deal.attributes.description}}</div>
              </div>
            </v-card-title>
          </v-card>
          <v-layout justify-center>
            <v-dialog
                v-model="confirmingUse"
                :disabled="dealUsed || !activeMembership"
                >
              <v-layout
                  column
                  align-center
                  slot="activator"
                  class="my-4"
                  >
                <v-btn
                    class="px-4 white font-weight-bold"
                    outline
                    dark
                    round
                    :disabled="dealUsed || (!activeMembership && futureMembership)"
                    >
                  Redeem this Offer
                </v-btn>
                <div
                    v-if="dealUsed"
                    class="body-2 white--text"
                    >
                  Deal used on: {{dealUsedOn}}
                </div>
                <div
                    v-else-if="!activeMembership && futureMembership"
                    class="body-2 white--text"
                    >
                  Your VIP Pass starts: {{futureMembershipStart}}
                </div>
              </v-layout>
              <v-card>
                <v-card-title>
                  <v-layout column align-center>
                    <div class="headline">Are you sure?</div>
                    <div class="subheading" v-if="deal.attributes.consumable">This can only be done once.</div>
                  </v-layout>
                </v-card-title>
                
                <v-card-actions class="justify-center pb-3">
                  <v-btn
                      color="error"
                      :disabled="processing"
                      :loading="processing"
                      @click="$router.push({name: 'vip-deal-use', params: {id: deal.attributes.id}})">
                    Yes
                  </v-btn>
                  <v-btn
                      :disabled="processing"
                      :loading="processing"
                      @click="confirmingUse = false">
                    No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </div>
      </v-layout>
    </page>
  </lean>
</template>
<script>
import Vuex      from 'vuex'
export default {
  name: 'VipDeal',
  data() {
    return {
      confirmingUse: false,
      processing: false
    }
  },
  created() {
    this.loadDeal({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Login', ['activeMembership', 'futureMembership', 'futureMembershipStart']),
    ...Vuex.mapGetters('Deal', ['deal', 'dealUsed', 'dealUsedOn'])
  },
  methods: {
    ...Vuex.mapActions('Deal', ['loadDeal'])
  }
}
</script>
<style scoped lang="sass">
.full
  min-height: var(--viewport-height)
  background-color: var(--deep-red)
  background-image: url('../../assets/map-background.svg')
  background-size: cover
  width: 100%
  padding-top: env(safe-area-inset-top) !important
</style>
