<template>
  <page :header="{enabled: true, back: {name: 'admins-user', params: {id: $route.params.id}}, center: {enabled: true, text: 'Payments'}}" :footer="{enabled: false}">
    <v-list>
      <v-list-tile
          v-for="payment in payments"
          :key="payment.attributes.id"
          :href="stripeLink({payment})"
          target="_blank"
          >
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">{{payment.attributes.description}}</v-list-tile-title>
          <v-list-tile-sub-title>
            {{payment.attributes.createdAt | dbDateTimeFormatted}}
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex from 'vuex'
export default {
  name: 'AdminsUsersPayments',
  created() {
    this.loadPayments({userId: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Admins/Users/Payments', ['payments']),
  },
  methods: {
    ...Vuex.mapActions('Admins/Users/Payments', ['loadPayments']),
    stripeLink({payment}) {
      return payment.attributes.stripeSubscriptionId ?
        `https://dashboard.stripe.com/subscriptions/${payment.attributes.stripeSubscriptionId}` :
        `https://dashboard.stripe.com/payments/${payment.attributes.stripeChargeId}`
    }
  },
}
</script>
<style scoped lang="sass">
</style>
