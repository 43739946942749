'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    establishment: null
  },
  getters: {
    establishment(state) { return state.establishment }
  },
  mutations: {
    setEstablishment(state, {establishment}) {
      state.establishment = establishment
    }
  },
  actions: {
    async loadEstablishment({commit}, {slug}) {
      const establishment = (await api.Operators.Establishments.show(slug)).data.data
      commit('setEstablishment', {establishment})
    }
  }
}
