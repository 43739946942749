'use strict'

export default {
  namespaced: true,
  state: {
    config: {}
  },
  mutations: {
    updateConfig(state, config) {
      state.config = {...config}
    }
  },
  getters: {
    config(state) { return state.config }
  },
  actions: {
    updateConfig({commit}, config) {
      commit('updateConfig', {
        enabled:               true,
        back:                  false,
        backWhite:             false,
        // Fix to top
        fixed:                 true,
        // Overlap content
        overlap:               false,
        // Remove safe area padding
        noPadding:             false,
        transparentBackground: false,
        center:                {
          enabled: false,
          to:      null,
          text:    null
        },
        rightHelpLinkEnabled:  false,
        hideCenter:            false,
        ...config
      })
    }
  }
}
