<template>
  <page :header="{enabled: false}" :footer="{enabled: false}">
    <v-layout
        column
        class="full pa-3">
      <router-link :to="{name: 'experiences'}" class="pa-2">
        <v-icon>icn icn-close-wht</v-icon>
      </router-link>
      <v-card class="pa-3">
        <v-layout column>
          <v-icon class="mb-3 icn-size icn-size-4">icn icn-deals-red</v-icon>
          <div class="headline text-xs-center mb-3">Join the VIP Lounge and get over $500 worth of deals, discounts, & coupons.</div>
          <div class="subheading mb-3">Get your digital pass to dozens of local wine discounts, deals, and gifts.</div>
          <div class="mb-3">
            <div class="font-weight-bold subheading">Local Residents</div>
            1) $5.99 monthly membership<br />
            2) $59.90 year (2 months free)
          </div>
          <div class="mb-3">
            <div class="font-weight-bold subheading">Out-of-towners</div>
            3) $24.99 4-day pass (purchase in advance)
          </div>
          <v-btn :to="{name: 'register-information'}" class="deep-red px-3" dark round>Become a VIP</v-btn>
        </v-layout>
      </v-card>
    </v-layout>
  </page>
</template>
<script>
export default {
  name: 'UsersRegister',
}
</script>
<style scoped lang="sass">
.full
  min-height: var(--viewport-height)
  background-color: var(--deep-red)
  background-image: url('../../assets/map-background.svg')
  background-size: cover
  width: 100%
  padding-top: env(safe-area-inset-top) !important
</style>
