'use strict'

let stripe = null

export default () => {
  if (!stripe && typeof(window.Stripe) !== 'undefined') {
    stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY)
  }
  return stripe
}
