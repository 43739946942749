<template>
  <page :pad-safely="true" :header="{enabled: true, back: {name: 'experiences'}}" :footer="{enabled: false}">
    <div class="headline text-xs-center">Your Information</div>
    <v-form class="pa-3" v-model="valid">
      <v-alert
          :value="true"
          v-if="errorMessage"
          type="error"
          >
        {{errorMessage}}
      </v-alert>
      <v-text-field v-model="computedFirstName" :rules="FirstNameRules" label="First Name"></v-text-field>
      <v-text-field v-model="computedLastName" :rules="LastNameRules" label="Last Name"></v-text-field>
      <v-text-field v-model="computedEmail" type="email" :rules="EmailRules" label="Email Address"></v-text-field>
      <v-text-field v-model="computedPassword" type="password" label="Password" :rules="PasswordRules"></v-text-field>
      <v-text-field v-model="computedZip" :rules="ZipRules" label="Zip Code"></v-text-field>
      <div class="text-xs-center">
        <v-btn
            color="deep-red"
            class="px-3"
            :dark="valid && !processing"
            round
            :disabled="!valid || processing"
            @click="register"
            >
          On to the last step...
        </v-btn>
      </div>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import rules      from '../../lib/rules'
export default {
  name: 'UsersRegisterInformation',
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.replace({name: 'register-pay'})
    }
  },
  computed: {
    ...Vuex.mapGetters('Users/Registration', ['registration', 'errorMessage']),
    ...rules.Registration,
    ...Vuex.mapGetters('Login', ['loggedIn']),
    computedFirstName: {
      get() { return this.registration.firstName },
      set(v) { this.setRegistration({registration: {firstName: v}}) }
    },
    computedLastName: {
      get() { return this.registration.lastName },
      set(v) { this.setRegistration({registration: {lastName: v}}) }
    },
    computedEmail: {
      get() { return this.registration.email },
      set(v) { this.setRegistration({registration: {email: v}}) }
    },
    computedPassword: {
      get() { return this.registration.password },
      set(v) { this.setRegistration({registration: {password: v}}) }
    },
    computedZip: {
      get() { return this.registration.zip },
      set(v) { this.setRegistration({registration: {zip: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Users/Registration', ['initializeRegistration', 'setRegistration', 'attemptRegister']),
    async register() {
      this.processing = true
      if (await this.attemptRegister()) {
        this.initializeRegistration()
        this.$router.push({name: 'register-pay'})
      }
      this.processing = false
    }
  }
}
</script>
<style scoped lang="sass">
</style>
