<template>
  <page :header="{enabled: false}">
    <v-img :src="require('../assets/partners.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
      <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top">
        <v-layout column fill-height justify-center align-center class="pa-2 white--text">
          <v-flex shrink class="headline mb-3">Partners</v-flex>
          <v-icon color="white" class="icn-size icn-size-5">icn icn-partners-wht</v-icon>
        </v-layout>
      </v-img>
    </v-img>
    <v-btn
        fab
        :to="{name: 'menu'}"
        color="white"
        class="menu-button grey--text elevation-3 ml-3 mt-3">
      <v-icon class="mr-1 ml-2">icn icn-menu-gry</v-icon>
    </v-btn>
    <v-layout row class="mx-3 mt-3">
      <v-btn
          round
          :color="tag === activeTag ? 'deep-red' : 'gray'"
          :outline="tag !== activeTag"
          :class="{'white--text': tag === activeTag}"
          class="px-3"
          style="height: 2.5rem; min-width: auto"
          v-for="tag in tags"
          :key="tag"
          @click.prevent="setActiveTag({activeTag: tag})">
        {{tag}}
      </v-btn>
    </v-layout>
    <list-of-establishments :establishments="shownPartners" class="px-2 pt-3 pb-6"></list-of-establishments>
  </page>
</template>
<script>
import Vuex                 from 'vuex'
import ListOfEstablishments from './ListOfEstablishments.vue'
export default {
  name: 'Partners',
  components: {
    ListOfEstablishments
  },
  async created() {
    await this.loadPartners()
    if (this.$route.query.tag && this.tags.includes(this.$route.query.tag)) {
      this.setActiveTag({activeTag: this.$route.query.tag})
      this.$router.replace({name: 'partners'})
    }
  },
  computed: {
    ...Vuex.mapGetters('Partners', ['partners', 'tags', 'activeTag', 'shownPartners']),
  },
  methods: {
    ...Vuex.mapActions('Partners', ['loadPartners', 'setActiveTag'])
  }
}
</script>
<style scoped lang="sass">
.menu-button
  position: absolute !important
  left: 0rem
  top: env(safe-area-inset-top)
  height: 3.2rem
  width: 3.2rem
  z-index: 10
</style>
