<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Spotlights'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-spotlight-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <div
        v-for="group in [{name: 'Upcoming', key: 'activeFuture'}, {name: 'Ongoing', key: 'activeOngoing'}, {name: 'Past', key: 'activePast'}, {name: 'Inactive', key: 'inactive'}]"
        :key="group.name"
        >
      <v-list v-if="groupedSpotlights[group.key].length">
        <v-subheader>{{group.name}}</v-subheader>
        <v-list-tile
            v-for="spotlight in groupedSpotlights[group.key]"
            :key="spotlight.attributes.id"
            :to="{name: 'admins-spotlight-edit', params: {id: spotlight.attributes.id}}"
            >
          <v-sheet
              color="grey"
              min-width="3rem"
              width="3rem"
              height="2rem"
              class="mr-3 handle">
            <v-img
                v-if="spotlight.attributes.headerImageFileNameUrl"
                min-width="3rem"
                width="3rem"
                height="2rem"
                :src="spotlight.attributes.headerImageFileNameUrl">
            </v-img>
          </v-sheet>
          <v-list-tile-content>
            <v-list-tile-title v-text="spotlight.attributes.name"></v-list-tile-title>
            <v-list-tile-sub-title>
              <v-chip color="light-gray" text-color="white" small>
                {{spotlight.attributes.placement}}
              </v-chip>
            </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-list-tile>
      </v-list>
    </div>
  </page>
</template>
<script>
import Vuex from 'vuex'
import _ from 'lodash'
import moment from 'moment'
export default {
  name: 'AdminsSpotlights',
  created() {
    this.loadSpotlights()
  },
  computed: {
    ...Vuex.mapGetters('Admins/Spotlights', ['spotlights']),
    groupedSpotlights() {
      const sortedSpotlights = _.sortBy(this.spotlights, ['attributes.showAt'])
      let groupedSpotlights = {activeOngoing: [], activeFuture: [], activePast: [], inactive: []}
      for (let s of sortedSpotlights) {
        let key = ''
        if (!s.attributes.active) {
          key = 'inactive'
        } else if (s.attributes.showAt && moment(s.attributes.showAt).isAfter(moment())) {
          key = 'activeFuture'
        } else if (!s.attributes.showAt || !s.attributes.showAtEnd || (s.attributes.showAtEnd && moment(s.attributes.showAtEnd).isAfter(moment()))) {
          key = 'activeOngoing'
        } else {
          key = 'activePast'
        }
        groupedSpotlights[key].push(s)
      }
      return groupedSpotlights
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/Spotlights', ['loadSpotlights'])
  }
}
</script>
<style scoped lang="sass">
</style>
