<template>
  <v-layout
      column
      justify-end
      :style="{height: remainingHeight}"
      id="map-wrapper">
    <GmapMap
        :options="{styles: require('../../lib/map-style').default, draggable: false, panControl: false, mapTypeControl: false, streetViewControl: false, zoomControl: false, fullscreenControl: false}"
        :center="{lat: $options.filters.stringToFloat(winery.attributes.lat), lng: $options.filters.stringToFloat(winery.attributes.lng)}"
        :zoom="16"
        mapTypeId="roadmap"
        @click="toggleMapOpen(true)"
        :style="{height: remainingHeight}"
        class="map">
      <GmapMarker
          key="you-are-here"
          :position="{lat: $options.filters.stringToFloat(winery.attributes.lat), lng: $options.filters.stringToFloat(winery.attributes.lng)}"
          :z-index="2"
          :options="{icon: {scaledSize: {height: 26, width: 26}, url: require('../../assets/user-location.svg')}}">
      </GmapMarker>
    </GmapMap>
    <v-layout shrink justify-space-around class="mb-3 mx-3">
      <v-btn
          round
          @click.prevent="openMap"
          color="deep-red"
          class="white--text mx-2"
          v-if="winery.attributes.address"
          large
          block>
        Directions
      </v-btn>
      <v-btn
          round
          @click.prevent="callBusiness"
          color="deep-red"
          class="white--text mx-2"
          v-if="simplePhone"
          large
          block>
        Call
      </v-btn>
    </v-layout>
  </v-layout>
</template>
<script>
import api  from '../../lib/api'
import Vuex from 'vuex'
import _    from 'lodash'
export default {
  name: 'LocationTab',
  data() {
    return {
      remainingHeight: '20rem'
    }
  },
  mounted() {
    this.remainingHeight = `calc(var(--viewport-height) - ${document.querySelector('#map-wrapper').offsetTop}px)`
  },
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    simplePhone() {
      return _.get(this.winery, 'attributes.phone', '').replace(/[^\d]/g, '')
    }
  },
  methods: {
    openMap() {
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/directions`}}})
      this.$gtag.event(
        `Establishment - click_directions - ${this.winery.attributes.slug} - ${this.winery.attributes.name}`,
        {
          eventCategory: 'Establishment',
          eventAction: 'click_directions',
          eventLabel: this.winery.attributes.name,
          eventValue: this.winery.attributes.slug
        }
      );
      window.FirebasePlugin?.logEvent('click_directions', {
        content_type: 'establishment',
        event_label: this.winery.attributes.name,
        event_id: this.winery.attributes.slug,
      });
      if (this.$root.platform.os.family.match(/^iOS/)) {
        window.open(`maps:maps.apple.com/?q=${encodeURIComponent(this.winery.attributes.address)}`, '_system')
      } else {
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(this.winery.attributes.address)}`, '_system')
      }
    },
    callBusiness() {
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/call`}}})
      window.FirebasePlugin?.logEvent('click_call', {
        content_type: 'establishment',
        event_label: this.winery.attributes.name,
        event_id: this.winery.attributes.slug,
      });
      this.$gtag.event(
        `Establishment - click_call - ${this.winery.attributes.slug} - ${this.winery.attributes.name}`,
        {
          eventCategory: 'Establishment',
          eventAction: 'click_call',
          eventLabel: this.winery.attributes.name,
          eventValue: this.winery.attributes.slug
        }
      );
      window.open(`tel:${this.simplePhone}`, '_system')
    }
  }
}
</script>
<style scoped lang="sass">
#map-wrapper
  position: relative
  width: var(--viewport-width)
  min-height: 20rem
  .map
    position: absolute
    top: 0
    left: 0
    width: var(--viewport-width)
    min-height: 20rem
</style>
