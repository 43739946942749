'use strict'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    spotlight: null
  },
  getters: {
    spotlight(state) { return state.spotlight }
  },
  mutations: {
    setSpotlight(state, {spotlight}) {
      state.spotlight = spotlight ? {...spotlight} : null
    }
  },
  actions: {
    async loadSpotlight({commit}, {id}) {
      commit('setSpotlight', {spotlight: null})
      const spotlight = (await api.Spotlight.show(id)).data.data
      commit('setSpotlight', {spotlight: spotlight})
    }
  }
}
