'use strict'

import _ from 'lodash'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    partner: null,
    tab: 'Partner'
  },
  getters: {
    partner(state) { return state.partner },
    tab(state) { return state.tab }
  },
  mutations: {
    setPartner(state, partner) {
      state.partner = partner
    },
    setTab(state, {tab}) {
      state.tab = tab
    }
  },
  actions: {
    async loadPartner({commit, rootGetters}, {slug}) {
      commit('setPartner', null)
      let partner = null
      // For quick loading get the partner info from other states
      partner = partner || _.find(rootGetters['Partners/partners'], {attributes: {slug}}, null)
      if (partner) {
        commit('setPartner', partner)
      }
      // Get partner's specific info
      api.Establishments.show(slug)
          .then(({data: {data}}) => commit('setPartner', data))
    },
    setTab({commit}, {tab}) {
      commit('setTab', {tab})
    }
  }
}
