<template>
  <page :header="{enabled: true, back: {name: 'login'}, center: {enabled: true, text: 'Businesses'}}" :footer="{enabled: false}">
    <v-list>
      <v-list-tile :to="{name: 'admins-establishments'}">
        <v-list-tile-content>
          <v-list-tile-title>Businesses</v-list-tile-title>
          <v-list-tile-sub-title>List of all establishments</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-deals'}">
        <v-list-tile-content>
          <v-list-tile-title>Deals</v-list-tile-title>
          <v-list-tile-sub-title>List of all deals</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-spotlights'}">
        <v-list-tile-content>
          <v-list-tile-title>Spotlights</v-list-tile-title>
          <v-list-tile-sub-title>Ads shown in various places</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-users'}">
        <v-list-tile-content>
          <v-list-tile-title>Users</v-list-tile-title>
          <v-list-tile-sub-title>List of users</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-reports'}">
        <v-list-tile-content>
          <v-list-tile-title>Reports</v-list-tile-title>
          <v-list-tile-sub-title>List of available reports</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-valley-guide'}">
        <v-list-tile-content>
          <v-list-tile-title>Valley Guide</v-list-tile-title>
          <v-list-tile-sub-title>Test out the valley guide bot</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
export default {
  name: 'AdminsDashboard'
}
</script>
<style scoped lang="sass">
</style>
