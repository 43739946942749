'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) { return state.user },
  },
  mutations: {
    setUser(state, {user}) {
      state.user = user ? {...(state.user || {}), ...user} : user
    },
  },
  actions: {
    async loadUser({commit}, {id}) {
      commit('setUser', {user: null})
      const user = (await api.Admins.Users.show(id)).data.data
      commit('setUser', {user: user.attributes})
    },
    setUser({commit}, {user}) {
      commit('setUser', {user})
    },
    async attemptSaveUser({getters}) {
      try {
        await api.Admins.Users.update(getters.user.id, {params: {user: getters.user}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
