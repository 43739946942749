<template>
  <a @click="clickOutboundLink">{{url}}</a>
</template>
<script>
import api from '../lib/api'
export default {
  name: 'OutboundLink',
  props: {
    url: String,
    eventCategory: [String, Function],
    eventAction: [String, Function],
    eventLabel: [String, Function],
    eventValue: [String, Function]
  },
  methods: {
    clickOutboundLink() {
      const details = {
        eventCategory: typeof(this.eventCategory) === 'string' ? this.eventCategory : this.eventCategory({url: this.url}),
        eventAction: typeof(this.eventAction) === 'string' ? this.eventAction : this.eventAction({url: this.url}),
        eventLabel: typeof(this.eventLabel) === 'string' ? this.eventLabel : this.eventLabel({url: this.url}),
        eventValue: typeof(this.eventValue) === 'string' ? this.eventValue : this.eventValue({url: this.url})
      }
      api.UserHit.create({params: {userHit: {path: `${this.$route.path}/${details.eventCategory}/${details.eventAction}/${details.eventValue}`}}})
      this.$gtag.event(
        `${details.eventCategory} - ${details.eventAction} - ${details.eventValue} - ${details.eventValue}`,
        details,
      );
      window.FirebasePlugin?.logEvent(details.eventAction, {
        content_type: details.eventCategory,
        event_label: details.eventLabel,
        event_id: details.eventValue,
      });
      window.open(this.url, '_system')
    },
  }
}
</script>
