'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    events: []
  },
  getters: {
    events(state) { return state.events }
  },
  mutations: {
    setEvents(state, {events}) {
      state.events = [...events]
    }
  },
  actions: {
    async loadEvents({commit}, {slug}) {
      const events = (await api.Operators.Events.index({params: {filter: {establishmentId: slug}}})).data.data
      commit('setEvents', {events})
    },
    async resortActiveEvents({dispatch}, {events}) {
      if (events.length) {
        for (let index in events) {
          await api.Operators.Events.update(events[index].attributes.id, {params: {event: {sortOrder: index}}})
        }
        await dispatch('loadEvents', {slug: events[0].attributes.establishmentId})
      }
    }
  }
}
