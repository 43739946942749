<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Deals'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'admins-deal-new'})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <v-subheader>Active ({{activeDeals.length}})</v-subheader>
    <list-of-deals :deals="activeDeals"></list-of-deals>
    <v-subheader>Inactive ({{inactiveDeals.length}})</v-subheader>
    <list-of-deals :deals="inactiveDeals"></list-of-deals>
  </page>
</template>
<script>
import Vuex from 'vuex'
import ListOfDeals from './ListOfDeals.vue'
export default {
  name: 'AdminsDeals',
  components: {
    ListOfDeals
  },
  created() {
    this.loadDeals()
  },
  computed: {
    ...Vuex.mapGetters('Admins/Deals', ['deals']),
    activeDeals() {
      return this.deals.filter(deal => deal.attributes.active)
    },
    inactiveDeals() {
      return this.deals.filter(deal => !deal.attributes.active)
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/Deals', ['loadDeals'])
  }
}
</script>
<style scoped lang="sass">
</style>
