'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    deal: null,
    establishment: null,
    errors: [],
  },
  getters: {
    deal(state) { return state.deal },
    establishment(state) { return state.establishment },
    errors(state) { return state.errors }
  },
  mutations: {
    setDeal(state, {deal}) {
      state.deal = deal ? {...(state.deal || {}), ...deal} : deal
    },
    setEstablishment(state, {establishment}) {
      state.establishment = {...establishment}
    },
    setErrors(state, {errors}) {
      state.errors = [...errors]
    }
  },
  actions: {
    async loadDeal({commit}, {id}) {
      commit('setDeal', {deal: null})
      commit('setEstablishment', {establishment: null})
      commit('setErrors', {errors: []})
      const deal = (await api.Admins.Deals.show(id)).data.data
      commit('setDeal', {deal: deal.attributes})
      commit('setEstablishment', {establishment: deal.relationships.establishment})
    },
    setDeal({commit}, {deal}) {
      commit('setDeal', {deal})
    },
    async attemptSaveDeal({commit, getters}) {
      try {
        await api.Admins.Deals.update(getters.deal.id, {params: {deal: getters.deal}})
        return true
      } catch(e) {
        commit('setErrors', {errors: e.response.data.errors})
        return false
      }
    },
    async attemptDestroyDeal({getters}) {
      try {
        await api.Admins.Deals.destroy(getters.deal.id)
        return true
      } catch(e) {
        return false
      }
    }
  }
}
