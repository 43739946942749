<template>
  <v-list>
    <v-list-tile v-for="amenity in amenities" :key="amenity">
      <v-list-tile-avatar @click="toggleAmenity({amenity})">
        <v-icon size="2.5rem" :color="(establishment.amenities && establishment.amenities.includes(amenity)) ? 'green' : 'grey'">
          {{(establishment.amenities && establishment.amenities.includes(amenity)) ? 'fas fa-check-circle' : 'fas fa-times-circle'}}
        </v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        {{amenity}}
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>
<script>
import _          from 'lodash'
export default {
  name: 'OperatorEstablishmentAmenities',
  props: {
    storeModule: {
      type: null,
      required: true
    }
  },
  computed: {
    establishment() { return this.$store.getters[`${this.storeModule}/establishment`] },
    amenities() {
      return ['Open Late', 'Live Music', 'Picnic Area', 'Dog Friendly', 'Lodging', 'Wine Tours', 'Restaurant', 'Food Truck', 'Casual Eats', 'Beer on Tap', 'Sustainable', 'Weddings']
    }
  },
  methods: {
    setEstablishment({establishment}) { this.$store.dispatch(`${this.storeModule}/setEstablishment`, {establishment})},
    toggleAmenity({amenity}) {
      this.setEstablishment({
        establishment: {
          amenities: _.xor(this.establishment.amenities, [amenity])
        }
      })
    }
  }
}
</script>
