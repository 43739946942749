<template>
  <v-dialog v-model="modal">
    <v-text-field
        :label="label"
        :disabled="disabled"
        slot="activator"
        :value="formattedTime"
        prepend-icon="access_time"
        readonly>
    </v-text-field>
    <v-time-picker v-if="modal" v-model="computedTime" full-width>
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
      <v-btn flat color="primary" @click="ok">OK</v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  name: 'TimePicker',
  props: {
    disabled: Boolean,
    label: String,
    value: null
  },
  data() {
    return {
      modal: false,
      time: null
    }
  },
  created() {
    this.time = this.value
  },
  computed: {
    formattedTime() {
      return moment(this.time).format('h:mma')
    },
    computedTime: {
      get() { return moment(this.time).format('HH:mm') },
      set(v) { this.time = moment(moment(this.time).format('YYYY-MM-DD ') + v, 'YYYY-MM-DD HH:mm') }
    }
  },
  methods: {
    ok() {
      this.$emit('change', this.time)
      this.modal = false
    }
  },
  watch: {
    value(v) {
      this.time = v
    }
  }
}
</script>
