'use strict'

import _   from 'lodash'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    user(state) { return state.user }
  },
  mutations: {
    initializeUser(state) {
      state.user = {}
    },
    randomizePassword(state) {
      const pieces = _.shuffle(['Wine', 'Fun', '1', 'Drink', 'Laugh', 'Taste', 'Grapes', 'Glass'])
      state.user = {
        ...state.user,
        password: _.times(3, () => pieces.pop()).join('')
      }
    },
    setUser(state, {user}) {
      state.user = {...state.user, ...user}
    }
  },
  actions: {
    initializeUser({commit}) {
      commit('initializeUser')
      commit('randomizePassword')
    },
    randomizePassword({commit}) {
      commit('randomizePassword')
    },
    setUser({commit}, {user}) {
      commit('setUser', {user})
    },
    async attemptSaveUser({getters}) {
      try {
        await api.Admins.Users.create({params: {user: getters.user}})
        return true
      } catch(e) {
        return false
      }
    }
  }
}
