<template>
  <div>
    <v-layout v-for="([day, dayShort]) in daysOfWeek" :key="day" align-center>
      <v-flex xs2>
        <v-layout align-center>
          <v-flex><v-checkbox v-model="hoursEnabled[day]" @change="toggleEstablishmentHours({day})"></v-checkbox></v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs2>
        <v-layout align-center>
          <v-flex class="caption">{{dayShort}}</v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs10>
        <v-layout>
          <v-flex class="mr-1">
            <hours-time-picker label="Start" :disabled="!hoursEnabled[day]" :value="hoursEnabled[day] ? establishment.hours[day].startTime : null" @change="setEstablishmentHours({day, time: {startTime: $event}})"></hours-time-picker>
          </v-flex>
          <v-flex>
            <hours-time-picker label="End" :disabled="!hoursEnabled[day]" :value="hoursEnabled[day] ? establishment.hours[day].endTime : null" @change="setEstablishmentHours({day, time: {endTime: $event}})"></hours-time-picker>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import _               from 'lodash'
import HoursTimePicker from './HoursTimePicker.vue'
export default {
  name: 'OperatorsEstablishmentHours',
  components: {
    HoursTimePicker
  },
  props: {
    storeModule: {
      type: null,
      required: true
    }
  },
  computed: {
    daysOfWeek() {
      return [['Monday', 'Mon'], ['Tuesday', 'Tues'], ['Wednesday', 'Wed'], ['Thursday', 'Thu'], ['Friday', 'Fri'], ['Saturday', 'Sat'], ['Sunday', 'Sun']]
    },
    establishment() { return this.$store.getters[`${this.storeModule}/establishment`] },
    hoursEnabled() {
      return _.mapValues((_.get(this.establishment, 'hours', {}) || {}), v => !!v)
    }
  },
  methods: {
    setEstablishment({establishment}) { this.$store.dispatch(`${this.storeModule}/setEstablishment`, {establishment})},
    toggleEstablishmentHours({day}) {
      let hours = this.establishment.hours || {}
      hours = hours[day] ? _.omit(hours, [day]) : {...hours, [day]: {startTime: '12:00', endTime: '17:00'}}
      this.setEstablishment({establishment: {hours}})
    },
    setEstablishmentHours({day, time}) {
      this.setEstablishment({
        establishment: {
          hours: {
            ...this.establishment.hours,
            [day]: {
              ...this.establishment.hours[day],
              ...time
            }
          }
        }
      })
    }
  }
}
</script>
