<template>
  <page :header="{enabled: true, back: {name: 'admins-dashboard'}, center: {enabled: true, text: 'Reports'}}" :footer="{enabled: false}">
    <v-list>
      <v-list-tile :to="{name: 'admins-report-establishment-month-recap'}">
        <v-list-tile-content>
          <v-list-tile-title>Establishment Month Recap</v-list-tile-title>
          <v-list-tile-sub-title>Monthly report on an establishment</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
      <v-list-tile :to="{name: 'admins-report-app-downloads'}">
        <v-list-tile-content>
          <v-list-tile-title>App Downloads</v-list-tile-title>
          <v-list-tile-sub-title>Total app downloads per platform</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
export default {
  name: 'AdminsReports'
}
</script>
