<template>
  <page :header="{enabled: true, back: {name: 'operators-wines', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'New Wine'}}" :footer="{enabled: false}">
    <v-form v-model="valid" v-if="establishment" class="pa-3">
      <v-text-field v-model="computedName" :rules="NameRules" label="Name"></v-text-field>
      <v-textarea v-model="computedDescription" :rules="DescriptionRules" label="Description" counter="1000"></v-textarea>
      <v-radio-group v-model="computedTags" :rules="TagsRules" label="Wine Type" required>
        <v-radio
            v-for="tag in ['Red', 'White', 'Sparkling', 'Sweet', 'Rosé']"
            :key="tag"
            :label="tag"
            :value="tag">
        </v-radio>
      </v-radio-group>
      <v-checkbox v-model="computedCriticScoreEnabled" label="Critic Score?"></v-checkbox>
      <v-layout row v-if="computedCriticScoreEnabled" class="mt-0">
        <v-flex shrink>
          <v-text-field
              v-model="computedCriticScore"
              hide-details
              :rules="CriticScoreRules"
              readonly
              prepend-icon="icn icn-award"
              class="mr-2"
              style="width: 5rem"
              single-line>
          </v-text-field>
        </v-flex>
        <v-flex>
          <v-slider
              v-model="computedCriticScore"
              :max="100"
              :min="60">
          </v-slider>
        </v-flex>
      </v-layout>
      <v-checkbox v-model="computedActive" label="Active"></v-checkbox>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveWine"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import _          from 'lodash'
import rules      from '../../lib/rules'
export default {
  name: 'OperatorsWineNew',
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.initializeWine()
    this.setWine({wine: {establishmentId: this.$route.params.slug}})
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishment', ['establishment']),
    ...Vuex.mapGetters('Operators/WineNew', ['wine']),
    ...rules.Wine,
    computedName: {
      get() { return this.wine.name },
      set(v) { this.setWine({wine: {name: v}}) }
    },
    computedDescription: {
      get() { return this.wine.description },
      set(v) { this.setWine({wine: {description: v}}) }
    },
    computedActive: {
      get() { return this.wine.active },
      set(v) { this.setWine({wine: {active: v}}) }
    },
    computedTags: {
      get() { return _.get(this.wine, 'tags[0]', null)},
      set(v) { this.setWine({wine: {tags: [v]}}) }
    },
    computedCriticScoreEnabled: {
      get() { return !!this.wine.criticScore },
      set(v) { this.setWine({wine: {criticScore: v ? 85 : null}}) }
    },
    computedCriticScore: {
      get() { return this.wine.criticScore },
      set(v) { this.setWine({wine: {criticScore: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Operators/WineNew', ['initializeWine', 'setWine', 'attemptSaveWine']),
    async saveWine() {
      this.processing = true
      if (await this.attemptSaveWine()) {
        this.$router.push({name: 'operators-wines', params: {slug: this.$route.params.slug}})
      }
      this.processing = false
    }
  }
}
</script>
