<template>
  <page :header="{enabled: true, back: {name: 'operators-establishment', params: {slug: $route.params.slug}}, center: {enabled: true, text: 'Wine List'}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-btn dark fab small color="primary" @click.prevent="$router.push({name: 'operators-wine-new', params: {slug: $route.params.slug}})">
         <v-icon>add</v-icon>
      </v-btn>
    </v-layout>
    <v-list v-if="active.length">
      <v-subheader>Active</v-subheader>
      <draggable :value="active" @change="sortActive" handle=".handle">
        <v-list-tile @click.prevent="" v-for="wine in active" :key="wine.attributes.id">
          <v-list-tile-content>
            <v-list-tile-title v-text="wine.attributes.name" @click.prevent="$router.push({name: 'operators-wine-edit', params: {slug: $route.params.slug, id: wine.attributes.id}})"></v-list-tile-title>
          </v-list-tile-content>
          <v-icon>keyboard_arrow_right</v-icon>
        </v-list-tile>
      </draggable>
    </v-list>
    <v-list v-if="inactive.length">
      <v-subheader>Inactive</v-subheader>
      <v-list-tile @click.prevent="" v-for="wine in inactive" :key="wine.attributes.id">
        <v-list-tile-content>
          <v-list-tile-title v-text="wine.attributes.name" @click.prevent="$router.push({name: 'operators-wine-edit', params: {slug: $route.params.slug, id: wine.attributes.id}})"></v-list-tile-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </page>
</template>
<script>
import Vuex      from 'vuex'
import _         from 'lodash'
import Draggable from 'vuedraggable'
export default {
  name: 'OperatorsWines',
  components: {
    Draggable
  },
  created() {
    this.loadEstablishment({slug: this.$route.params.slug})
    this.loadWines({slug: this.$route.params.slug})
  },
  computed: {
    ...Vuex.mapGetters('Operators/Establishment', ['establishment']),
    ...Vuex.mapGetters('Operators/Wines', ['wines']),
    active() { return _.sortBy(this.wines.filter(b => b.attributes.active), 'attributes.sortOrder') },
    inactive() { return _.sortBy(this.wines.filter(b => !b.attributes.active), 'attributes.sortOrder') }
  },
  methods: {
    ...Vuex.mapActions('Operators/Establishment', ['loadEstablishment']),
    ...Vuex.mapActions('Operators/Wines', ['loadWines', 'resortActiveWines']),
    async sortActive(e) {
      let resortedWines = [...this.active]
      resortedWines.splice(e.moved.newIndex, 0, resortedWines.splice(e.moved.oldIndex, 1)[0])
      await this.resortActiveWines({wines: resortedWines})
    }
  }
}
</script>
<style scoped lang="sass">
</style>
