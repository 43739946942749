<template>
  <page :header="{enabled: true, back: {name: 'admins-user', params: {id: $route.params.id}}, center: {enabled: true, text: user ? `Edit ${user.email}` : ''}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3" v-if="user">
      <v-text-field v-model="user.id" label="ID" readonly></v-text-field>
      <v-text-field v-model="user.stripeCustomerId" label="Stripe Customer ID" readonly></v-text-field>
      <v-text-field v-model="user.stripeLast4" label="Stripe Card Last 4" readonly></v-text-field>
      <v-text-field v-model="computedFirstName" :rules="FirstNameRules" label="First Name"></v-text-field>
      <v-text-field v-model="computedLastName" :rules="LastNameRules" label="Last Name"></v-text-field>
      <v-text-field v-model="computedEmail" :rules="EmailRules" label="Email"></v-text-field>
      <v-text-field v-model="computedPassword" type="password" label="Password" :messages="['Leave blank to remain unchanged']"></v-text-field>
      <v-checkbox v-model="computedReceiveEmails" label="Receive Emails"></v-checkbox>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveUser"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import rules       from '../../lib/rules'
export default {
  name: 'AdminsUserEdit',
  data() {
    return {
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.loadUser({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Admins/UserEdit', ['user']),
    ...rules.User,
    computedFirstName: {
      get() { return this.user.firstName },
      set(v) { this.setUser({user: {firstName: v}}) }
    },
    computedLastName: {
      get() { return this.user.lastName },
      set(v) { this.setUser({user: {lastName: v}}) }
    },
    computedEmail: {
      get() { return this.user.email },
      set(v) { this.setUser({user: {email: v}}) }
    },
    computedPassword: {
      get() { return this.user.password },
      set(v) { this.setUser({user: {password: v}}) }
    },
    computedReceiveEmails: {
      get() { return this.user.receiveEmails },
      set(v) { this.setUser({user: {receiveEmails: v}}) }
    },
  },
  methods: {
    ...Vuex.mapActions('Admins/UserEdit', ['loadUser', 'setUser', 'attemptSaveUser']),
    async saveUser() {
      this.processing = true
      if (await this.attemptSaveUser()) {
        this.$router.push({name: 'admins-user', params: {id: this.user.id}})
      }
      this.processing = false
    }
  }
}
</script>
