'use strict'

import localforage from 'localforage'
import _           from 'lodash'
import moment      from 'moment'

import api         from '../../api'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    loginErrors: [],
    loginForm: {
      email: '',
      password: ''
    }
  },
  getters: {
    currentUser(state) { return state.currentUser },
    loggedIn(state) { return !!state.currentUser },
    activeMembership(state) { return !!_.get(state.currentUser, 'relationships.activePayments', []).length },
    futureMembership(state) { return !!_.get(state.currentUser, 'relationships.futurePayments', []).length },
    futureMembershipStart(state) {
      const start = _.get(state.currentUser, 'relationships.futurePayments[0].membershipStart', null)
      return start ? moment(start).format('YYYY-MM-DD') : null
    },
    loginErrors(state) { return state.loginErrors },
    loginForm(state) { return state.loginForm }
  },
  actions: {
    setLoginForm({commit}, {loginForm}) {
      commit('setLoginForm', {loginForm})
    },
    async initializeCurrentUser({commit}, {givenCredentials = null} = {}) {
      const credentials = givenCredentials || (await localforage.getItem('tl-credentials'))

      if (credentials) {
        try {
          const login = (await api.Login.create({params: {login: credentials}})).data.data
          commit('setCurrentUser', {currentUser: login})
        } catch(e) {
          commit('setCurrentUser', {currentUser: null})
        }
      }
    },
    async attemptLogin({commit, getters}) {
      let successful = true
      try {
        const login = (await api.Login.create({params: {login: getters.loginForm}})).data.data

        commit('setLoginErrors', {loginErrors: []})
        commit('setLoginForm', {loginForm: {email: '', password: ''}})
        commit('setCurrentUser', {currentUser: login})
      } catch(e) {
        successful = false
        commit('setLoginErrors', {loginErrors: e.response.data.errors})
        commit('setLoginForm', {loginForm: {password: ''}})
      }
      return successful
    },
    async attemptLogout({commit}) {
      commit('logout')
      return true
    }
  },
  mutations: {
    setLoginErrors(state, {loginErrors}) {
      state.loginErrors = [...loginErrors]
    },
    setLoginForm(state, {loginForm}) {
      state.loginForm = {...state.loginForm, ...loginForm}
    },
    setCurrentUser(state, {currentUser}) {
      if (currentUser) {
        localforage.setItem('tl-credentials', _.pick(currentUser.attributes, ['email', 'token']))
      }
      state.currentUser = currentUser
    },
    logout(state) {
      localforage.removeItem('tl-credentials')
      state.currentUser = null
    }
  }
}
