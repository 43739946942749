'use strict'

import _           from 'lodash'

import api         from '../../../api'

export default {
  namespaced: true,
  state: {
    user: null
  },
  getters: {
    user(state) { return state.user },
    activeMembership(state) { return !!_.get(state.user, 'relationships.activePayments', []).length },
  },
  mutations: {
    setUser(state, {user}) {
      state.user = user
    }
  },
  actions: {
    async loadUser({commit}, {id}) {
      commit('setUser', {user: null})
      const user = (await api.Admins.Users.show(id)).data.data
      commit('setUser', {user})
    }
  }
}
