'use strict'

import _           from 'lodash'
import localforage from 'localforage'
import moment      from 'moment'

export default {
  namespaced: true,
  state: {
    acknowledgements: {}
  },  
  mutations: {
    setAcknowledgements(state, {acknowledgements}) {
      state.acknowledgements = {...acknowledgements}
      localforage.setItem('tl-acknowledgements', state.acknowledgements)
    }   
  },  
  getters: {
    acknowledgements(state) { return state.acknowledgements }
  },  
  actions: {
    async initialize({commit, dispatch}) {
      const acknowledgements = (await localforage.getItem('tl-acknowledgements')) || {}
      commit('setAcknowledgements', {acknowledgements})
      // Remove any expired acknowledgements
      for (let key in acknowledgements) {
        if (acknowledgements[key].expires && moment(acknowledgements[key].expires).isBefore(moment())) {
          dispatch('removeAcknowledgement', {acknowledgement: {name: key}})
        }
      }
    },  
    async addAcknowledgement({commit, getters}, {acknowledgement: {name, expires}}) {
      commit('setAcknowledgements', {
        acknowledgements: {
          ...getters.acknowledgements,
          [name]:                       {name, expires}
        }   
      })  
    },
    async removeAcknowledgement({commit, getters}, {acknowledgement: {name}}) {
      commit('setAcknowledgements', {
        acknowledgements: _.omit(getters.acknowledgements, [name])
      })  
    }   
  }
}
