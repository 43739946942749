<template>
  <v-flex v-if="!$root.appInfo.pageTransitioning">
    <v-list v-if="showPlaceholders && establishments.length === 0" two-line>
      <v-list-tile v-for="placeholder in [0, 1, 2]" :key="placeholder">
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray"></v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <v-chip color="light-gray" style="width: 13rem; height: 1.3rem"></v-chip>
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip color="light-gray" style="width: 5rem; height: 1rem"></v-chip>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-list
        v-if="establishments.length > 0 && condensed"
        class="condensed-list"
        >
      <v-list-tile
          v-for="establishment in establishments"
          :key="establishment.attributes.slug"
          :to="{name: route({establishment}), params: {slug: establishment.attributes.slug}, query: linkQuery}"
          class="mb-2">
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray">
          <v-img v-if="establishment.attributes.logoImageFileNameUrl" :src="establishment.attributes.logoImageFileNameUrl"></v-img>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">
            {{establishment.attributes.name}}
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip :color="$options.filters.establishmentOpen(establishment) ? 'deep-red' : 'light-gray'" text-color="white" small>
              {{$options.filters.establishmentOpen(establishment) ? 'Open' : 'Closed'}}
            </v-chip>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-list
        v-if="establishments.length > 0 && !condensed"
        class="px-2"
        >
      <div
          v-for="establishment in establishments"
          :key="establishment.attributes.slug"
          >
        <router-link
            class="mb-2"
            v-if="establishment.attributes.partnered"
            :to="{name: route({establishment}), params: {slug: establishment.attributes.slug}, query: linkQuery}">
          <v-card
              :tile="false"
              >
            <v-avatar
                size="5rem"
                style="margin-bottom: -5rem; z-index: 10"
                class="ml-2"
                color="white">
              <v-avatar size="4rem">
                <v-img v-if="establishment.attributes.logoImageFileNameUrl" :src="establishment.attributes.logoImageFileNameUrl"></v-img>
              </v-avatar>
            </v-avatar>
            <v-img :src="establishment.attributes.headerImageFileNameUrl || require('../assets/drinking.jpg')" height="12rem">
              <v-img :src="require('../assets/shadow-upward.png')" contain height="12rem" class="shading-bottom">
                <v-layout column fill-height justify-end class="pa-2 white--text">
                  <v-flex shrink class="headline">
                    {{establishment.attributes.name}}
                  </v-flex>
                </v-layout>
              </v-img>
            </v-img>
          </v-card>
        </router-link>
        <v-list-tile
            v-else
            :to="{name: route({establishment}), params: {slug: establishment.attributes.slug}, query: linkQuery}"
            class="mt-3"
            style="height: 4rem"
            >
          <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray">
            <v-img v-if="establishment.attributes.logoImageFileNameUrl" :src="establishment.attributes.logoImageFileNameUrl"></v-img>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title class="subtitle black--text">
              {{establishment.attributes.name}}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </v-list>
  </v-flex>
</template>
<style scoped lang="sass">
::v-deep
  .v-list
    &.condensed-list
      .v-list__tile
        height: auto !important
    .v-list__tile__title
      height: auto
      white-space: inherit
  .v-card
    >:first-child:not(.v-btn):not(.v-chip)
      border-top-left-radius: 50%
      border-top-right-radius: 50%

</style>
<script>
export default {
  name: 'ListOfEstablishments',
  props: {
    establishments: Array,
    showPlaceholders: {
      type: Boolean,
      default: true
    },
    condensed: {
      type: Boolean,
      default: true
    },
    routeName: {
      type: String
    },
    linkQuery: {
      type: Object
    }
  },
  methods: {
    route({establishment}) {
      let r = establishment.attributes.type === 'Winery' ? 'winery' : 'partner'
      if (this.routeName) {
        r = this.routeName
      }
      return r
    }
  }
}
</script>
