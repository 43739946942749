'use strict'

import _                   from 'lodash'

import router              from '../../router'

export default {
  namespaced: true,
  state: {
    appInfo: {
      version:                   '',
      deviceUuid:                '',
      platform:                  '',
      pageTransitioning:         false,
      googleAnalyticsTrackingID: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
      googleTagID:               process.env.VUE_APP_GOOGLE_TAG_ID,
      fullViewportHeight:        0,
      fullViewportWidth:         0,
      viewportHeight:            0,
      viewportWidth:             0,
      reasonableHeaderHeight:    0,
      notificationsEnabled:      false,
      notificationsDenied:       false,
      deviceToken:               null
    }
  },
  getters: {
    appInfo(state) { return state.appInfo },
    platform(state) { return state.appInfo.platform },
    mobileApp(state, getters) { return ['iOS', 'Android'].includes(getters.platform) },
    notificationsEnabled(state) { return state.appInfo.notificationsEnabled },
    notificationsDenied(state) { return state.appInfo.notificationsDenied },
    deviceToken(state) { return state.appInfo.deviceToken }
  },
  mutations: {
    setAppInfo(state, {appInfo}) {
      state.appInfo = {...state.appInfo, ...appInfo}
    }
  },
  actions: {
    async initialize({dispatch}) {
      const version = document.querySelector('body').dataset.version
      if (version && version.match(/^\d+\.\d+\.\d+$/)) {
        dispatch('setAppInfo', {appInfo: {version}})
      }
      if (_.get(window, 'device.uuid')) {
        dispatch('setAppInfo', {appInfo: {deviceUuid: window.device.uuid}})
      }
      document.addEventListener('deviceready', () => {
        if (window.device) {
          dispatch('setAppInfo', {appInfo: {platform: window.device.platform}})
        }
      }, false)
      if (window.FirebasePlugin) {
        window.FirebasePlugin.setAnalyticsCollectionEnabled(true)
        if (window.FirebasePlugin.hasPermission) {
          // Check if permissions already enabled
          window.FirebasePlugin.hasPermission(async hasPermission => {
            const notificationsEnabled = !!_.get(hasPermission, 'isEnabled', false)
            dispatch('setAppInfo', {appInfo: {notificationsEnabled}})
            if (notificationsEnabled) {
              dispatch('setDeviceToken')
            }
          })
          // Setup notifications to handle opening to a page
          window.FirebasePlugin.onMessageReceived(notification => {
            if (notification.goToPath) {
              if (notification.tap) {
                router.push(notification.goToPath)
              }
            }
          }, () => {})
        }
      }
    },
    async setDeviceToken({dispatch, getters}) {
      // Try to get device token and save it
      if (window.FirebasePlugin) {
        await new Promise(resolve => {
          if (getters.platform === 'iOS') {
            window.FirebasePlugin.grantPermission(
              () => {
                window.FirebasePlugin.onTokenRefresh(
                  deviceToken => {
                    dispatch('setAppInfo', {appInfo: {notificationsEnabled: true, deviceToken}})
                    resolve()
                  },
                  resolve
                )
              },
              () => {
                dispatch('setAppInfo', {appInfo: {notificationsDenied: true}})
                resolve()
              }
            )
          // grantPermission can't be called in Android
          } else if (getters.platform === 'Android') {
            window.FirebasePlugin.onTokenRefresh(
              deviceToken => {
                dispatch('setAppInfo', {appInfo: {notificationsEnabled: true, deviceToken}})
                resolve()
              },
              resolve
            )
          }
        })
      }
    },
    async setAppInfo({commit}, {appInfo}) {
      commit('setAppInfo', {appInfo})
    }
  }
}
