'use strict'

export default {
  User: {
    FirstNameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[\w'-]+$/)) || 'Invalid format'
    ]),
    LastNameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[\w'-]+$/)) || 'Invalid format'
    ]),
    EmailRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)) || 'Invalid format'
    ])
  },
  Deal: {
    EstablishmentIdRules: () => ([
      v => !!v || 'Required'
    ]),
    DescriptionRules: () => ([
      v => !v || (v && v.length <= 800) || 'Max 800 characters'
    ]),
    NameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-Z \d-'‘’,:&!$]+$/)) || 'Only letters, numbers, spaces, commas, apostrophes, dashes, colons',
      v => (v && v.length >= 1 && v.length <= 100) || 'Must be between 1 and 100 characters'
    ]),
    UrlRules: () => ([
      v => !v || !!v.match(/^https?:\/\/.+$/) || 'Not a valid URL'
    ])
  },
  Payment: {
    TypeRules: () => ([
      v => !!v || 'Required'
    ])
  },
  Registration: {
    FirstNameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[\w'-]+$/)) || 'Invalid format'
    ]),
    LastNameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[\w'-]+$/)) || 'Invalid format'
    ]),
    EmailRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)) || 'Invalid format'
    ]),
    PasswordRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^.{4,}$/)) || 'Must be at least 4 letters'
    ]),
    ZipRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^\d{5}$/)) || '5 digit zipcode'
    ])
  },
  Login: {
    EmailRules: () => ([
      v => !!v || 'Required'
    ]),
    PasswordRules: () => ([
      v => !!v || 'Required'
    ])
  },
  Establishment: {
    SlugRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-z-\d]+$/)) || 'Only lowercase letters, numbers, and dashes',
      v => (v && v.length >= 1 && v.length <= 120) || 'Must be between 1 and 120 characters'
    ]),
    DescriptionRules: () => ([
      v => !v || (v && v.length <= 1500) || 'Max 1,500 characters'
    ]),
    NameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-ZÀ-ÿ \d-'‘’,:&]+$/)) || 'Only letters, numbers, spaces, commas, apostrophes, dashes, colons',
      v => (v && v.length >= 1 && v.length <= 100) || 'Must be between 1 and 100 characters'
    ]),
    PhoneRules: () => ([
      v => !v || !!v.match(/^\(\d\d\d\) \d\d\d-\d\d\d\d$/) || 'Only this format: (555) 555-5555'
    ]),
    AddressRules: () => ([
      () => true
    ]),
    WebsiteRules: () => ([
      () => true
    ]),
    LatRules: () => ([
      v => !v || (v && !!v.match(/^-?\d*\.?\d+$/)) || 'Must be a valid latitude value',
      v => !v || (v && v.length <= 15) || 'Must be 15 or less characters',
    ]),
    LngRules: () => ([
      v => !v || (v && !!v.match(/^-?\d*\.?\d+$/)) || 'Must be a valid longitude value',
      v => !v || (v && v.length <= 15) || 'Must be 15 or less characters',
    ]),
    LocaleRules: () => ([
      v => !!v || 'Required'
    ]),
    TypeRules: () => ([
      v => !!v || 'Required'
    ]),
    PartnerTypeRules: () => ([
      () => true
    ])
  },
  EstablishmentOperator: {
    UserIdRules: () => ([
      v => !!v || 'Required'
    ])
  },
  Event: {
    NameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-ZÀ-ÿ \d-'‘’,:&]+$/)) || 'Only letters, numbers, spaces, commas, apostrophes, dashes, colons',
      v => (v && v.length >= 1 && v.length <= 100) || 'Must be between 1 and 100 characters'
    ]),
    DescriptionRules: () => ([
      v => !v || (v && v.length <= 1000) || 'Max 1,000 characters'
    ])
  },
  Spotlight: {
    DescriptionRules: () => ([
      v => !v || (v && v.length <= 2500) || 'Max 2,500 characters'
    ]),
    NameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-Z \d-+'‘’,:&!]+$/)) || 'Only letters, numbers, spaces, commas, apostrophes, dashes, colons',
      v => (v && v.length >= 1 && v.length <= 100) || 'Must be between 1 and 100 characters'
    ]),
    UrlRules: () => ([
      v => !v || !!v.match(/^https?:\/\/.+$/) || 'Not a valid URL'
    ]),
    PlacementRules: () => ([
      v => !!v || 'Required'
    ])
  },
  Wine: {
    NameRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-ZÀ-ÿ \d-'‘’,:]+$/)) || 'Only letters, numbers, spaces, commas, apostrophes, dashes, colons',
      v => (v && v.length >= 1 && v.length <= 100) || 'Must be between 1 and 100 characters'
    ]),
    DescriptionRules: () => ([
      v => !v || (v && v.length <= 1000) || 'Max 1,000 characters'
    ]),
    TagsRules: () => ([
      v => !!v || 'Required'
    ]),
    CriticScoreRules: () => ([
      v => !v || (v && parseInt(v) && v > 60 && v <= 100) || 'Critic score invalid'
    ])
  },
  InterestedUser: {
    EmailRules: () => ([
      v => !!v || 'Required',
      v => (v && !!v.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/)) || 'Invalid format'
    ])
  }
}
