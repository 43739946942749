<template>
  <page :header="{enabled: true, back: {name: 'admins-deal', params: {id: $route.params.id}}, center: {enabled: true, text: deal ? `Edit ${deal.name}` : ''}}" :footer="{enabled: false}">
    <v-layout justify-end class="py-1 px-2">
      <v-dialog v-model="confirmingDestroy">
        <v-btn slot="activator" dark fab small color="error">
           <v-icon>delete_forever</v-icon>
        </v-btn>
        <v-card>
          <v-card-title class="headline grey lighten-2">Delete?</v-card-title>
          <v-card-actions class="justify-center py-3">
            <v-btn
                color="error"
                :disabled="processing"
                :loading="processing"
                @click="destroyDeal">
              Yes
            </v-btn>
            <v-btn
                :disabled="processing"
                :loading="processing"
                @click="confirmingDestroy = false">
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-form v-model="valid" class="pa-3" v-if="deal">
      <v-text-field :value="establishment.attributes.name" label="Business" readonly></v-text-field>
      <v-text-field v-model="computedName" :rules="NameRules" label="Name"></v-text-field>
      <v-text-field v-model="computedUrl" :rules="UrlRules" label="URL"></v-text-field>
      <h3 class="mt-2">Image</h3>
      <v-layout row align-center>
        <v-sheet
            color="light-gray"
            min-width="7rem"
            width="7rem"
            height="3.5rem"
            class="mr-3 handle">
          <v-img
              v-if="deal.imageFileNameData || deal.imageFileNameUrl"
              min-width="7rem"
              width="7rem"
              height="3.5rem"
              :src="deal.imageFileNameData || deal.imageFileNameUrl">
          </v-img>
        </v-sheet>
        <image-upload v-model="computedImageFileNameData"></image-upload>
      </v-layout>
      <v-textarea v-model="computedDescription" :rules="DescriptionRules" label="Description"></v-textarea>
      <v-checkbox v-model="computedAtStart" label="Start?"></v-checkbox>
      <div v-if="computedAtStart">
        <date-picker
            :value="computedAtStartDate"
            display-format="dddd, MMM D"
            @change="computedAtStartDate = $event">
        </date-picker>
        <time-picker
            :value="computedAtStartTime"
            @change="computedAtStartTime = $event">
        </time-picker>
      </div>
      <v-checkbox
          v-model="computedAtEnd"
          label="End?"
          :disabled="!computedAtStart"
          >
      </v-checkbox>
      <div v-if="computedAtEnd">
        <date-picker
            :value="computedAtEndDate"
            display-format="dddd, MMM D"
            @change="computedAtEndDate = $event">
        </date-picker>
        <time-picker
            :value="computedAtEndTime"
            @change="computedAtEndTime = $event">
        </time-picker>
      </div>
      <v-layout>
        <v-checkbox v-model="computedWeekdaysOnly" label="Weekdays Only"></v-checkbox>
      </v-layout>
      <v-layout>
        <v-checkbox v-model="computedConsumable" label="Consumable"></v-checkbox>
      </v-layout>
      <v-layout>
        <v-checkbox v-model="computedActive" label="Active"></v-checkbox>
      </v-layout>
      <v-btn
          color="primary"
          :disabled="!valid || processing"
          :loading="processing"
          @click="saveDeal"
          class="ml-0">Save</v-btn>
    </v-form>
  </page>
</template>
<script>
import Vuex        from 'vuex'
import moment      from 'moment'
import rules       from '../../lib/rules'
import DatePicker  from '../DatePicker.vue'
import TimePicker  from '../TimePicker.vue'
import ImageUpload from '../Operators/ImageUpload.vue'
export default {
  name: 'AdminsDealEdit',
  components: {
    DatePicker,
    TimePicker,
    ImageUpload
  },
  data() {
    return {
      confirmingDestroy: false,
      valid: true,
      processing: false
    }
  },
  async created() {
    await this.loadDeal({id: this.$route.params.id})
  },
  computed: {
    ...Vuex.mapGetters('Admins/DealEdit', ['deal', 'establishment']),
    ...rules.Deal,
    computedName: {
      get() { return this.deal.name },
      set(v) { this.setDeal({deal: {name: v}}) }
    },
    computedUrl: {
      get() { return this.deal.url },
      set(v) { this.setDeal({deal: {url: v}}) }
    },
    computedImageFileNameData: {
      get() { return this.deal.imageFileNameData },
      set(v) { this.setDeal({deal: {imageFileNameData: v}}) }
    },
    computedDescription: {
      get() { return this.deal.description },
      set(v) { this.setDeal({deal: {description: v}}) }
    },
    computedAtStart: {
      get() { return !!this.deal.atStart },
      set(v) {
        this.setDeal({deal: {atStart: v ? moment().set('hour', 0).set('minute', 0).toDate() : null}})
        this.setDeal({deal: {atEnd: null}})
      }
    },
    computedAtStartDate: {
      get() { return this.deal.atStart },
      set(v) { this.setDeal({deal: {atStart: v}}) }
    },
    computedAtStartTime: {
      get() { return this.deal.atStart },
      set(v) { this.setDeal({deal: {atStart: v}}) }
    },
    computedAtEnd: {
      get() { return !!this.deal.atEnd },
      set(v) { this.setDeal({deal: {atEnd: v ? moment(this.deal.atStart).add(10, 'days').toDate() : null}}) }
    },
    computedAtEndDate: {
      get() { return this.deal.atEnd },
      set(v) { this.setDeal({deal: {atEnd: v}}) }
    },
    computedAtEndTime: {
      get() { return this.deal.atEnd },
      set(v) { this.setDeal({deal: {atEnd: v}}) }
    },
    computedWeekdaysOnly: {
      get() { return this.deal.weekdaysOnly },
      set(v) { this.setDeal({deal: {weekdaysOnly: v}}) }
    },
    computedConsumable: {
      get() { return this.deal.consumable },
      set(v) { this.setDeal({deal: {consumable: v}}) }
    },
    computedActive: {
      get() { return this.deal.active },
      set(v) { this.setDeal({deal: {active: v}}) }
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/DealEdit', ['loadDeal', 'setDeal', 'attemptSaveDeal', 'attemptDestroyDeal']),
    async saveDeal() {
      this.processing = true
      if (await this.attemptSaveDeal()) {
        this.$router.push({name: 'admins-deal', params: {id: this.$route.params.id}})
      }
      this.processing = false
    },
    async destroyDeal() {
      this.processing = true
      if (await this.attemptDestroyDeal()) {
        this.$router.push({name: 'admins-deals'})
      }
      this.processing = false
    }
  }
}
</script>
