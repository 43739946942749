'use strict'

import api from '../../api'

export default {
  namespaced: true,
  state: {
    deals: [],
    type: 'Wineries'
  },
  getters: {
    deals(state) { return state.deals },
    // eslint-disable-next-line
    shownDeals(state, getters, rootState, rootGetters) {
      const dealsOfType = state.deals.filter(deal => deal.relationships.establishment.attributes.type === (state.type === 'Wineries' ? 'Winery' : 'Partner'))
      const dealsForLoginState = rootState['Login/activeMembership'] ? dealsOfType : dealsOfType.slice(0, 4)
      return dealsForLoginState
    },
    type(state) { return state.type }
  },
  mutations: {
    setDeals(state, {deals}) {
      state.deals = [...deals]
    },
    setType(state, {type}) {
      state.type = type
    }
  },
  actions: {
    async loadDeals({commit}) {
      const deals = (await api.Deals.index()).data.data
      commit('setDeals', {deals})
    },
    setType({commit}, {type}) {
      commit('setType', {type})
    }
  }
}
