'use strict'

import api from '../../../api'

export default {
  namespaced: true,
  state: {
    registration: {},
    errorMessage: null
  },
  getters: {
    registration(state) { return state.registration },
    errorMessage(state) { return state.errorMessage }
  },
  mutations: {
    initializeRegistration(state) {
      state.registration = {}
      state.errorMessage = null
    },
    setRegistration(state, {registration}) {
      state.registration = {...state.registration, ...registration}
    },
    setErrorMessage(state, {errorMessage}) {
      state.errorMessage = errorMessage
    }
  },
  actions: {
    initializeRegistration({commit}) {
      commit('initializeRegistration')
    },
    setRegistration({commit}, {registration}) {
      commit('setRegistration', {registration})
    },
    async attemptRegister({dispatch, commit, getters}) {
      try {
        const login = (await api.Users.Registrations.create({params: {registration: getters.registration}})).data.data
        dispatch('initializeRegistration')
        commit('Login/setCurrentUser', {currentUser: login}, {root: true})
        return true
      } catch(e) {
        commit('setErrorMessage', {errorMessage: e.response.data.errors[0].title})
        return false
      }
    }
  }
}
