var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[(_vm.futureEvents.length)?_vm._l((_vm.futureEvents),function(event){return _c('v-card',{key:event.attributes.id,staticClass:"mb-4"},[(event.attributes.imageFileNameUrl)?_c('v-img',{attrs:{"src":event.attributes.imageFileNameUrl,"height":"15rem"}},[_c('v-img',{staticClass:"shading-bottom",attrs:{"src":require('../../assets/shadow-upward.png'),"contain":"","height":"15rem"}},[_c('v-layout',{staticClass:"pa-3 white--text",attrs:{"column":"","fill-height":"","justify-end":""}},[_c('v-flex',{staticClass:"title mb-1",attrs:{"shrink":""}},[_vm._v(_vm._s(event.attributes.name))]),_c('v-flex',{staticClass:"subheading",attrs:{"shrink":""}},[_vm._v(_vm._s(_vm._f("eventTimeFormatted")(event)))])],1)],1)],1):_c('div',[_c('div',{staticClass:"title mb-1"},[_vm._v(_vm._s(event.attributes.name))]),_c('div',{staticClass:"subheading"},[_vm._v(_vm._s(_vm._f("eventTimeFormatted")(event)))])]),_c('v-card-title',{staticClass:"pt-3 px-0",staticStyle:{"white-space":"pre-line"},attrs:{"primary-title":""}},[_c('div',[_c('sanitized-html',{attrs:{"html":event.attributes.description,"link-event-category":"Event","link-event-action":"click_external","link-event-label":function (ref) {
	var url = ref.url;

	return ((_vm.winery.attributes.name) + " - " + (event.attributes.name) + " (" + url + ")");
},"link-event-value":("" + (event.attributes.id))}})],1)])],1)}):_vm._e(),_c('div',{staticClass:"title font-weight-regular pt-3 pb-2 mb-2 gray--text"},[_vm._v("Past events")]),(_vm.recentPastEvents.length)?_vm._l((_vm.recentPastEvents),function(event){return _c('v-card',{key:event.attributes.id,staticClass:"mb-4"},[(event.attributes.imageFileNameUrl)?_c('v-img',{attrs:{"src":event.attributes.imageFileNameUrl,"height":"15rem"}},[_c('v-img',{staticClass:"shading-bottom",attrs:{"src":require('../../assets/shadow-upward.png'),"contain":"","height":"15rem"}},[_c('v-layout',{staticClass:"pa-3 white--text",attrs:{"column":"","fill-height":"","justify-end":""}},[_c('v-flex',{staticClass:"title mb-1",attrs:{"shrink":""}},[_vm._v(_vm._s(event.attributes.name))]),_c('v-flex',{staticClass:"subheading",attrs:{"shrink":""}},[_vm._v(_vm._s(_vm._f("eventTimeFormatted")(event)))])],1)],1)],1):_c('div',[_c('div',{staticClass:"title mb-1"},[_vm._v(_vm._s(event.attributes.name))]),_c('div',{staticClass:"subheading"},[_vm._v(_vm._s(_vm._f("eventTimeFormatted")(event)))])]),_c('v-card-title',{staticClass:"pt-3 px-0",staticStyle:{"white-space":"pre-line"},attrs:{"primary-title":""}},[_c('div',[_c('sanitized-html',{attrs:{"html":event.attributes.description,"link-event-category":"Event","link-event-action":"click_external","link-event-label":function (ref) {
	var url = ref.url;

	return ((_vm.winery.attributes.name) + " - " + (event.attributes.name) + " (" + url + ")");
},"link-event-value":("" + (event.attributes.id))}})],1)])],1)}):_vm._e(),(!_vm.futureEvents.length && !_vm.recentPastEvents.length)?_c('transition',{attrs:{"name":"fade","appear":""}},[_c('v-icon',{staticClass:"mt-5"},[_vm._v("icn icn-no-events")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }