<template>
  <page :header="{enabled: true, back: {name: 'admins-reports'}, center: {enabled: true, text: 'Establishment Month Recap'}}" :footer="{enabled: false}">
    <v-form v-model="valid" class="pa-3">
      <v-menu v-model="monthModal" :close-on-content-click="false" right>
        <v-text-field slot="activator" v-model="computedMonth" label="Month Start" prepend-icon="event" readonly></v-text-field>
        <v-date-picker v-model="computedMonth" type="month" @input="monthModal = false"></v-date-picker>
      </v-menu>
      <v-menu v-model="monthEndModal" :close-on-content-click="false" left>
        <v-text-field slot="activator" v-model="computedMonthEnd" label="Month End" prepend-icon="event" readonly></v-text-field>
        <v-date-picker v-model="computedMonthEnd" type="month" @input="monthModalEnd = false"></v-date-picker>
      </v-menu>
      <v-autocomplete
          v-model="computedEstablishmentSlug"
          :items="establishments"
          :item-text="establishmentName"
          item-value="attributes.slug"
          :search-input.sync="search"
          hide-no-data
          label="Business"
          placeholder="Start typing to search"
          prepend-icon="far fa-building">
      </v-autocomplete>
      <v-btn
          color="primary"
          :disabled="!computedEstablishmentSlug || !computedMonth || !computedMonthEnd || !valid || processing"
          :loading="processing"
          @click="generateReport"
          class="ml-0">
        Generate
      </v-btn>
    </v-form>
    <div v-if="report">
      <v-card>
        <v-card-title class="font-weight-bold">Clicks</v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <template v-if="report.establishment.type === 'Winery'">
            <v-list-tile>
              <v-list-tile-content>Overview Tab</v-list-tile-content>
              <v-list-tile-content class="align-end">{{report.data.counts.overviewCount}}</v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Events Tab</v-list-tile-content>
              <v-list-tile-content class="align-end">{{report.data.counts.eventsCount}}</v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Wines Tab</v-list-tile-content>
              <v-list-tile-content class="align-end">{{report.data.counts.winesCount}}</v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Hours Tab</v-list-tile-content>
              <v-list-tile-content class="align-end">{{report.data.counts.hoursCount}}</v-list-tile-content>
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-content>Location Tab</v-list-tile-content>
              <v-list-tile-content class="align-end">{{report.data.counts.locationCount}}</v-list-tile-content>
            </v-list-tile>
          </template>
          <v-list-tile>
            <v-list-tile-content>Total Clicks</v-list-tile-content>
            <v-list-tile-content class="align-end">{{report.data.counts.totalViewCount}}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Total Visits</v-list-tile-content>
            <v-list-tile-content class="align-end">{{report.data.counts.uniqueViewCount}}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
      <v-card>
        <v-card-title class="font-weight-bold">Actions</v-card-title>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-tile>
            <v-list-tile-content>Direction Clicks</v-list-tile-content>
            <v-list-tile-content class="align-end">{{report.data.counts.directionsCount}}</v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>Call Clicks</v-list-tile-content>
            <v-list-tile-content class="align-end">{{report.data.counts.callCount}}</v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </div>
  </page>
</template>
<script>
import Vuex       from 'vuex'
import _          from 'lodash'
export default {
  name: 'AdminsReportEstablishmentMonthRecap',
  data() {
    return {
      valid: true,
      monthModal: false,
      monthEndModal: false,
      search: '',
      processing: false,
      showSubPages: false
    }
  },
  async created() {
    await this.initialize()
  },
  computed: {
    ...Vuex.mapGetters('Admins/ReportEstablishmentMonthRecap', ['report', 'filters', 'establishments']),
    computedMonth: {
      get() { return this.filters.month },
      set(v) { this.setFilters({filters: {month: v}}) }
    },
    computedMonthEnd: {
      get() { return this.filters.monthEnd },
      set(v) { this.setFilters({filters: {monthEnd: v}}) }
    },
    computedEstablishmentSlug: {
      get() { return this.filters.establishmentSlug },
      set(v) { this.setFilters({filters: {establishmentSlug: v}}) }
    }
  },
  watch: {
    search(v) {
      this.doSearch({search: v})
    }
  },
  methods: {
    ...Vuex.mapActions('Admins/ReportEstablishmentMonthRecap', ['initialize', 'searchForEstablishments', 'setFilters', 'attemptGenerateReport']),
    establishmentName(establishment) {
      return establishment.attributes.name
    },
    doSearch: _.debounce(function({search}) {
      this.searchForEstablishments({search})
    }, 200),
    async generateReport() {
      this.processing = true
      await this.attemptGenerateReport()
      this.processing = false
    }
  }
}
</script>
