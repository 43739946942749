<template>
  <lean :left-to="{name: 'guide'}">
    <page :header="{back: {name: 'guide'}, overlap: true, noPadding: true, transparentBackground: true, hideCenter: true, backWhite: true}">
      <v-img :src="require('../assets/wineries-twenty-one-plus.jpg')" :height="$root.reasonableHeaderHeight" width="100%">
        <v-img :src="require('../assets/shadow-downward.png')" :height="$root.reasonableHeaderHeight" contain class="shading-top">
          <v-layout column fill-height justify-start align-center class="pa-2 white--text">
            <v-flex shrink class="headline mt-5">21+ Events</v-flex>
          </v-layout>
        </v-img>
      </v-img>
      <div class="px-4 pt-4" v-if="!eventDayGroupings.length">
        <div class="title text-xs-center">All 21+ Events have been temporarily cancelled due to CDC guidelines.</div>
      </div>
      <v-layout
          column
          shrink
          v-for="({dayString, events}, i) in eventDayGroupings"
          :key="dayString"
          class="day-grouping px-3">
        <div class="title font-weight-regular pt-3 pb-2 mb-2">{{dayString}}{{i === 0 ? "'s 21+ Events" : ''}}</div>
        <v-layout
            row
            v-for="event in events"
            @click.prevent="$router.push({name: 'winery-events', params: {slug: event.relationships.establishment.attributes.slug}, query: {tab: 'Events', back: {name: 'events'}}})"
            class="my-2"
            :key="event.attributes.id">
          <v-layout column>
            <div class="subheading">{{event.relationships.establishment.attributes.name}}</div>
            <div class="title font-weight-regular">{{event.attributes.name}}</div>
            <div class="subheading">
              {{event | eventTimeFormatted({onlyTime: true})}}
            </div>
          </v-layout>
        </v-layout>
      </v-layout>
    </page>
  </lean>
</template>
<script>
import Vuex                 from 'vuex'
import moment               from 'moment'
export default {
  name: 'WineriesTwentyOnePlus',
  created() {
    this.loadEvents()
  },
  computed: {
    ...Vuex.mapGetters('Events', ['events']),
    eventDayGroupings() {
      return this.$store.getters['Events/eventDayGroupingsFunc']({attributes: {twentyOnePlus: true}})
    }
  },
  methods: {
    ...Vuex.mapActions('Events', ['loadEvents']),
    eventTimeStatus({dayString, event}) {
      let status = {}
      const now = moment()
      if (now.isAfter(event.attributes.adjustedAtEnd)) {
        status = {
          text: 'EARLIER',
          icon: 'icn icn-music',
          class: 'black'
        }
      } else if (moment(now).add(1, 'hour').isAfter(event.attributes.adjustedAt) && now.isBefore(event.attributes.adjustedAt)) {
        status = {
          text: 'SOON',
          icon: 'icn icn-music-soon',
          class: 'deep-red-light'
        }
      // For events that are across multiple days should only show 21+ events on today
      } else if (dayString === 'Today' && now.isAfter(event.attributes.adjustedAt) && now.isBefore(event.attributes.adjustedAtEnd)) {
        status = {
          text: 'NOW',
          icon: 'icn icn-music-now',
          class: 'deep-red'
        }
      } else {
        status = {
          text: 'LATER',
          icon: 'icn icn-music-off',
          class: 'gray'
        }
      }
      return status
    }
  }
}
</script>
<style scoped lang="sass">
.day-grouping
  >.title
    border-bottom: 1px solid var(--light-gray)
</style>
