<template>
  <v-layout column class="pa-4 subtitle tab">
    <v-flex v-if="winery.attributes.description" class="mb-3">{{winery.attributes.description}}</v-flex>
    <template v-if="sortedAmenities.length">
      <v-layout
          align-start
          v-for="amenity in sortedAmenities"
          :key="amenity.amenity"
          class="my-2"
          :class="{'grayed-out': !wineryHasAmenity({winery, amenity: amenity.amenity})}"
          @click="(wineryHasAmenity({winery, amenity: amenity.amenity}) && amenity.click) ? $router.push(amenity.click) : false"
          style="flex-shrink: 0">
        <v-flex xs2>
          <v-icon
              size="2.5rem"
              :color="wineryHasAmenity({winery, amenity: amenity.amenity}) ? null : 'gray'">
            {{amenity.icon}}{{wineryHasAmenity({winery, amenity: amenity.amenity}) ? '' : '-off'}}
          </v-icon>
        </v-flex>
        <v-flex
            xs10
            :class="wineryHasAmenity({winery, amenity: amenity.amenity}) ? null : 'grey--text'">
          <div class="title">{{amenity.amenity}}</div>
          <v-layout class="subheading">
            <span v-for="(piece,i) in Array.isArray(amenity.description) ? amenity.description : [amenity.description]" :key="i">
              <span v-if="typeof(piece) === 'object'" :is="piece" :active="wineryHasAmenity({winery, amenity: amenity.amenity})"></span>
              <span v-else>{{piece}}</span>
            </span>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </v-layout>
</template>
<script>
import Vuex from 'vuex'
import _    from 'lodash'

import CallButton from './WineryTab/CallButton.vue'
export default {
  name: 'WineryTab',
  components: {
    // eslint-disable-next-line
    CallButton
  },
  computed: {
    ...Vuex.mapGetters('Winery', ['winery']),
    sortedAmenities() {
      const availableAmenities = [
        {icon: 'icn icn-late',        amenity: 'Open Late',    description: 'Enjoy an evening with us.'},
        {icon: 'icn icn-music',       amenity: 'Live Music',   description: 'Check Events for days and times.'},
        {icon: 'icn icn-picnic',      amenity: 'Picnic Area',  description: 'Outside food allowed.'},
        {icon: 'icn icn-foodtruck',   amenity: 'Food Truck',   click: {name: 'winery-location', params: {slug: this.winery.attributes.slug}}, description: [CallButton, ' ahead, hours may vary.']},
        {icon: 'icn icn-casual-eats', amenity: 'Casual Eats',  description: 'Enjoy an assortment of snacks and treats.'},
        {icon: 'icn icn-dining',      amenity: 'Restaurant',   click: {name: 'winery-location', params: {slug: this.winery.attributes.slug}}, description: [CallButton, ' for hours and reservations.']},
        {icon: 'icn icn-dog',         amenity: 'Dog Friendly', description: 'Well behaved and service dogs allowed.'},
        {icon: 'icn icn-beer',        amenity: 'Beer on Tap',  description: 'Rotating selection of ice cold craft beer.'},
        {icon: 'icn icn-sustainable', amenity: 'Sustainable',  description: 'Verified sustainable winegrowing practices.'},
        {icon: 'icn icn-tour',        amenity: 'Winery Tours', description: 'Get an informative behind-the-scenes look at the wine making process.'},
        {icon: 'icn icn-lodging',     amenity: 'Lodging',      description: 'Call for details and reservations.'},
        {icon: 'icn icn-wedding',     amenity: 'Weddings',     click: {name: 'winery-location', params: {slug: this.winery.attributes.slug}}, description: [CallButton, ' for a wedding packet or tour.']},
      ]
      return _.sortBy(
        availableAmenities,
        amenity => !this.wineryHasAmenity({winery: this.winery, amenity: amenity.amenity})
      )
    }
  },
  methods: {
    wineryHasAmenity({winery, amenity}) {
      const searchForAmenity = amenity === 'Winery Tours' ? 'Wine Tours' : amenity
      return winery.attributes.amenities.includes(searchForAmenity)
    }
  }
}
</script>
<style scoped lang="sass">
.tab
  padding-bottom: env(safe-area-inset-bottom)
.grayed-out ::v-deep .v-btn
  background-color: var(--light-gray) !important
  color: white !important
</style>
