'use strict'

import axios  from 'axios'

import store  from './store'
import router from './router'

const temeculalifeAxios = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX
})
// Serialize query parameters with qs
import qs from 'qs'
temeculalifeAxios.defaults.paramsSerializer = params => qs.stringify(params, {indices: false})

// Setup axios request interceptor to attach header on every request
temeculalifeAxios.interceptors.request.use(config => {
  const currentUser = store.getters['Login/currentUser']
  if (currentUser) {
    config.headers['AUTH-EMAIL'] = currentUser.attributes.email
    config.headers['AUTH-TOKEN'] = currentUser.attributes.token
  }
  if (store.getters['AppInfo/appInfo'].deviceUuid) {
    config.headers['DEVICE-UUID'] = store.getters['AppInfo/appInfo'].deviceUuid
  }
  return config
})
// Setup axios response interceptor to handle unauthorized requests
temeculalifeAxios.interceptors.response.use(response => response, err => {
  // If the server says this user is unauthorized, then log them out
  if (err && err.response && err.response.status === 401) {
    store.dispatch('Login/attemptLogout')
    router.push({name: 'login'})
  }
  return Promise.reject(err)
})

export default temeculalifeAxios
