<template>
  <v-flex>
    <v-list v-if="establishments.length === 0" two-line>
      <v-list-tile v-for="placeholder in [0, 1, 2]" :key="placeholder">
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray"></v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            <v-chip color="light-gray" style="width: 13rem; height: 1.3rem"></v-chip>
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip color="light-gray" style="width: 5rem; height: 1rem"></v-chip>
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
    <v-list v-else two-line>
      <v-list-tile
          v-for="establishment in establishments"
          :key="establishment.attributes.slug"
          :to="{name: 'admins-establishment', params: {slug: establishment.attributes.slug}}">
        <v-list-tile-avatar size="4rem" class="mr-3" color="light-gray">
          <v-img v-if="establishment.attributes.logoImageFileNameUrl" :src="establishment.attributes.logoImageFileNameUrl"></v-img>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title class="subtitle">{{establishment.attributes.name}}</v-list-tile-title>
          <v-list-tile-sub-title>
            <v-chip :color="$options.filters.establishmentOpen(establishment) ? 'deep-red' : 'light-gray'" text-color="white" small>
              {{$options.filters.establishmentOpen(establishment) ? 'Open' : 'Closed'}}
            </v-chip>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-icon>keyboard_arrow_right</v-icon>
      </v-list-tile>
    </v-list>
  </v-flex>
</template>
<script>
export default {
  name: 'AdminsListOfEstablishments',
  props: {
    establishments: Array
  }
}
</script>
